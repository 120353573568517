import React from 'react'
import { GatsbyBrowser } from 'gatsby'

import CalculatorLayout from './src/components/CalculatorLayout'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = (api) => {
  const { element, props } = api
  return (
    <CalculatorLayout {...props}>
      {element as any}
    </CalculatorLayout>
  )
}
