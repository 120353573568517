import jFactor from './jFactor.png'
import jFactor95SI from './jFactor95SI.png'
import jFactor95SIT0 from './jFactor95SIT0.png'
import jFactor95US from './jFactor95US.png'
import jFactor95UST0 from './jFactor95UST0.png'
import jFactor99SI from './jFactor99SI.png'
import jFactor99SIT0 from './jFactor99SIT0.png'
import jFactor99US from './jFactor99US.png'
import jFactor99UST0 from './jFactor99UST0.png'
import jFactorMeanSI from './jFactorMeanSI.png'
import jFactorMeanSIT0 from './jFactorMeanSIT0.png'
import jFactorMeanUS from './jFactorMeanUS.png'
import jFactorMeanUST0 from './jFactorMeanUST0.png'
import xBar from './xBar.png'
import xBarSI from './xBarSI.png'
import xBarSIT0 from './xBarSIT0.png'
import xBarUS from './xBarUS.png'
import xBarUST0 from './xBarUST0.png'

export {
  jFactor,
  jFactor95SI,
  jFactor95SIT0,
  jFactor95US,
  jFactor95UST0,
  jFactor99SI,
  jFactor99SIT0,
  jFactor99US,
  jFactor99UST0,
  jFactorMeanSI,
  jFactorMeanSIT0,
  jFactorMeanUS,
  jFactorMeanUST0,
  xBar,
  xBarSI,
  xBarSIT0,
  xBarUS,
  xBarUST0
}
