import CO from './CO.png'
import COD from './COD.png'
import COU from './COU.png'
import CY from './CY.png'
import CYD from './CYD.png'
import CYDID from './CYDID.png'
import CYU from './CYU.png'
import CYUID from './CYUID.png'
import EL from './EL.png'
import ELD from './ELD.png'
import hardnessTable from './hardnessTable.png'
import PE from './PE.png'
import PED from './PED.png'
import PEID from './PEID.png'
import siteFactorsTable from './siteFactorsTable.png'
import SP from './SP.png'
import SPD from './SPD.png'
import TO from './TO.png'
import TOD from './TOD.png'

export {
  CO,
  COD,
  COU,
  CY,
  CYD,
  CYDID,
  CYU,
  CYUID,
  EL,
  ELD,
  hardnessTable,
  PE,
  PED,
  PEID,
  siteFactorsTable,
  SP,
  SPD,
  TO,
  TOD
}
