import React from 'react'

import WorkflowProvider from './WorkflowProvider'
import Workload from './Workload'

function WorkloadModule() {
  return (
    <WorkflowProvider>
      <Workload />
    </WorkflowProvider>
  )
}

export default WorkloadModule
