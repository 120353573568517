import React from 'react'
import { faScrewdriverWrench } from '@fortawesome/pro-regular-svg-icons'

import SearchJobs from '@e2grnd/ui-core/dist/components/admin/SearchJobs'
import CalculatorHeader from '@e2grnd/ui-core/dist/components/CalculatorHeader/CalculatorHeader'

export default function SearchJobsPage() {
  return (
    <CalculatorHeader title="Search Jobs" icon={faScrewdriverWrench} baseBreadcrumbs={[]}>
      <SearchJobs />
    </CalculatorHeader>
  )
}
