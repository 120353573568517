/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'

import { getAcfErrorBoxPlots } from '../lib/getResultCases.js'

const AcfError = ({ results, problemType }) => {
  const graphData = getAcfErrorBoxPlots(results)
  return (
    <div className="cf w-100">
      <DataGraphDisplay graphs={graphData} />
    </div>
  )
}

AcfError.propTypes = {
  results: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
})

export default connect(mapStateToProps, null)(AcfError)
