import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@mui/material'

import OidcCallback from '@e2grnd/ui-core/dist/contexts/AuthProvider/OidcCallback'
import theme from '@e2grnd/ui-core/dist/styles/theme'

import { PageFrontmatterOptions } from '../types/page-frontmatter'

export default function LocalOidcCallback() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet title="Signing in..." />
      <OidcCallback />
    </ThemeProvider>
  )
};

export const frontmatter: PageFrontmatterOptions = {
  bare: true,
}
