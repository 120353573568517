import React from 'react'

import CorrsolutionsReportRouter from '@e2grnd/ui-core/dist/routes/CorrsolutionsReportRouter'

import { PageFrontmatterOptions } from '../../types/page-frontmatter'

export default function AppRoot() {
  return (
    <CorrsolutionsReportRouter />
  )
}

export const frontmatter: PageFrontmatterOptions = {
  layout: {
    noDrawer: true,
  },
}
