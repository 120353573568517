import b31TminTable from './b31TminTable.png'
import CY from './CY.png'
import ElbowDims from './ElbowDims.png'
import MI from './MI.png'
import MiterDims from './MiterDims.png'
import PE from './PE.png'
import PipeDims from './PipeDims.png'
import PipeDimsU from './PipeDimsU.png'
import siteFactorsTable from './siteFactorsTable.png'
import supplementalLoads from './supplementalLoads.png'

export {
  b31TminTable,
  CY,
  ElbowDims,
  MI,
  MiterDims,
  PE,
  PipeDims,
  PipeDimsU,
  siteFactorsTable,
  supplementalLoads
}
