export default [{
  name: "nChains",
  required: false,
  type: "CUSTOM_FIELD"
}, {
  name: "numberOfBurnInSteps",
  required: true,
  type: "CUSTOM_FIELD"
}, {
  name: "numberOfAdaptiveSteps",
  required: true,
  type: "CUSTOM_FIELD"
}, {
  name: "numberOfSamplesToThin",
  required: true,
  type: "CUSTOM_FIELD"
}, {
  name: "numberOfTotalSamples",
  required: true,
  type: "CUSTOM_FIELD"
}, {
  name: "projectionsCredibleInterval",
  required: true,
  type: "CUSTOM_FIELD"
}];