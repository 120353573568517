import CPDesignCurrent1Eqn from './CPDesignCurrent1Eqn.png'
import CpDesignCurrent2Eqn from './CpDesignCurrent2Eqn.png'
import CpDesignCurrent3Eqn from './CpDesignCurrent3Eqn.png'
import CPDesignDesignLifeEqn from './CPDesignDesignLifeEqn.png'
import CPDesignResistanceEqn from './CPDesignResistanceEqn.png'
import GalvanicDistributedHorizontal from './GalvanicDistributedHorizontal.png'
import GalvanicDistributedHorizontalBackfill from './GalvanicDistributedHorizontalBackfill.png'
import GalvanicDistributedVertical from './GalvanicDistributedVertical.png'
import GalvanicDistributedVerticalBackfill from './GalvanicDistributedVerticalBackfill.png'
import GalvanicDistributedVerticalBed from './GalvanicDistributedVerticalBed.png'
import GalvanicShallowDistributedBed from './GalvanicShallowDistributedBed.png'
import GalvanicShallowHorizontal from './GalvanicShallowHorizontal.png'
import GalvanicShallowHorizontalBackfillContinuous from './GalvanicShallowHorizontalBackfillContinuous.png'
import GalvanicShallowHorizontalBackfillSegmented from './GalvanicShallowHorizontalBackfillSegmented.png'
import GalvanicShallowHorizontalBed from './GalvanicShallowHorizontalBed.png'
import GalvanicShallowVertical from './GalvanicShallowVertical.png'
import GalvanicShallowVerticalBackfill from './GalvanicShallowVerticalBackfill.png'
import GalvanicShallowVerticalBed from './GalvanicShallowVerticalBed.png'
import ImpressedDeepWell from './ImpressedDeepWell.png'
import ImpressedDeepWellAnode from './ImpressedDeepWellAnode.png'
import ImpressedHorizontalBed from './ImpressedHorizontalBed.png'
import ImpressedVerticalBed from './ImpressedVerticalBed.png'
import PipeDimensions from './PipeDimensions.png'
import RectifierHorizontal from './RectifierHorizontal.png'
import RectifierHorizontalBackfillContinuous from './RectifierHorizontalBackfillContinuous.png'
import RectifierHorizontalBackfillSegmented from './RectifierHorizontalBackfillSegmented.png'
import RectifierVertical from './RectifierVertical.png'
import RectifierVerticalBackfill from './RectifierVerticalBackfill.png'
import WireDimensionsHorizontal from './WireDimensionsHorizontal.png'
import WireDimensionsHorizontalImpressed from './WireDimensionsHorizontalImpressed.png'
import WireDimensionsVertical from './WireDimensionsVertical.png'
import WireDimensionsVerticalImpressed from './WireDimensionsVerticalImpressed.png'

export {
  CPDesignCurrent1Eqn,
  CpDesignCurrent2Eqn,
  CpDesignCurrent3Eqn,
  CPDesignDesignLifeEqn,
  CPDesignResistanceEqn,
  GalvanicDistributedHorizontal,
  GalvanicDistributedHorizontalBackfill,
  GalvanicDistributedVertical,
  GalvanicDistributedVerticalBackfill,
  GalvanicDistributedVerticalBed,
  GalvanicShallowDistributedBed,
  GalvanicShallowHorizontal,
  GalvanicShallowHorizontalBackfillContinuous,
  GalvanicShallowHorizontalBackfillSegmented,
  GalvanicShallowHorizontalBed,
  GalvanicShallowVertical,
  GalvanicShallowVerticalBackfill,
  GalvanicShallowVerticalBed,
  ImpressedDeepWell,
  ImpressedDeepWellAnode,
  ImpressedHorizontalBed,
  ImpressedVerticalBed,
  PipeDimensions,
  RectifierHorizontal,
  RectifierHorizontalBackfillContinuous,
  RectifierHorizontalBackfillSegmented,
  RectifierVertical,
  RectifierVerticalBackfill,
  WireDimensionsHorizontal,
  WireDimensionsHorizontalImpressed,
  WireDimensionsVertical,
  WireDimensionsVerticalImpressed
}
