export default [{
  expression: (problemType, evtFlag, accessControl, credentials, activeCalculator) => {
    return problemType !== 'SIMP' && problemType !== 'R' ? [{
      expression: (evtFlag, accessControl, credentials, activeCalculator) => {
        return evtFlag === 'evt' ? [{
          name: "cmlMeasurementThicknessTable2",
          required: false,
          type: "CUSTOM_FIELD"
        }] : [{
          name: "cmlMeasurementThicknessTable",
          required: false,
          type: "CUSTOM_FIELD"
        }];
      },
      testIdentifiers: ["evtFlag", "accessControl", "credentials", "activeCalculator"]
    }] : [];
  },
  testIdentifiers: ["problemType", "evtFlag", "accessControl", "credentials", "activeCalculator"]
}, {
  expression: (problemType, accessControl, credentials, activeCalculator) => {
    return problemType === 'SIMP' ? [{
      name: "cmlMeasurementThicknessTable",
      required: false,
      type: "CUSTOM_FIELD"
    }] : "";
  },
  testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
}, {
  expression: (problemType, accessControl, credentials, activeCalculator) => {
    return problemType === 'R' ? [{
      name: "cmlMeasurementThicknessTable2",
      required: false,
      type: "CUSTOM_FIELD"
    }] : "";
  },
  testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
}, {
  expression: (table2StatusEmpty, accessControl, credentials, activeCalculator) => {
    return table2StatusEmpty === false ? "" : "";
  },
  testIdentifiers: ["table2StatusEmpty", "accessControl", "credentials", "activeCalculator"]
}];