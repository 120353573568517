import CO from './CO.png'
import CY from './CY.png'
import EL from './EL.png'
import PE from './PE.png'
import SP from './SP.png'
import TO from './TO.png'

export {
  CO,
  CY,
  EL,
  PE,
  SP,
  TO
}
