import isEmpty from 'lodash/isEmpty'
import sum from 'lodash/sum'

import { getColumnFrom2DArray } from '@e2grnd/ui-core/dist/core/lib'

import { getComponents, getMeMultiplier, getNPSPipeThickness, getPipeNPS, getPipeNPS2, getSigmaMultiplier } from './getPipeThickness'

export function getReplacements(table) {
  const inp0 = getColumnFrom2DArray(table, 0)
  const inp1 = getColumnFrom2DArray(table, 1)
  const inp2 = getColumnFrom2DArray(table, 2)
  const inp3 = getColumnFrom2DArray(table, 3)
  const inp4 = getColumnFrom2DArray(table, 4)
  const inp5 = getColumnFrom2DArray(table, 5)
  const inp6 = getColumnFrom2DArray(table, 6)
  const inp7 = getColumnFrom2DArray(table, 7)
  const inp8 = getColumnFrom2DArray(table, 8)
  const newTable = []
  for(let i = 0; i < inp0.length; i += 1) {
    const row = [inp0[i], inp1[i], inp2[i], inp3[i], inp4[i], inp5[i], inp6[i], inp7[i], inp8[i]]
    if(((typeof inp6[i] !== 'undefined' && inp6[i] !== null) && inp6[i] !== '') && inp6[i] === 0) {
      row[7] = 'N/A'
    } else if(((typeof inp6[i] !== 'undefined' && inp6[i] !== null) && inp6[i] !== '') && inp6[i] > 0) {
      if(row[7] === 'N/A') {
        row[7] = ''
      }
    }
    newTable.push(row)
  }
  return newTable
}

export function getStep3ThicknessTable(table, evtTable) {
  const inp0 = getColumnFrom2DArray(table, 0)
  const inp1 = getColumnFrom2DArray(table, 1)
  const inp2 = getColumnFrom2DArray(table, 2)
  const inp3 = getColumnFrom2DArray(table, 3)
  const inp4 = getColumnFrom2DArray(table, 4)
  const evtNumReadings = getColumnFrom2DArray(evtTable, 3)
  const newTable = []
  for(let i = 0; i < inp0.length; i += 1) {
    const row = [inp0[i], inp1[i], inp2[i], inp3[i], inp4[i]]
    if(((typeof inp3[i] !== 'undefined' && inp3[i] !== null) && inp3[i] !== '') && inp3[i] === 'RT') {
      row[4] = evtNumReadings[0]
    }
    newTable.push(row)
  }
  return newTable
}

export function getEvtTable(table) {
  const newTable = []
  const col0 = ['RT', 'UT Scan']
  const defaultCol1 = [0.9, 0.9]
  const defaultCol2 = [0.9, 0.9]
  const defaultCol3 = [1.0, 1.0]
  let inp1
  let inp2
  try {
    inp1 = getColumnFrom2DArray(table, 1)
  } catch{
    inp1 = []
  }
  try {
    inp2 = getColumnFrom2DArray(table, 2)
  } catch{
    inp2 = []
  }
  for(let i = 0; i < col0.length; i += 1) {
    const row = [col0[i], defaultCol1[i], defaultCol2[i], defaultCol3[i]]
    if(inp1.length === col0.length && inp2.length === col0.length) {
      if((typeof inp1[i] !== 'undefined' && inp1[i] !== null) && inp1[i] !== '') {
        row[1] = Math.max(0.2, Math.min(inp1[i], 0.99))
      }
      if((typeof inp2[i] !== 'undefined' && inp2[i] !== null) && inp2[i] !== '') {
        row[2] = Math.min(0.999, Math.max(0.5, inp2[i]))
      }
    }
    row[3] = Math.ceil(Math.log(1 - row[2]) / Math.log(1 - row[1]))
    newTable.push(row)
  }
  return newTable
}

export function getNumReadingsTable(dataTable) {
  const npsList = getPipeNPS2()
  const utCensoredList = [2, 2, 2, 2, 2, 2, 2, 2, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4]
  const rtCensoredList = [4, 4, 4, 4, 8, 8, 8, 8, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20]
  let inp1
  let inp2
  try {
    inp1 = getColumnFrom2DArray(dataTable, 1)
  } catch{
    inp1 = []
  }
  try {
    inp2 = getColumnFrom2DArray(dataTable, 2)
  } catch{
    inp2 = []
  }
  const newData = []
  for(let i = 0; i < npsList.length; i += 1) {
    const row = [npsList[i], utCensoredList[i], rtCensoredList[i]]
    if((typeof inp1[i] !== 'undefined' && inp1[i] !== null) && inp1[i] !== '') {
      row[1] = inp1[i]
    }
    if((typeof inp2[i] !== 'undefined' && inp2[i] !== null) && inp2[i] !== '') {
      row[2] = inp2[i]
    }
    newData.push(row)
  }
  return newData
}

export function getMultiplierTable(dataTable) {
  const components = getComponents()
  let inp1
  let inp2
  try {
    inp1 = getColumnFrom2DArray(dataTable, 1)
  } catch{
    inp1 = []
  }
  try {
    inp2 = getColumnFrom2DArray(dataTable, 2)
  } catch{
    inp2 = []
  }
  const newData = []
  for(let i = 0; i < components.length; i += 1) {
    const row = [components[i]]
    if((typeof inp1[i] !== 'undefined' && inp1[i] !== null) && inp1[i] !== '') {
      row.push(inp1[i])
    } else {
      row.push(getSigmaMultiplier(components[i]))
    }
    if((typeof inp2[i] !== 'undefined' && inp2[i] !== null) && inp2[i] !== '') {
      row.push(inp2[i])
    } else {
      row.push(getMeMultiplier(components[i]))
    }
    newData.push(row)
  }
  return newData
}

export function getNpsTable(dataTable) {
  const nps = ['0.125', '0.125', '0.125', '0.25', '0.25', '0.25', '0.375', '0.375', '0.375', '0.5', '0.5', '0.5', '0.5', '0.5', '0.5', '0.75', '0.75', '0.75', '0.75', '0.75', '0.75', '0.75', '0.75', '0.75', '1', '1', '1', '1', '1', '1', '1', '1', '1.25', '1.25', '1.25', '1.25', '1.25', '1.25', '1.25', '1.5', '1.5', '1.5', '1.5', '1.5', '1.5', '1.5', '2', '2', '2', '2', '2', '2', '2.5', '2.5', '2.5', '2.5', '2.5', '3', '3', '3', '3', '3', '3.5', '3.5', '3.5', '4', '4', '4', '4', '4', '4', '4', '5', '5', '5', '5', '5', '5', '6', '6', '6', '6', '6', '6', '8', '8', '8', '8', '8', '8', '8', '8', '8', '10', '10', '10', '10', '10', '10', '10', '10', '10', '12', '12', '12', '12', '12', '12', '12', '12', '12', '12', '12', '14', '14', '14', '14', '14', '14', '14', '14', '14', '14', '16', '16', '16', '16', '16', '16', '16', '16', '16', '16', '18', '18', '18', '18', '18', '18', '18', '18', '18', '18', '18', '20', '20', '20', '20', '20', '20', '20', '20', '20', '20', '24', '24', '24', '24', '24', '24', '24', '24', '24', '24', '24', '26', '26', '26', '28', '28', '28', '28', '30', '30', '30', '30', '32', '32', '32', '32', '34', '34', '34', '34', '36', '36', '36', '36', '42', '42', '48', '48']
  const sch = ['10', '40', '80', '10', '40', '80', '10', '40', '80', '40', '80', '160', 'XXS', '150LWN', '300LWN', '5S', '40', '80', '160', 'XXS', '150LWN1', '150LWN2', '6000CPLG', '300LWN', '40', '80', '160', 'XXS', '150LWN1', '150LWN2', '6000CPLG', '300LWN', '40', '80', '160', 'XXS', '150LWN1', '150LWN2', '300LWN', '40', '80', '160', 'XXS', '150LWN1', '150LWN2', '300LWN', '40', '80', '160', 'XXS', '150LWN1', 'LWN', '10', '40', '80', '160', 'XXS', '10', '40', '80', '160', 'XXS', '10', '40', '80', '10', '40', '80', '120', '160', 'XXS', 'XXXS', '10', '40', '80', '120', '160', 'XXS', '10', '40', '80', '120', '160', 'XXS', '20', '30', '40', '60', '80', '120', '140', 'XXS', '160', '20', '30', '40', 'XS', '80', '100', '120', '140', '160', '20', '30', 'STD', '40', 'XS', '60', '80', '100', '120', '140', '160', '10', '20', 'STD', 'XS', '60', '80', '100', '120', '140', '160', '10', '20', 'STD', '40', '60', '80', '100', '120', '160', '160', '10', '20', 'STD', 'XS', '40', '80', '80', '100', '120', '140', '160', '10', 'STD', 'XS', '40', '60', '80', '100', '120', '140', '160', '10', 'STD', 'XS', '30', '40', '60', '80', '100', '120', '140', '160', '10', 'STD', '80', '10', '30', '40', 'STD', '10', '40', 'STD', '30', '10', 'STD', '80', '30', '10', 'STD', '80', '30', '10', 'STD', '80', '30', 'STD', '80', 'STD', '80']
  const wall = [0.049, 0.068, 0.095, 0.065, 0.088, 0.119, 0.065, 0.091, 0.126, 0.109, 0.147, 0.187, 0.294, 0.375, 0.5, 0.065, 0.113, 0.154, 0.219, 0.308, 0.437, 0.5, 0.532, 0.562, 0.133, 0.179, 0.25, 0.358, 0.437, 0.5, 0.532, 0.562, 0.14, 0.191, 0.25, 0.382, 0.5, 0.562, 0.625, 0.145, 0.2, 0.281, 0.4, 0.5, 0.562, 0.625, 0.154, 0.218, 0.343, 0.436, 0.562, 0.625, 0.12, 0.203, 0.276, 0.375, 0.552, 0.12, 0.216, 0.3, 0.437, 0.6, 0.12, 0.226, 0.318, 0.12, 0.237, 0.337, 0.437, 0.531, 0.674, 1, 0.134, 0.258, 0.375, 0.5, 0.625, 0.75, 0.134, 0.28, 0.432, 0.562, 0.718, 0.864, 0.25, 0.277, 0.322, 0.406, 0.5, 0.718, 0.812, 0.875, 0.906, 0.25, 0.307, 0.365, 0.5, 0.593, 0.718, 0.843, 1, 1.125, 0.25, 0.33, 0.375, 0.406, 0.5, 0.562, 0.687, 0.843, 1, 1.125, 1.312, 0.25, 0.312, 0.375, 0.5, 0.593, 0.75, 0.937, 1.093, 1.25, 1.406, 0.25, 0.312, 0.375, 0.5, 0.656, 0.843, 1.031, 1.218, 1.437, 1.593, 0.25, 0.312, 0.375, 0.5, 0.562, 0.75, 0.937, 1.156, 1.375, 1.562, 1.781, 0.25, 0.375, 0.5, 0.593, 0.812, 1.031, 1.281, 1.5, 1.75, 1.968, 0.25, 0.375, 0.5, 0.562, 0.687, 0.968, 1.218, 1.531, 1.812, 2.062, 2.343, 0.312, 0.375, 0.5, 0.312, 0.375, 0.5, 0.5, 0.312, 0.375, 0.5, 0.625, 0.312, 0.375, 0.5, 0.625, 0.312, 0.375, 0.5, 0.625, 0.312, 0.375, 0.5, 0.625, 0.375, 0.5, 0.375, 0.5]
  const npsMax = [0.051, 0.071, 0.1, 0.068, 0.092, 0.125, 0.068, 0.096, 0.132, 0.114, 0.154, 0.196, 0.309, 0.394, 0.525, 0.068, 0.119, 0.162, 0.23, 0.323, 0.459, 0.525, 0.562, 0.59, 0.14, 0.188, 0.263, 0.376, 0.459, 0.525, 0.562, 0.59, 0.147, 0.201, 0.263, 0.401, 0.525, 0.59, 0.656, 0.152, 0.21, 0.295, 0.42, 0.525, 0.59, 0.656, 0.162, 0.229, 0.36, 0.458, 0.59, 0.656, 0.126, 0.213, 0.29, 0.394, 0.58, 0.126, 0.227, 0.315, 0.459, 0.63, 0.126, 0.237, 0.334, 0.126, 0.249, 0.354, 0.459, 0.558, 0.708, 1.05, 0.141, 0.271, 0.394, 0.525, 0.656, 0.788, 0.141, 0.294, 0.454, 0.59, 0.754, 0.907, 0.263, 0.291, 0.338, 0.426, 0.525, 0.754, 0.853, 0.919, 0.951, 0.263, 0.322, 0.383, 0.525, 0.623, 0.754, 0.885, 1.05, 1.181, 0.263, 0.347, 0.394, 0.426, 0.525, 0.59, 0.721, 0.885, 1.05, 1.181, 1.378, 0.263, 0.328, 0.394, 0.525, 0.623, 0.788, 0.984, 1.148, 1.313, 1.476, 0.263, 0.328, 0.394, 0.525, 0.689, 0.885, 1.083, 1.279, 1.509, 1.673, 0.263, 0.328, 0.394, 0.525, 0.59, 0.788, 0.984, 1.214, 1.444, 1.64, 1.87, 0.263, 0.394, 0.525, 0.623, 0.853, 1.083, 1.345, 1.575, 1.838, 2.066, 0.263, 0.394, 0.525, 0.59, 0.721, 1.016, 1.279, 1.608, 1.903, 2.165, 2.46, 0.328, 0.394, 0.525, 0.328, 0.394, 0.525, 0.525, 0.328, 0.394, 0.525, 0.656, 0.328, 0.394, 0.525, 0.656, 0.328, 0.394, 0.525, 0.656, 0.328, 0.394, 0.525, 0.656, 0.394, 0.525, 0.394, 0.525]
  const npsStd = [0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.0035, 0.00385, 0.0035, 0.0035, 0.0035, 0.0035, 0.0042, 0.0049, 0.00525, 0.00525, 0.0056, 0.0035, 0.00385, 0.0042, 0.00525, 0.0056, 0.00595, 0.00665, 0.00735, 0.0077, 0.0042, 0.0049, 0.00525, 0.0056, 0.00595, 0.0063, 0.007, 0.00805, 0.00875, 0.0091, 0.0098, 0.0049, 0.0056, 0.0063, 0.007, 0.0077, 0.00875, 0.0098, 0.0105, 0.0112, 0.0119, 0.0056, 0.0063, 0.007, 0.00805, 0.00945, 0.0105, 0.01155, 0.0126, 0.01365, 0.01435, 0.0063, 0.00735, 0.00805, 0.0091, 0.0098, 0.0112, 0.0126, 0.014, 0.01505, 0.0161, 0.01715, 0.00735, 0.00875, 0.01015, 0.0112, 0.01295, 0.0147, 0.0161, 0.0175, 0.0189, 0.0203, 0.00875, 0.0105, 0.01225, 0.01295, 0.01435, 0.01715, 0.0189, 0.02135, 0.0231, 0.02485, 0.02625, 0.0105, 0.01155, 0.0133, 0.0112, 0.01225, 0.01435, 0.01435, 0.0119, 0.0133, 0.0154, 0.01715, 0.01295, 0.014, 0.0161, 0.0182, 0.01365, 0.01505, 0.01715, 0.01925, 0.01435, 0.01575, 0.0182, 0.0203, 0.01855, 0.02135, 0.021, 0.0245]
  const preferredSch = [0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 1, 0]
  const inp2 = getColumnFrom2DArray(dataTable, 2)
  const inp3 = getColumnFrom2DArray(dataTable, 3)
  const inp4 = getColumnFrom2DArray(dataTable, 4)
  const inp5 = getColumnFrom2DArray(dataTable, 5)
  const newData = []
  for(let i = 0; i < nps.length; i += 1) {
    const row = [nps[i], sch[i], wall[i], npsMax[i], npsStd[i], preferredSch[i]]
    if((typeof inp2[i] !== 'undefined' && inp2[i] !== null) && inp2[i] !== '') {
      row[2] = inp2[i]
    }
    if((typeof inp3[i] !== 'undefined' && inp3[i] !== null) && inp3[i] !== '') {
      row[3] = inp3[i]
    }
    if((typeof inp4[i] !== 'undefined' && inp4[i] !== null) && inp4[i] !== '') {
      row[4] = inp4[i]
    }
    if((typeof inp5[i] !== 'undefined' && inp5[i] !== null) && inp5[i] !== '') {
      row[5] = inp5[i]
    }
    newData.push(row)
  }
  return newData
}

export function getUpdatedStep2Table(dataTable) {
  const inp0 = getColumnFrom2DArray(dataTable, 0)
  const inp1 = getColumnFrom2DArray(dataTable, 1)
  const inp2 = getColumnFrom2DArray(dataTable, 2)
  const inp3 = getColumnFrom2DArray(dataTable, 3)
  const inp4 = getColumnFrom2DArray(dataTable, 4)
  const inp5 = getColumnFrom2DArray(dataTable, 5)
  const inp6 = getColumnFrom2DArray(dataTable, 6)
  const inp7 = getColumnFrom2DArray(dataTable, 7)
  let flowOrderEmpty = true
  inp1.forEach((cell) => {
    if((typeof cell !== 'undefined' && cell !== null) && cell !== '') {
      flowOrderEmpty = false
    }
  })
  const newData = []
  for(let i = 0; i < inp0.length; i += 1) {
    const row = [inp0[i], inp1[i], inp2[i], inp3[i], inp4[i], inp5[i], inp6[i], inp7[i]]
    if(flowOrderEmpty && ((typeof inp1[i] === 'undefined' || inp1[i] === null) || inp1[i] === '')) {
      if((typeof inp0[i] !== 'undefined' && inp0[i] !== null) && inp0[i] !== '') {
        row[1] = i
      }
    }
    if((typeof inp3[i] !== 'undefined' && inp3[i] !== null) && inp3[i] !== '') {
      if((typeof inp4[i] !== 'undefined' && inp4[i] !== null) && inp4[i] !== '') {
        if((typeof inp5[i] === 'undefined' || inp5[i] === null) || inp5[i] === '') {
          row[5] = getNPSPipeThickness(inp3[i], inp4[i])
        }
      }
    }
    newData.push(row)
  }
  return newData
}

export function getUpdatedStep2ReplacementTable(step2Table, replacementTable) {
  const inp0 = getColumnFrom2DArray(step2Table, 0)
  const inp7 = getColumnFrom2DArray(step2Table, 7)
  const col0 = getColumnFrom2DArray(replacementTable, 0)
  const col1 = getColumnFrom2DArray(replacementTable, 1)
  const col2 = getColumnFrom2DArray(replacementTable, 2)
  const expectedLength = sum(inp7)
  const step2ReplacementTableObject = {}
  if(expectedLength > 0) {
    for(let j = 0; j < col0.length; j += 1) {
      const objectKeys = Object.keys(step2ReplacementTableObject)
      if(!objectKeys.includes(col0[j])) {
        step2ReplacementTableObject[col0[j]] = {}
      }
      step2ReplacementTableObject[col0[j]][col1[j]] = col2[j]
    }
    const newData = []
    for(let i = 0; i < inp0.length; i += 1) {
      if(inp7[i] > 0) {
        if(typeof step2ReplacementTableObject[inp0[i]] === 'undefined') {
          for(let k = 0; k < inp7[i]; k += 1) {
            newData.push([inp0[i], k, ''])
          }
        } else {
          for(let m = 0; m < inp7[i]; m += 1) {
            if(typeof step2ReplacementTableObject[inp0[i]][m] === 'undefined') {
              newData.push([inp0[i], m, ''])
            } else {
              newData.push([inp0[i], m, step2ReplacementTableObject[inp0[i]][m]])
            }
          }
        }
      }
    }
    return newData
  } else {
    return replacementTable
  }
}

export function getUpdatedStep3Table(step2Table, dataTable, censoredTable) {
  const inp0 = getColumnFrom2DArray(dataTable, 0)
  const inp1 = getColumnFrom2DArray(dataTable, 1)
  const inp2 = getColumnFrom2DArray(dataTable, 2)
  const inp3 = getColumnFrom2DArray(dataTable, 3)
  const inp4 = getColumnFrom2DArray(dataTable, 4)
  const cmlIds = getColumnFrom2DArray(step2Table, 0)
  let npsCol
  try {
    npsCol = getColumnFrom2DArray(step2Table, 3)
  } catch{
    npsCol = []
  }
  const col0 = getPipeNPS()
  const col1 = getColumnFrom2DArray(censoredTable, 1)
  const col2 = getColumnFrom2DArray(censoredTable, 2)
  const newData = []
  for(let i = 0; i < inp0.length; i += 1) {
    const row = [inp0[i], inp1[i], inp2[i], inp3[i], inp4[i]]
    const cmlId = inp0[i]
    const cmlIdIndex = cmlIds.indexOf(cmlId)
    if(cmlIdIndex !== -1) {
      if((typeof inp4[i] === 'undefined' || inp4[i] === null) || inp4[i] === '') {
        if((typeof inp3[i] !== 'undefined' && inp3[i] !== null) && inp3[i] !== '') {
          if((typeof npsCol[cmlIdIndex] !== 'undefined' && npsCol[cmlIdIndex] !== null) && npsCol[cmlIdIndex] !== '') {
            const npsIndex = col0.indexOf(npsCol[cmlIdIndex])
            if(inp3[i] === 'UT') {
              row[4] = col1[npsIndex]
            } else if(inp3[i] === 'RT') {
              row[4] = col2[npsIndex]
            } else {
              row[4] = ''
            }
          } else {
            /* eslint-disable no-lonely-if */
            if(inp3[i] === 'VT') {
              row[4] = ''
            } else {
              row[4] = 1
            }
          }
        }
      }
    }
    newData.push(row)
  }
  return newData
}

export function checkTableEmpty(dataTable, whichTable) {
  const inp0 = getColumnFrom2DArray(dataTable, 0)
  const inp1 = getColumnFrom2DArray(dataTable, 1)
  const inp2 = getColumnFrom2DArray(dataTable, 2)
  const inp3 = getColumnFrom2DArray(dataTable, 3)
  const inp4 = getColumnFrom2DArray(dataTable, 4)
  let inp5
  if(whichTable === 1) {
    inp5 = getColumnFrom2DArray(dataTable, 5)
  }
  let status = false
  if(inp0.length > 0) {
    for(let i = 0; i < inp0.length; i += 1) {
      if((inp0[0] === null || inp0[0] === '') || typeof inp0[0] === 'undefined') {
        status = true
      }
      if(whichTable === 1) {
        if(inp0[i] !== null && inp0[i] !== '' && typeof inp0[i] !== 'undefined') {
          const conditionCol1 = ((inp1[i] === null || inp1[i] === '') || typeof inp1[i] === 'undefined')
          const conditionCol2 = ((inp2[i] === null || inp2[i] === '') || typeof inp2[i] === 'undefined')
          const conditionCol3 = ((inp3[i] === null || inp3[i] === '') || typeof inp3[i] === 'undefined')
          const conditionCol4 = ((inp4[i] === null || inp4[i] === '') || typeof inp4[i] === 'undefined')
          const conditionCol5 = ((inp5[i] === null || inp5[i] === '') || typeof inp5[i] === 'undefined')
          if((((conditionCol1 || conditionCol2) || conditionCol3) || conditionCol4) || conditionCol5) {
            status = true
          }
        }
      } else {
        if(inp0[i] !== null && inp0[i] !== '' && typeof inp0[i] !== 'undefined') {
          const conditionCol1 = ((inp1[i] === null || inp1[i] === '') || typeof inp1[i] === 'undefined')
          const conditionCol2 = ((inp2[i] === null || inp2[i] === '') || typeof inp2[i] === 'undefined')
          const conditionCol3 = ((inp3[i] === null || inp3[i] === '') || typeof inp3[i] === 'undefined')
          const conditionCol4 = ((inp4[i] === null || inp4[i] === '') || typeof inp4[i] === 'undefined')
          if((((conditionCol1 || conditionCol2) || conditionCol3) || conditionCol4)) {
            status = true
          }
        }
      }
    }
  } else {
    status = true
  }
  return status
}

export function checkStep2TableEmpty(dataTable) {
  const inp0 = getColumnFrom2DArray(dataTable, 0)
  const inp1 = getColumnFrom2DArray(dataTable, 1)
  const inp2 = getColumnFrom2DArray(dataTable, 2)
  const inp3 = getColumnFrom2DArray(dataTable, 3)
  const inp4 = getColumnFrom2DArray(dataTable, 4)
  const inp5 = getColumnFrom2DArray(dataTable, 5)
  const inp6 = getColumnFrom2DArray(dataTable, 6)
  const inp7 = getColumnFrom2DArray(dataTable, 7)
  const inp8 = getColumnFrom2DArray(dataTable, 8)
  let status = false
  if(inp0.length > 0) {
    for(let i = 0; i < inp0.length; i += 1) {
      if((inp0[0] === null || inp0[0] === '') || typeof inp0[0] === 'undefined') {
        status = true
      }
      if(inp0[i] !== null && inp0[i] !== '' && typeof inp0[i] !== 'undefined') {
        const conditionCol1 = ((inp1[i] === null || inp1[i] === '') || typeof inp1[i] === 'undefined')
        const conditionCol2 = ((inp2[i] === null || inp2[i] === '') || typeof inp2[i] === 'undefined')
        const conditionCol3 = ((inp3[i] === null || inp3[i] === '') || typeof inp3[i] === 'undefined')
        const conditionCol4 = ((inp4[i] === null || inp4[i] === '') || typeof inp4[i] === 'undefined')
        const conditionCol5 = ((inp5[i] === null || inp5[i] === '') || typeof inp5[i] === 'undefined')
        const conditionCol6 = ((inp6[i] === null || inp6[i] === '') || typeof inp6[i] === 'undefined')
        const conditionCol7 = ((inp7[i] === null || inp7[i] === '') || typeof inp7[i] === 'undefined')
        const conditionCol8 = ((inp8[i] === null || inp8[i] === '') || typeof inp8[i] === 'undefined')
        if(((((((conditionCol1 || conditionCol2) || conditionCol5) || conditionCol6) || conditionCol7) || conditionCol3) || conditionCol4) || conditionCol8) {
          status = true
        }
      }
    }
  } else {
    status = true
  }
  return status
}

export function checkStep2TableEmpty2(dataTable) {
  const inp0 = getColumnFrom2DArray(dataTable, 0)
  const inp1 = getColumnFrom2DArray(dataTable, 1)
  const inp2 = getColumnFrom2DArray(dataTable, 2)
  const inp5 = getColumnFrom2DArray(dataTable, 5)
  const inp6 = getColumnFrom2DArray(dataTable, 6)
  const inp3 = getColumnFrom2DArray(dataTable, 3)
  const inp4 = getColumnFrom2DArray(dataTable, 4)
  const inp7 = getColumnFrom2DArray(dataTable, 7)
  const inp8 = getColumnFrom2DArray(dataTable, 8)
  let status = false
  if(inp0.length > 0) {
    for(let i = 0; i < inp0.length; i += 1) {
      if((inp0[0] === null || inp0[0] === '') || typeof inp0[0] === 'undefined') {
        status = true
      }
      if(inp0[i] !== null && inp0[i] !== '' && typeof inp0[i] !== 'undefined') {
        const conditionCol1 = ((inp1[i] === null || inp1[i] === '') || typeof inp1[i] === 'undefined')
        const conditionCol2 = ((inp2[i] === null || inp2[i] === '') || typeof inp2[i] === 'undefined')
        const conditionCol3 = ((inp3[i] === null || inp3[i] === '') || typeof inp3[i] === 'undefined')
        const conditionCol4 = ((inp4[i] === null || inp4[i] === '') || typeof inp4[i] === 'undefined')
        const conditionCol5 = ((inp5[i] === null || inp5[i] === '') || typeof inp5[i] === 'undefined')
        const conditionCol6 = ((inp6[i] === null || inp6[i] === '') || typeof inp6[i] === 'undefined')
        const conditionCol7 = ((inp7[i] === null || inp7[i] === '') || typeof inp7[i] === 'undefined')
        const conditionCol8 = ((inp8[i] === null || inp8[i] === '') || typeof inp8[i] === 'undefined')
        if(((((((conditionCol1 || conditionCol2) || conditionCol5) || conditionCol6) || conditionCol3) || conditionCol4) || conditionCol7) || conditionCol8) {
          status = true
        }
      }
    }
  } else {
    status = true
  }
  return status
}

export function checkStep2TableReplacements(dataTable, step2Table) {
  const inp6 = getColumnFrom2DArray(step2Table, 6)
  const expectedLength = sum(inp6)
  if(expectedLength === 0) {
    return false
  } else {
    if(isEmpty(dataTable)) {
      return true
    } else {
      const inp0 = getColumnFrom2DArray(dataTable, 0)
      const inp1 = getColumnFrom2DArray(dataTable, 1)
      if(inp1.length < expectedLength) {
        return true
      }
      if(inp0.length > 0) {
        let status = false
        for(let i = 0; i < inp0.length; i += 1) {
          if((inp0[0] === null || inp0[0] === '') || typeof inp0[0] === 'undefined') {
            status = true
          }
          if(inp0[i] !== null && inp0[i] !== '' && typeof inp0[i] !== 'undefined') {
            const conditionCol1 = ((inp1[i] === null || inp1[i] === '') || typeof inp1[i] === 'undefined')
            if(conditionCol1) {
              status = true
            }
          }
        }
        return status
      } else {
        return true
      }
    }
  }
}

export function checkStep2TableForReplacements(dataTable) {
  if(typeof dataTable === 'undefined') {
    return false
  }
  const inp7 = getColumnFrom2DArray(dataTable, 7)
  if(typeof inp7 === 'undefined') {
    return false
  }
  const lodashSum = sum(inp7)
  if(lodashSum > 0) {
    return true
  } else {
    return false
  }
}

export function checkStep2TableDuplicate(dataTable) {
  let status = false
  const inp0 = getColumnFrom2DArray(dataTable, 0)
  const inp1 = getColumnFrom2DArray(dataTable, 1)
  const newCol = []
  const newCol1 = []
  for(let i = 0; i < inp0.length; i += 1) {
    if(inp0[i] !== null && inp0[i] !== '' && typeof inp0[i] !== 'undefined') {
      if(newCol.includes(inp0[i])) {
        status = true
      }
      newCol.push(inp0[i])
    }
  }
  for(let j = 0; j < inp1.length; j += 1) {
    if(inp1[j] !== null && inp1[j] !== '' && typeof inp1[j] !== 'undefined') {
      if(newCol1.includes(inp1[j])) {
        status = true
      }
      newCol1.push(inp1[j])
    }
  }
  return status
}
