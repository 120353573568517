import React from 'react'
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons'

import CalculatorHeader from '@e2grnd/ui-core/dist/components/CalculatorHeader/CalculatorHeader'
import JobsDashboardRouter from '@e2grnd/ui-core/dist/routes/JobsDashboardRouter'

export default function AppRoot() {
  return (
    <CalculatorHeader title="Jobs" icon={faBriefcase} baseBreadcrumbs={[]}>
      <JobsDashboardRouter />
    </CalculatorHeader>
  )
}
