import beadConditionsAmbient from './beadConditionsAmbient.png'
import beadConditionsWelding from './beadConditionsWelding.png'
import beadGeometryMainline from './beadGeometryMainline.png'
import beadMaterials from './beadMaterials.png'
import beadMaterialsMainline from './beadMaterialsMainline.png'
import beadMaterialsProcessfluid from './beadMaterialsProcessfluid.png'
import beadOnPlate from './BeadOnPlate.jpg'
import branch from './Branch.jpg'
import branchConditionsAmbient from './branchConditionsAmbient.png'
import branchConditionsWelding from './branchConditionsWelding.png'
import branchGeometryBranch from './branchGeometryBranch.png'
import branchGeometryMainline from './branchGeometryMainline.png'
import branchMaterials from './branchMaterials.png'
import branchMaterialsBranch from './branchMaterialsBranch.png'
import branchMaterialsMainline from './branchMaterialsMainline.png'
import branchMaterialsProcessfluid from './branchMaterialsProcessfluid.png'
import branchProcessConditions from './branchProcessConditions.png'
import branchWeldolet from './BranchWeldolet.jpg'
import heatInputEquation from './heatInputEquation.png'
import hottapOutputFig3 from './hottapOutputFig3.png'
import hottapOutputFig4 from './hottapOutputFig4.png'
import hottapSleeve from './HottapSleeve.jpg'
import hottapThermocoupleLocationBeadonpipe from './hottapThermocoupleLocationBeadonpipe.png'
import hottapThermocoupleLocationBranch from './hottapThermocoupleLocationBranch.png'
import hottapThermocoupleLocationHeatsinkcapacity from './hottapThermocoupleLocationHeatsinkcapacity.png'
import hottapThermocoupleLocationSleeve from './hottapThermocoupleLocationSleeve.png'
import repairSleeve from './RepairSleeve.jpg'
import sleeveConditionsAmbient from './sleeveConditionsAmbient.png'
import sleeveConditionsWelding from './sleeveConditionsWelding.png'
import sleeveGeometryMainline from './sleeveGeometryMainline.png'
import sleeveGeometrySleeve from './sleeveGeometrySleeve.png'
import sleeveMaterials from './sleeveMaterials.png'
import sleeveMaterialsMainline from './sleeveMaterialsMainline.png'
import sleeveMaterialsProcessfluid from './sleeveMaterialsProcessfluid.png'
import sleeveMaterialsSleeve from './sleeveMaterialsSleeve.png'
import sleeveWeldonlyGeometry from './sleeveWeldonlyGeometry.png'
import torchConditionsAmbient from './torchConditionsAmbient.png'
import torchConditionsWelding from './torchConditionsWelding.png'
import torchGeometryMainline from './torchGeometryMainline.png'
import torchMaterialsMainline from './torchMaterialsMainline.png'
import torchMaterialsProcessfluid from './torchMaterialsProcessfluid.png'
import torchTest from './TorchTest.jpg'
import weldoletBranchMaterials from './weldoletBranchMaterials.png'
import weldoletConditionsAmbient from './weldoletConditionsAmbient.png'
import weldoletConditionsWelding from './weldoletConditionsWelding.png'
import weldoletGeometryBranch from './weldoletGeometryBranch.png'
import weldoletGeometryMainline from './weldoletGeometryMainline.png'
import weldoletGeometryWeldolet from './weldoletGeometryWeldolet.png'
import weldoletMaterials from './weldoletMaterials.png'
import weldoletMaterialsMainline from './weldoletMaterialsMainline.png'
import weldoletMaterialsProcessfluid from './weldoletMaterialsProcessfluid.png'
import weldoletMaterialsWeldolet from './weldoletMaterialsWeldolet.png'
import weldonlyConditions from './weldonlyConditions.png'

export {
  beadConditionsAmbient,
  beadConditionsWelding,
  beadGeometryMainline,
  beadMaterials,
  beadMaterialsMainline,
  beadMaterialsProcessfluid,
  beadOnPlate,
  branch,
  branchConditionsAmbient,
  branchConditionsWelding,
  branchGeometryBranch,
  branchGeometryMainline,
  branchMaterials,
  branchMaterialsBranch,
  branchMaterialsMainline,
  branchMaterialsProcessfluid,
  branchProcessConditions,
  branchWeldolet,
  heatInputEquation,
  hottapOutputFig3,
  hottapOutputFig4,
  hottapSleeve,
  hottapThermocoupleLocationBeadonpipe,
  hottapThermocoupleLocationBranch,
  hottapThermocoupleLocationHeatsinkcapacity,
  hottapThermocoupleLocationSleeve,
  repairSleeve,
  sleeveConditionsAmbient,
  sleeveConditionsWelding,
  sleeveGeometryMainline,
  sleeveGeometrySleeve,
  sleeveMaterials,
  sleeveMaterialsMainline,
  sleeveMaterialsProcessfluid,
  sleeveMaterialsSleeve,
  sleeveWeldonlyGeometry,
  torchConditionsAmbient,
  torchConditionsWelding,
  torchGeometryMainline,
  torchMaterialsMainline,
  torchMaterialsProcessfluid,
  torchTest,
  weldoletBranchMaterials,
  weldoletConditionsAmbient,
  weldoletConditionsWelding,
  weldoletGeometryBranch,
  weldoletGeometryMainline,
  weldoletGeometryWeldolet,
  weldoletMaterials,
  weldoletMaterialsMainline,
  weldoletMaterialsProcessfluid,
  weldoletMaterialsWeldolet,
  weldonlyConditions
}
