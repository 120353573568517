/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

import { getCircCrGraph } from '../lib/getResultCases.js'

const RCircuitCorrosionRates = ({ results, circuit, circuitCorrosionRates }) => {
  const graphData = getCircCrGraph(circuit, results.epRate)

  return (
    <div className="cf w-100">
      <div>
        <b><i>{circuit}</i> Corrosion Rates</b>
        <div>
          <DataTableDisplay id="circuitCorrosionRates" table={circuitCorrosionRates} containerHeight="75px" />
          <br /><br />
        </div>

        <div className="cf w-100">
          {results.epRate
            ? (
              <div className="fl w-100">
                <div>
                  <DataGraph plotClass="data-graph-85" data={graphData.traces} layout={graphData.layout} />
                </div>
              </div>
            )
            : <p>No Graphs to Display</p>}
        </div>
      </div>

      <div>
        <br/><br/><br/>
        <b>B-Values represent the credible interval levels for the corrosion rates. </b>
        <i>B50 = Median;  B75 = 3rd Quartile; B90, B95, B99 are the 90th, 95th, and 99th Precentiles Respectively</i>
      </div>
    </div>
  )
}

RCircuitCorrosionRates.propTypes = {
  results: PropTypes.object,
  circuit: PropTypes.string,
  circuitCorrosionRates: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  circuit: state.workflow.fields.circuit,
  circuitCorrosionRates: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.circuitCorrosionRates : undefined,
})

export default connect(mapStateToProps, null)(RCircuitCorrosionRates)
