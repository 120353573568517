/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'

import { getGelmanRubinGraphs } from '../lib/getResultCases.js'

const GelmanRubin = ({ results }) => {
  let keys
  const values = []
  const items = []
  let graphData
  let graphKeys
  let firstKey
  if(typeof results.gelmanRubinData !== 'undefined') {
    const jsonObject = JSON.parse(results.gelmanRubinData[0])
    keys = Object.keys(jsonObject)
    let i = 0
    keys.forEach((key) => {
      items.push(<E2GSelectFieldOption label={`${key}`} value={`${key}`} key={i} />)
      values.push(`${key}`)
      i += 1
    })
    graphData = getGelmanRubinGraphs(results.gelmanRubinData, results.gelmanRubinKeys ? results.gelmanRubinKeys[0] : values[0])
    graphKeys = Object.keys(graphData)
    firstKey = graphKeys[0]
  }
  return (
    <div className="cf w-100">
      {results.gelmanRubinData
        ? (
          <div className="fl w-100">
            <div className="fl w-100">
              <E2GSelectFieldIndexer name="gelmanRubinKeys" value={results.gelmanRubinKeys ? results.gelmanRubinKeys[0] : values[0]} label="Select Parameter to Plot">
                {items}
              </E2GSelectFieldIndexer>
              <div>
                <DataGraph plotClass="data-graph-85" data={graphData[firstKey].traces} layout={graphData[firstKey].layout} />
              </div>
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

GelmanRubin.propTypes = {
  results: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
})

export default connect(mapStateToProps, null)(GelmanRubin)
