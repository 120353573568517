/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

import { getCmlCrGraph } from '../lib/getResultCases.js'

const RCmlCorrosionRates = ({ results, circuit, cmlCorrosionRates }) => {
  const graphCmlData = getCmlCrGraph(results.cmlCrPages, results.cmlCrLabels, results.cmlCrColors)

  return (
    <div className="cf w-100">
      <div>
        <b><i>{circuit}</i> CML Corrosion Rates</b>
        <DataTableDisplay id="cmlCorrosionRatesTable" table={cmlCorrosionRates} containerHeight="200px" />
        <br />

        <div className="cf w-100">
          {results.cmlCrPages
            ? (
              <div className="cf w-100">
                <DataGraphDisplay graphs={graphCmlData} />
              </div>
            )
            : <p>No Graphs to Display</p>}
        </div>
      </div>

      <div>
        <br/>
        <b>B-Values represent the credible interval levels for the corrosion rates. </b>
        <i>B50 = Median;  B75 = 3rd Quartile; B90, B95, B99 are the 90th, 95th, and 99th Precentiles Respectively</i>
      </div>
    </div>
  )
}

RCmlCorrosionRates.propTypes = {
  results: PropTypes.object,
  circuit: PropTypes.string,
  cmlCorrosionRates: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  circuit: state.workflow.fields.circuit,
  cmlCorrosionRates: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.cmlCorrosionRates : undefined,
})

export default connect(mapStateToProps, null)(RCmlCorrosionRates)
