/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint no-shadow: ["error", { "allow": ["row", "col"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */

export default [
  {
    name: 'cmlDataAndLabelsTable',
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
    ],
    keywords: [
      {
        keyword: 'uniqueCmlIds',
        colHeaders: {
          USCustomary: ['CML ID*'],
          Metric: ['CML ID*'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'circuitFlowOrders',
        colHeaders: {
          USCustomary: ['Flow Order Index'],
          Metric: ['Flow Order Index'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfGroups',
        colHeaders: {
          USCustomary: ['ACF Group Name'],
          Metric: ['ACF Group Name'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'nps',
        colHeaders: {
          USCustomary: ['Pipe NPS'],
          Metric: ['Pipe NPS'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'sch',
        colHeaders: {
          USCustomary: ['Pipe Sch'],
          Metric: ['Pipe Sch'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'nominals',
        colHeaders: {
          USCustomary: ['Nominal Thickness (in)'],
          Metric: ['Nominal Thickness (mm)'],
        },
        rowHeaders: false,
        units: {
          USCustomary: 'in',
          Metric: 'mm',
        },
      },
      {
        keyword: 'baseDates',
        colHeaders: {
          USCustomary: ['Base Date'],
          Metric: ['Base Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'componentTypes',
        colHeaders: {
          USCustomary: ['Component Type'],
          Metric: ['Component Type'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'tmins',
        colHeaders: {
          USCustomary: ['Tmin (in)*'],
          Metric: ['Tmin (mm)*'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'CML-ID'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        } else if(col === 6) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM-DD-YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      className: 'htCenter',
      columns: [
        { type: 'text' }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0' } }, // Flow order integer
        { type: 'text' }, // CML_ID
        {
          type: 'autocomplete',
          source: ['0.125', '0.25', '0.375', '0.5', '0.75', '1', '1.25', '1.5', '2', '2.5', '3', '3.5', '4', '5', '6', '8', '10', '12', '14', '16', '18', '20', '24', '26', '28', '30', '32', '34', '36', '42', '48'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['5S', '10', '30', '40', '60', '80', '100', '120', '140', '160', 'XS', 'XXS', 'XXXS', 'STD', 'LWN', '150LWN', '150LWN1', '150LWN2', '300LWN', '6000CPLG'],
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric', numericFormat: { pattern: '0.000' } }, // Nominal Thickness
        { type: 'text' }, // Base Date
        {
          type: 'autocomplete',
          source: ['CAP', 'CPLG', 'ELL', 'HVYWALL', 'NIP', 'PIPE', 'RED', 'TEE', 'TNIP', 'TPIPE'],
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric', numericFormat: { pattern: '0.000' } },
      ],
      colWidths: [80, 80, 80, 80, 80, 100, 100, 110, 80],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
      stretchH: 'all',
    },
  },
  {
    name: 'cmlDataAndLabelsTable2',
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
    ],
    keywords: [
      {
        keyword: 'uniqueCmlIds2',
        colHeaders: {
          USCustomary: ['CML ID'],
          Metric: ['CML ID'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'circuitFlowOrders2',
        colHeaders: {
          USCustomary: ['Flow Order Index'],
          Metric: ['Flow Order Index'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfGroups2',
        colHeaders: {
          USCustomary: ['ACF Group Name'],
          Metric: ['ACF Group Name'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'nominals2',
        colHeaders: {
          USCustomary: ['Nominal Thickness (in)'],
          Metric: ['Nominal Thickness (mm)'],
        },
        rowHeaders: false,
        units: {
          USCustomary: 'in',
          Metric: 'mm',
        },
      },
      {
        keyword: 'undertolerances2',
        colHeaders: {
          USCustomary: ['Undertolerance (%)'],
          Metric: ['Undertolerance (%)'],
        },
        rowHeaders: false,
        units: {
          USCustomary: '%',
          Metric: '%',
        },
      },
      {
        keyword: 'baseDates2',
        colHeaders: {
          USCustomary: ['Base Date'],
          Metric: ['Base Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'numReplacements2',
        colHeaders: {
          USCustomary: ['Number of Replacements'],
          Metric: ['Number of Replacements'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'replacementDates',
        colHeaders: {
          USCustomary: ['Replacement Dates'],
          Metric: ['Replacement Dates'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'tmins2',
        colHeaders: {
          USCustomary: ['Tmin (in)*'],
          Metric: ['Tmin (mm)*'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'CML-ID'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        } else if(col === 5) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM-DD-YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        } else if(col === 7) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM-DD-YYYY, ...'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      className: 'htCenter',
      columns: [
        { type: 'text' }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0' } }, // Flow order integer
        { type: 'text' }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.000' } }, // Nominal Thickness
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // Undertolerance
        { type: 'text' }, // Base Date
        { type: 'numeric', numericFormat: { pattern: '0' } }, // Number of Replacements
        { type: 'text' }, // Replacement Dates
        { type: 'numeric', numericFormat: { pattern: '0.000' } }, // Tmin
      ],
      colWidths: [80, 80, 80, 100, 100, 100, 100, 120, 80],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
      stretchH: 'all',
    },
  },
  {
    name: 'cmlDataAndLabelsTable3',
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
    ],
    keywords: [
      {
        keyword: 'uniqueCmlIds3',
        colHeaders: {
          USCustomary: ['CML ID*'],
          Metric: ['CML ID*'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'circuitFlowOrders3',
        colHeaders: {
          USCustomary: ['Flow Order Index'],
          Metric: ['Flow Order Index'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfGroups3',
        colHeaders: {
          USCustomary: ['ACF Group Name'],
          Metric: ['ACF Group Name'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'nps3',
        colHeaders: {
          USCustomary: ['Pipe NPS'],
          Metric: ['Pipe NPS'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'sch3',
        colHeaders: {
          USCustomary: ['Pipe Sch'],
          Metric: ['Pipe Sch'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'nominals3',
        colHeaders: {
          USCustomary: ['Nominal Thickness (in)'],
          Metric: ['Nominal Thickness (mm)'],
        },
        rowHeaders: false,
        units: {
          USCustomary: 'in',
          Metric: 'mm',
        },
      },
      {
        keyword: 'baseDates3',
        colHeaders: {
          USCustomary: ['Base Date'],
          Metric: ['Base Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'componentTypes3',
        colHeaders: {
          USCustomary: ['Component Type'],
          Metric: ['Component Type'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'tmins3',
        colHeaders: {
          USCustomary: ['Tmin (in)*'],
          Metric: ['Tmin (mm)*'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'monitoredComponent',
        colHeaders: {
          USCustomary: ['Monitored Component'],
          Metric: ['Monitored Component'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'CML-ID'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        } else if(col === 6) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        } else if(col === 9) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = '1=Yes/0=No'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      className: 'htCenter',
      columns: [
        { type: 'text' }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0' } }, // Flow order integer
        {
          type: 'autocomplete',
          source: null,
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['0.125', '0.25', '0.375', '0.5', '0.75', '1', '1.25', '1.5', '2', '2.5', '3', '3.5', '4', '5', '6', '8', '10', '12', '14', '16', '18', '20', '24', '26', '28', '30', '32', '34', '36', '42', '48'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['5S', '10', '30', '40', '60', '80', '100', '120', '140', '160', 'XS', 'XXS', 'XXXS', 'STD', 'LWN', '150LWN', '150LWN1', '150LWN2', '300LWN', '6000CPLG'],
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric', numericFormat: { pattern: '0.000' } }, // Nominal Thickness
        { type: 'text' }, // Base Date
        {
          type: 'autocomplete',
          source: ['CAP', 'CPLG', 'ELL', 'HVYWALL', 'NIP', 'PIPE', 'RED', 'TEE', 'TNIP', 'TPIPE'],
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric', numericFormat: { pattern: '0.000' } },
        {
          type: 'autocomplete',
          source: ['1', '0'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [80, 80, 80, 80, 80, 100, 100, 110, 80, 100],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
      stretchH: 'all',
    },
  },
  {
    name: 'acfInputTable',
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    keywords: [
      {
        keyword: 'uniqueAcfs',
        colHeaders: {
          USCustomary: ['ACF Group Name'],
          Metric: ['ACF Group Name'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfMeanRates',
        colHeaders: {
          USCustomary: ['Mean Corrosion Rate (mil/yr)'],
          Metric: ['Mean Corrosion Rate (mm/yr)'],
        },
        units: {
          USCustomary: 'mil/yr',
          Metric: 'mm/yr',
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfMaxRates',
        colHeaders: {
          USCustomary: ['Max Corrosion Rate (mil/yr)'],
          Metric: ['Max Corrosion Rate (mm/yr)'],
        },
        units: {
          USCustomary: 'mil/yr',
          Metric: 'mm/yr',
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfSdRates',
        colHeaders: {
          USCustomary: ['Corrosion Rate SD (mil/yr)'],
          Metric: ['Corrosion Rate SD (mm/yr)'],
        },
        units: {
          USCustomary: 'mil/yr',
          Metric: 'mm/yr',
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0' } }, // Flow order integer
        { type: 'numeric', numericFormat: { pattern: '0' } }, // Flow order integer
        { type: 'numeric', numericFormat: { pattern: '0' } }, // Flow order integer
      ],
      colWidths: [100, 120, 120, 120],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
      stretchH: 'all',
    },
  },
  {
    name: 'cmlMeasurementThicknessTable',
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
    ],
    keywords: [
      {
        keyword: 'tmlIDs',
        colHeaders: {
          USCustomary: ['CML ID'],
          Metric: ['CML ID'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'thicknessReadings',
        colHeaders: {
          USCustomary: ['Thickness Reading (in)'],
          Metric: ['Thickness Reading (mm)'],
        },
        rowHeaders: false,
        units: {
          USCustomary: 'in',
          Metric: 'mm',
        },
      },
      {
        keyword: 'measurementDates',
        colHeaders: {
          USCustomary: ['Measurement Date'],
          Metric: ['Measurement Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'inspectionTypes',
        colHeaders: {
          USCustomary: ['Inspection Type'],
          Metric: ['Inspection Type'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'tmlhistno',
        colHeaders: {
          USCustomary: ['TML History Index'],
          Metric: ['TML History Index'],
        },
        rowHeaders: false,
        units: {
          USCustomary: 'DimensionlessEnglish',
          Metric: 'DimensionlessMetric',
        },
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 2) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM-DD-YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      className: 'htCenter',
      columns: [
        {
          type: 'autocomplete',
          source: null,
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric', numericFormat: { pattern: '0.000' } },
        { type: 'text' },
        {
          type: 'autocomplete',
          source: null,
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric', numericFormat: { pattern: '0' } },
      ],
      colWidths: [100, 160, 140, 140, 140],
      minSpareRows: 2,
      stretchH: 'all',
    },
  },
  {
    name: 'cmlMeasurementThicknessTable2',
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    keywords: [
      {
        keyword: 'tmlIDs2',
        colHeaders: {
          USCustomary: ['CML ID'],
          Metric: ['CML ID'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'thicknessReadings2',
        colHeaders: {
          USCustomary: ['Thickness Reading (in)'],
          Metric: ['Thickness Reading (mm)'],
        },
        rowHeaders: false,
        units: {
          USCustomary: 'in',
          Metric: 'mm',
        },
      },
      {
        keyword: 'measurementDates2',
        colHeaders: {
          USCustomary: ['Measurement Date'],
          Metric: ['Measurement Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'inspectionTypes2',
        colHeaders: {
          USCustomary: ['Inspection Type'],
          Metric: ['Inspection Type'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'tmlhistno2',
        colHeaders: {
          USCustomary: ['TML History Index'],
          Metric: ['TML History Index'],
        },
        rowHeaders: false,
        units: {
          USCustomary: 'DimensionlessEnglish',
          Metric: 'DimensionlessMetric',
        },
      },
      {
        keyword: 'numberOfReadingsEvt2',
        colHeaders: {
          USCustomary: ['Number of Readings'],
          Metric: ['Number of Readings'],
        },
        rowHeaders: false,
        units: {
          USCustomary: 'DimensionlessEnglish',
          Metric: 'DimensionlessMetric',
        },
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 2) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM-DD-YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        } else if(col === 4 || col === 5) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = '0'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      className: 'htCenter',
      columns: [
        {
          type: 'autocomplete',
          source: null,
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric', numericFormat: { pattern: '0.000' } },
        { type: 'text' },
        {
          type: 'autocomplete',
          source: null,
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric', numericFormat: { pattern: '0' } },
        { type: 'numeric', numericFormat: { pattern: '0' } },
      ],
      colWidths: [100, 160, 140, 140, 140, 140],
      minSpareRows: 2,
      stretchH: 'all',
    },
  },
  {
    name: 'censoredReadingsTable',
    keywords: [
      {
        keyword: 'censoredNPS',
        colHeaders: {
          USCustomary: ['NPS'],
          Metric: ['NPS'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'censoredUtReadings',
        colHeaders: {
          USCustomary: ['UT Number of<br />Readings'],
          Metric: ['UT Number of<br />Readings'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'censoredRtReadings',
        colHeaders: {
          USCustomary: ['RT Number of<br />Readings'],
          Metric: ['RT Number of<br />Readings'],
        },
        rowHeaders: false,
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        { type: 'text' }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0' } },
        { type: 'numeric', numericFormat: { pattern: '0' } },
      ],
      colWidths: [120, 120, 120],
      minSpareRows: 0,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
      cells(row, col) {
        const cellProperties = {}
        if(col === 0) {
          cellProperties.readOnly = true
        }
        return cellProperties
      },
      stretchH: 'all',
    },
  },
  {
    name: 'pipeScheduleTable',
    keywords: [
      {
        keyword: 'npsAll',
        colHeaders: {
          USCustomary: ['NPS'],
          Metric: ['NPS'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'schAll',
        colHeaders: {
          USCustomary: ['UT Number of<br />Readings'],
          Metric: ['UT Number of<br />Readings'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'wallAll',
        colHeaders: {
          USCustomary: ['RT Number of<br />Readings'],
          Metric: ['RT Number of<br />Readings'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'pipeMaxAll',
        colHeaders: {
          USCustomary: ['RT Number of<br />Readings'],
          Metric: ['RT Number of<br />Readings'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'nomSdAll',
        colHeaders: {
          USCustomary: ['RT Number of<br />Readings'],
          Metric: ['RT Number of<br />Readings'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'preferredSch',
        colHeaders: {
          USCustomary: ['Preferred Sch'],
          Metric: ['Preferred Sch'],
        },
        rowHeaders: false,
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'numeric', numericFormat: { pattern: '0.000' } },
        { type: 'numeric', numericFormat: { pattern: '0.000' } },
        { type: 'numeric', numericFormat: { pattern: '0.0000' } },
        { type: 'numeric', numericFormat: { pattern: '0' } },
      ],
      colWidths: [120, 120, 120, 120, 120, 100],
      minSpareRows: 0,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
      cells(row, col) {
        const cellProperties = {}
        if(col === 0 || col === 1) {
          cellProperties.readOnly = true
        }
        return cellProperties
      },
      stretchH: 'all',
    },
  },
  {
    name: 'errorMultipliersTable',
    keywords: [
      {
        keyword: 'componentType',
        colHeaders: {
          USCustomary: ['Component Type'],
          Metric: ['Component Type'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'dataErrorMultiplier',
        colHeaders: {
          USCustomary: ['Data Error<br />Multiplier'],
          Metric: ['Data Error<br />Multiplier'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'measurementErrorMultiplier',
        colHeaders: {
          USCustomary: ['Measurement Error<br />Multiplier'],
          Metric: ['Measurement Error<br />Multiplier'],
        },
        rowHeaders: false,
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'text',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        { type: 'text' }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.00' } },
        { type: 'numeric', numericFormat: { pattern: '0.00' } },
      ],
      colWidths: [120, 120, 120],
      minSpareRows: 0,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
      cells(row, col) {
        const cellProperties = {}
        if(col === 0) {
          cellProperties.readOnly = true
        }
        return cellProperties
      },
      stretchH: 'all',
    },
  },
  // {
  //   name: 'cmlReplacementDatesTable',
  //   columnDisplayOptions: [
  //     {
  //       dataType: 'text',
  //     },
  //     {
  //       dataType: 'text',
  //     },
  //   ],
  //   keywords: [
  //     {
  //       keyword: 'replacementCmls',
  //       colHeaders: {
  //         USCustomary: ['CML ID*'],
  //         Metric: ['CML ID*'],
  //       },
  //       rowHeaders: false,
  //     },
  //     {
  //       keyword: 'replacementDates',
  //       colHeaders: {
  //         USCustomary: ['Replacement Date<br />(MM-DD-YYYY)*'],
  //         Metric: ['Replacement Date<br />(MM-DD-YYYY)*'],
  //       },
  //       rowHeaders: false,
  //     },
  //   ],
  //   type: 'input',
  //   options: {
  //     cells(row, col) {
  //       if (col === 1) {
  //         this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
  //           if (value === null || value === '') {
  //             td.className = 'htCenter';
  //             td.style.color = '#999';
  //             td.textContent = 'MM-DD-YYYY';
  //           } else {
  //             td.className = 'htCenter';
  //             td.textContent = value;
  //           }
  //         };
  //       }
  //       // const cellProperties = {};
  //       // if (col === 0 || col === 1) {
  //       //   cellProperties.readOnly = true;
  //       // }
  //       // return cellProperties;
  //     },
  //     className: 'htCenter',
  //     columns: [
  //       {
  //         type: 'autocomplete',
  //         source: null,
  //         strict: true,
  //         allowInvalid: false,
  //       },
  //       { type: 'text' },
  //     ],
  //     colWidths: [120, 120],
  //     minSpareRows: 0,
  //     stretchH: 'all',
  //   },
  // },
  {
    name: 'summaryData',
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    keywords: [
      {
        keyword: 'summaryData',
        colHeaders: { USCustomary: ['Mean', 'SD', 'HDI 3%', 'HDI 97%', 'MCSE Mean', 'MCSE SD', 'ESS Bulk', 'ESS Tail', 'R Hat'], Metric: ['Mean', 'SD', 'HDI 3%', 'HDI 97%', 'MCSE Mean', 'MCSE SD', 'ESS Bulk', 'ESS Tail', 'R Hat'] },
        rowHeaders: false,
        units: {
          USCustomary: ['DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish'],
          Metric: ['DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric'],
        },
      },
    ],
    type: 'output',
    options: {
      // rowHeaders: [], // set as list dynamically in step4
      className: 'htCenter',
      stretchH: 'all',
      rowHeaderWidth: 140,
      colWidths: [120, 120, 120, 120, 120, 120, 120, 120, 120, 120],
      columns: [
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // CML_ID
      ],
    },
  },

  {
    name: 'summaryDataR',
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
      {
        dataType: 'float',
      },
    ],
    keywords: [
      {
        keyword: 'summaryDataR',
        colHeaders: { USCustomary: ['Lower99', 'Median', 'Upper99', 'Mean', 'SD', 'MCerr', 'MC%ofSD', 'SSeff', 'AC.100', 'psrf', 'gr_psrf'], Metric: ['Lower99', 'Median', 'Upper99', 'Mean', 'SD', 'MCerr', 'MC%ofSD', 'SSeff', 'AC.100', 'psrf', 'gr_psrf'] },
        rowHeaders: false,
        units: {
          USCustomary: ['DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish', 'DimensionlessEnglish'],
          Metric: ['DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric', 'DimensionlessMetric'],
        },
      },
    ],
    type: 'output',
    options: {
      // rowHeaders: [], // set as list dynamically in step4
      className: 'htCenter',
      stretchH: 'all',
      rowHeaderWidth: 140,
      colWidths: [120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120],
      columns: [
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // Lower99
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // Median
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // Upper99
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // Mean
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // SD
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // MCerr
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // MC%ofSC
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // SSeff
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // AC.100
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // psrf
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // gr_psrf
      ],
    },
  },
  {
    name: 'cmlErrPages',
    keywords: [
      {
        keyword: 'cmlErrPages',
      },
    ],
    type: 'output',
  },
  {
    name: 'cmlErrLabels',
    keywords: [
      {
        keyword: 'cmlErrLabels',
      },
    ],
    type: 'output',
  },
  {
    name: 'acfErrPages',
    keywords: [
      {
        keyword: 'acfErrPages',
      },
    ],
    type: 'output',
  },
  {
    name: 'acfErrLabels',
    keywords: [
      {
        keyword: 'acfErrLabels',
      },
    ],
    type: 'output',
  },
  {
    name: 'circuitCorrosionRates',
    columnDisplayOptions: [
      {
        dataType: 'text', // units
      },
      {
        dataType: 'float', // circuit B50
      },
      {
        dataType: 'float', // circuit B75
      },
      {
        dataType: 'float', // circuit B90
      },
      {
        dataType: 'float', // circuit B95
      },
      {
        dataType: 'float', // circuit B99
      },
    ],
    keywords: [
      {
        keyword: 'circUnits',
        colHeaders: {
          USCustomary: ['CR Units'],
          Metric: ['CR Units'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'circCrB50',
        colHeaders: {
          USCustomary: ['B50'],
          Metric: ['B50'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'circCrB75',
        colHeaders: {
          USCustomary: ['B75'],
          Metric: ['B75'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'circCrB90',
        colHeaders: {
          USCustomary: ['B90'],
          Metric: ['B90'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'circCrB95',
        colHeaders: {
          USCustomary: ['B95'],
          Metric: ['B95'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'circCrB99',
        colHeaders: {
          USCustomary: ['B99'],
          Metric: ['B99'],
        },
        rowHeaders: false,
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' }, // units
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B50
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B75
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B90
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B95
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B99
      ],
      colWidths: [25, 25, 25, 25, 25, 25],
      stretchH: 'all',
    },
  },
  {
    name: 'cmlCorrosionRates',
    columnDisplayOptions: [
      {
        dataType: 'text', // circuit_isometric
      },
      {
        dataType: 'text', // cml_id
      },
      {
        dataType: 'float', // acf_group
      },
      {
        dataType: 'text', // units
      },
      {
        dataType: 'float', // cml B50
      },
      {
        dataType: 'float', // cml B75
      },
      {
        dataType: 'float', // cml B90
      },
      {
        dataType: 'float', // cml B95
      },
      {
        dataType: 'float', // cml B99
      },
    ],
    keywords: [
      {
        keyword: 'circIso',
        colHeaders: {
          USCustomary: ['Iso/Line'],
          Metric: ['Iso/Line'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'cmlText',
        colHeaders: {
          USCustomary: ['CML ID'],
          Metric: ['CML ID'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'cmlAcfGroup',
        colHeaders: {
          USCustomary: ['ACF Gp #'],
          Metric: ['ACF Gp #'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'cmlCrUnits',
        colHeaders: {
          USCustomary: ['CR Units'],
          Metric: ['CR Units'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'cmlCrB50',
        colHeaders: {
          USCustomary: ['B50'],
          Metric: ['B50'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'cmlCrB75',
        colHeaders: {
          USCustomary: ['B75'],
          Metric: ['B75'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'cmlCrB90',
        colHeaders: {
          USCustomary: ['B90'],
          Metric: ['B90'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'cmlCrB95',
        colHeaders: {
          USCustomary: ['B95'],
          Metric: ['B95'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'cmlCrB99',
        colHeaders: {
          USCustomary: ['B99'],
          Metric: ['B99'],
        },
        rowHeaders: false,
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' }, // circuit_isometric
        { type: 'text' }, // cml_text
        { type: 'numeric', numericFormat: { pattern: '0' } }, // acf_group
        { type: 'text' }, // units
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // B50
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // B75
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // B90
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // B95
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // B99
      ],
      colWidths: [100, 50, 30, 25, 25, 25, 25, 25, 25],
      stretchH: 'all',
    },
  },
  {
    name: 'acfCorrosionRates',
    columnDisplayOptions: [
      {
        dataType: 'float', // ACF Group #
      },
      {
        dataType: 'text', // ACF Group Name
      },
      {
        dataType: 'text', // units
      },
      {
        dataType: 'float', // acf B50
      },
      {
        dataType: 'float', // acf B75
      },
      {
        dataType: 'float', // acf B90
      },
      {
        dataType: 'float', // acf B95
      },
      {
        dataType: 'float', // acf B99
      },
    ],
    keywords: [
      {
        keyword: 'acfCrGroup',
        colHeaders: {
          USCustomary: ['ACF Group'],
          Metric: ['ACF Group'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfCrName',
        colHeaders: {
          USCustomary: ['ACF Group Name'],
          Metric: ['ACF Group Name'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfCrUnits',
        colHeaders: {
          USCustomary: ['CR Units'],
          Metric: ['CR Units'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfCrB50',
        colHeaders: {
          USCustomary: ['B50'],
          Metric: ['B50'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfCrB75',
        colHeaders: {
          USCustomary: ['B75'],
          Metric: ['B75'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfCrB90',
        colHeaders: {
          USCustomary: ['B90'],
          Metric: ['B90'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfCrB95',
        colHeaders: {
          USCustomary: ['B95'],
          Metric: ['B95'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'acfCrB99',
        colHeaders: {
          USCustomary: ['B99'],
          Metric: ['B99'],
        },
        rowHeaders: false,
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'numeric', numericFormat: { pattern: '0' } }, // ACF GROUP
        { type: 'text' }, // ACF NAME
        { type: 'text' }, // units
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B50
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B75
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B90
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B95
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // ACF B99
      ],
      colWidths: [50, 100, 25, 25, 25, 25, 25, 25],
      stretchH: 'all',
    },
  },
  {
    name: 'rmData',
    columnDisplayOptions: [
      {
        dataType: 'float', // RisK Matrix Year
      },
      {
        dataType: 'text', // Circuit
      },
      {
        dataType: 'text', // Cirucit Iso, CML
      },
      {
        dataType: 'float', // rm Consequence
      },
      {
        dataType: 'float', // POF
      },
      {
        dataType: 'float', // Risk
      },
      {
        dataType: 'float', // Risk Target
      },
    ],
    keywords: [
      {
        keyword: 'rmYear',
        colHeaders: {
          USCustomary: ['Year'],
          Metric: ['Year'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'rmCircuit',
        colHeaders: {
          USCustomary: ['Circuit'],
          Metric: ['Circuit'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'rmCml',
        colHeaders: {
          USCustomary: ['CML Text'],
          Metric: ['CML Text'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'rmConsequence',
        colHeaders: {
          USCustomary: ['Cons.'],
          Metric: ['Cons.'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'rmPof',
        colHeaders: {
          USCustomary: ['POF'],
          Metric: ['POF'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'rmRisk',
        colHeaders: {
          USCustomary: ['Risk'],
          Metric: ['Risk'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'rmRiskTarget',
        colHeaders: {
          USCustomary: ['Risk Target'],
          Metric: ['Risk Target'],
        },
        rowHeaders: false,
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'numeric', numericFormat: { pattern: '0' } }, // Year
        { type: 'text' }, // Circuit
        { type: 'text' }, // Iso-CML
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // Consequence
        { type: 'numeric', numericFormat: { pattern: '0.0000000' } }, // POF
        { type: 'numeric', numericFormat: { pattern: '0.0000' } }, // Risk
        { type: 'numeric', numericFormat: { pattern: '0.00' } }, // Risk Target
      ],
      colWidths: [25, 30, 80, 25, 30, 25, 35],
      stretchH: 'all',
    },
  },
]
