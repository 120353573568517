// THIS FILE IS GENERATED BY: plugins/calculator-meta/generate-provider.ts
import React from 'react'
import Debug from 'debug'

import { useCalculatorDescriptors } from '@e2grnd/ui-core'
import { useAuthContext } from '@e2grnd/ui-core/dist/contexts/AuthProvider'
import CalculatorProvider from '@e2grnd/ui-core/dist/contexts/CalculatorProvider'
import WorkflowRouter from '@e2grnd/ui-core/dist/routes/WorkflowRouter'

import config from '../../calculators/corrSolutions'
import meta from '../calculators/corrSolutions'

const log = Debug('e2g:CalculatorPage:corrSolutions:debug')

export default function CorrSolutionsProvider() {
  const { accessControl } = useAuthContext()
  const { calculatorDescriptors } = useCalculatorDescriptors()
  const descriptor = calculatorDescriptors.corrSolutions
  if(!descriptor) throw new Error('Calculator descriptor not found for calcId corrSolutions')
  if(typeof window === 'undefined') return null
  log('CalculatorPage "corrSolutions" loaded')
  return (
    <CalculatorProvider accessControl={accessControl} config={config} descriptor={descriptor} meta={meta}>
      <WorkflowRouter basepath="/app/calculator/corrSolutions/" />
    </CalculatorProvider>
  )
}
