export const stringToBlob = (b64Data: string, fileName = 'circuitResults', download = true) => {
  const blob = b64Data // Chrome, Firefox, Safari
  try {
    const file = new File([blob], fileName)
    const u = window.URL.createObjectURL(file)
    const l = document.createElement('a')
    l.href = u
    if(download) {
      l.setAttribute('download', fileName)
    } else {
      l.setAttribute('target', '_blank')
    }
    document.body.appendChild(l)
    l.click()
    document.body.removeChild(l)
    setTimeout(() => {
      window.URL.revokeObjectURL(u)
    }, 100)
  } catch{
    throw new Error('file is undefined')
  }
}
