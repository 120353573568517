import acceptanceCriteriaNN from './acceptanceCriteriaNN.png'
import acceptanceCriteriaNY from './acceptanceCriteriaNY.png'
import acceptanceCriteriaYN from './acceptanceCriteriaYN.png'
import acceptanceCriteriaYY from './acceptanceCriteriaYY.png'
import b31TminTable from './b31TminTable.png'
import insulationAndRefactory from './insulationAndRefactory.png'
import loadsNN from './loadsNN.png'
import loadsNY from './loadsNY.png'
import loadsYN from './loadsYN.png'
import loadsYY from './loadsYY.png'
import noInsulationOrRefactory from './noInsulationOrRefactory.png'
import onlyInsulation from './onlyInsulation.png'
import onlyRefactory from './onlyRefactory.png'
import pipeSpanTable from './pipeSpanTable.png'
import profileInsulationAndRefactory from './profileInsulationAndRefactory.png'
import profileNoInsulationOrRefactory from './profileNoInsulationOrRefactory.png'
import profileOnlyInsulation from './profileOnlyInsulation.png'
import profileOnlyRefactory from './profileOnlyRefactory.png'
import siteFactorsTable from './siteFactorsTable.png'

export {
  acceptanceCriteriaNN,
  acceptanceCriteriaNY,
  acceptanceCriteriaYN,
  acceptanceCriteriaYY,
  b31TminTable,
  insulationAndRefactory,
  loadsNN,
  loadsNY,
  loadsYN,
  loadsYY,
  noInsulationOrRefactory,
  onlyInsulation,
  onlyRefactory,
  pipeSpanTable,
  profileInsulationAndRefactory,
  profileNoInsulationOrRefactory,
  profileOnlyInsulation,
  profileOnlyRefactory,
  siteFactorsTable
}
