import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Container, Typography } from '@mui/material'

import E2GFeedback from '@e2grnd/ui-core/dist/components/support/E2GFeedback'

import { PageFrontmatterOptions } from 'types/page-frontmatter'

export default function FeedbackPage() {
  return (
    <Container maxWidth="md">
      <Box p={4}>
        <Helmet title="Feedback" />
        <Typography variant="h1" sx={{ mb: 4 }}>Send Us Feedback</Typography>
        <E2GFeedback />
      </Box>
    </Container>
  )
}
export const frontmatter: PageFrontmatterOptions = {
  layout: {
    noDrawer: true,
  },
}
