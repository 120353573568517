import { problemTypeLup } from './sequence/Problems'
import dataTables from './dataTables'
import examples from './examples.js'
import optionsPages from './optionsPages'
import params from './params'
import propertyPages from './propertyPages'
import report from './report'
import sequence from './sequence'
import steps from './steps'

export default {
  steps,
  params,
  dataTables,
  propertyPages,
  examples,
  optionsPages,
  sequence,
  problemTypeLup,
  report,
}
