// THIS FILE IS GENERATED BY: plugins/calculator-meta/generate-provider.ts
import React from 'react'
import Debug from 'debug'

import { useCalculatorDescriptors } from '@e2grnd/ui-core'
import { useAuthContext } from '@e2grnd/ui-core/dist/contexts/AuthProvider'
import CalculatorProvider from '@e2grnd/ui-core/dist/contexts/CalculatorProvider'
import FreeformWorkflowRouter from '@e2grnd/ui-core/dist/routes/FreeformWorkflowRouter'

import config from '../../calculators/degradationAnalysis'
import meta from '../calculators/degradationAnalysis'

const log = Debug('e2g:CalculatorPage:degradationAnalysis:debug')

export default function DegradationAnalysisProvider() {
  const { accessControl } = useAuthContext()
  const { calculatorDescriptors } = useCalculatorDescriptors()
  const descriptor = calculatorDescriptors.degradationAnalysis
  if(!descriptor) throw new Error('Calculator descriptor not found for calcId degradationAnalysis')
  if(typeof window === 'undefined') return null
  log('CalculatorPage "degradationAnalysis" loaded')
  return (
    <CalculatorProvider accessControl={accessControl} config={config} descriptor={descriptor} meta={meta}>
      <FreeformWorkflowRouter />
    </CalculatorProvider>
  )
}
