/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ToggleField from '@e2grnd/ui-core/dist/containers/ToggleField'

const ReportConfig = ({
  thicknessPlotsReportFlag, metalLossPlotsReportFlag, pofPlotsReportFlag, residualPlotsReportFlag, style,
}) => (
  <div>
    <div className="fl w-100">
      <div className="fl w-100 w-50-ns pl3">
        <ToggleField style={style} name="thicknessPlotsReportFlag" value={thicknessPlotsReportFlag} label="Include Thickness Projection Plots?" />
        <ToggleField style={style} name="metalLossPlotsReportFlag" value={metalLossPlotsReportFlag} label="Include Metal Loss Projection Plots?" />
        <ToggleField style={style} name="pofPlotsReportFlag" value={pofPlotsReportFlag} label="Include POF Projection Plots??" />
        {/* <ToggleField style={style} name="densityPlotsReportFlag" value={densityPlotsReportFlag} label="Include Density Plots?" /> */}
        <ToggleField style={style} name="residualPlotsReportFlag" value={residualPlotsReportFlag} label="Include Residual Plots?" />
      </div>
    </div>
  </div>
)

ReportConfig.propTypes = {
  thicknessPlotsReportFlag: PropTypes.bool,
  metalLossPlotsReportFlag: PropTypes.bool,
  pofPlotsReportFlag: PropTypes.bool,
  // densityPlotsReportFlag: PropTypes.bool,
  residualPlotsReportFlag: PropTypes.bool,
  style: PropTypes.object,
}
const mapStateToProps = state => ({
  thicknessPlotsReportFlag: state.workflow.fields.thicknessPlotsReportFlag,
  metalLossPlotsReportFlag: state.workflow.fields.metalLossPlotsReportFlag,
  pofPlotsReportFlag: state.workflow.fields.pofPlotsReportFlag,
  // densityPlotsReportFlag: state.workflow.fields.densityPlotsReportFlag,
  residualPlotsReportFlag: state.workflow.fields.residualPlotsReportFlag,
  style: state.ui.style,
})
export default connect(mapStateToProps, null)(ReportConfig)
