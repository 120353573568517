/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'

import { getAvgDensityGraphs } from '../lib/getResultCases.js'

const AvgDensity = ({ results }) => {
  let keys
  const items = []
  let graphData
  let graphKeys
  let firstKey
  if(typeof results.finalOutData !== 'undefined') {
    const jsonObject = JSON.parse(results.finalOutData[0])
    keys = Object.keys(jsonObject)
    keys.forEach((key, index) => {
      items.push(<E2GSelectFieldOption label={key} value={index.toString()} key={index} />)
    })
    graphData = getAvgDensityGraphs(results.finalOutData, results.avgDensityKeys ? results.avgDensityKeys[0] : '0')
    graphKeys = Object.keys(graphData)
    firstKey = graphKeys[0]
  }
  return (
    <div className="cf w-100">
      {results.finalOutData
        ? (
          <div className="fl w-100">
            <E2GSelectFieldIndexer name="avgDensityKeys" value={results.avgDensityKeys ? results.avgDensityKeys[0] : '0'} label="Select Parameter to Plot">
              {items}
            </E2GSelectFieldIndexer>
            <div>
              <DataGraph plotClass="data-graph-85" data={graphData[firstKey].traces} layout={graphData[firstKey].layout} />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

AvgDensity.propTypes = {
  results: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
})

export default connect(mapStateToProps, null)(AvgDensity)
