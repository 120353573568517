/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

const AnalysisSummary = ({ circuit, problemType, summaryDataR }) => {
  const modelCriteriaString = 'There are no WAIC and LOO deviance estimates available for this analysis.\n A gr_psrf = -1 indicates parameter converged.'

  return (
    <div className="fl w-100">
      <p><b><i>{circuit}</i> Analysis Parameter Summary</b></p>
      <p>{modelCriteriaString}</p>
      <DataTableDisplay id="summaryDataR" table={summaryDataR} colHeaders={{ USCustomary: ['Lower99', 'Median', 'Upper99', 'Mean', 'SD', 'MCerr', 'MC%ofSD', 'SSeff', 'AC.100', 'psrf', 'gr_psrf'], Metric: ['Lower99', 'Median', 'Upper99', 'Mean', 'SD', 'MCerr', 'MC%ofSD', 'SSeff', 'AC.100', 'psrf', 'gr_psrf'] }} containerHeight="300px" />
    </div>
  )
}

AnalysisSummary.propTypes = {
  circuit: PropTypes.string,
  problemType: PropTypes.string,
  summaryDataR: PropTypes.object,
}

const mapStateToProps = state => ({
  circuit: state.workflow.fields.circuit,
  problemType: state.workflow.fields.problemType,
  summaryDataR: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.summaryDataR : undefined,
})

export default connect(mapStateToProps, null)(AnalysisSummary)
