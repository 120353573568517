import acceptanceCriteriaNN from './acceptanceCriteriaNN.png'
import acceptanceCriteriaNY from './acceptanceCriteriaNY.png'
import acceptanceCriteriaYN from './acceptanceCriteriaYN.png'
import acceptanceCriteriaYY from './acceptanceCriteriaYY.png'
import insulationAndRefactory from './insulationAndRefactory.png'
import noInsulationOrRefactory from './noInsulationOrRefactory.png'
import onlyInsulation from './onlyInsulation.png'
import onlyRefactory from './onlyRefactory.png'
import profileInsulationAndRefactory from './profileInsulationAndRefactory.png'
import profileNoInsulationOrRefactory from './profileNoInsulationOrRefactory.png'
import profileOnlyInsulation from './profileOnlyInsulation.png'
import profileOnlyRefactory from './profileOnlyRefactory.png'

export {
  acceptanceCriteriaNN,
  acceptanceCriteriaNY,
  acceptanceCriteriaYN,
  acceptanceCriteriaYY,
  insulationAndRefactory,
  noInsulationOrRefactory,
  onlyInsulation,
  onlyRefactory,
  profileInsulationAndRefactory,
  profileNoInsulationOrRefactory,
  profileOnlyInsulation,
  profileOnlyRefactory
}
