/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'

import { getAutoCorrelationPerChainGraph } from '../lib/getResultCases.js'

const AutoCorrelationPerChain = ({ results }) => {
  let keys
  const values = []
  const items = []
  let graphData
  let graphKeys
  let firstKey
  if(typeof results.autoCorrelationData !== 'undefined') {
    const jsonObject = JSON.parse(results.autoCorrelationData[0])
    keys = Object.keys(jsonObject)
    let i = 0
    keys.forEach((key) => {
      items.push(<E2GSelectFieldOption label={`${key}`} value={`${key}`} key={i} />)
      values.push(`${key}`)
      i += 1
    })
    graphData = getAutoCorrelationPerChainGraph(results.autoCorrelationData, results.autoCorrelationKeys ? results.autoCorrelationKeys[0] : values[0])
    graphKeys = Object.keys(graphData)
    firstKey = graphKeys[0]
  }
  return (
    <div className="cf w-100">
      {results.autoCorrelationData
        ? (
          <div className="fl w-100">
            <E2GSelectFieldIndexer name="autoCorrelationKeys" value={results.autoCorrelationKeys ? results.autoCorrelationKeys[0] : values[0]} label="Select Parameter to Plot">
              {items}
            </E2GSelectFieldIndexer>
            <div>
              <DataGraph plotClass="data-graph-85" data={graphData[firstKey].traces} layout={graphData[firstKey].layout} />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

AutoCorrelationPerChain.propTypes = {
  results: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
})

export default connect(mapStateToProps, null)(AutoCorrelationPerChain)
