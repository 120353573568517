import React, { useContext, useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Alert, Box, Button, Collapse, Grid, Typography } from '@mui/material'

import InputFiles from '@e2grnd/ui-core/dist/components/InputFiles'

import { WorkflowStateContext } from '../WorkflowProvider'

import useField from './useField'

// eslint-disable-next-line react/prop-types
const InlineCode: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <code style={{
    fontSize: '85%',
    border: '1px solid rgba(100, 100, 100, 0.25)',
    borderRadius: 3,
    backgroundColor: 'rgba(100, 100, 100, 0.1',
    padding: '0 2px 1px 2px',
  }}
  >{children}
  </code>
)
// eslint-disable-next-line react/prop-types
const Codeblock: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <pre style={{
    fontSize: '85%',
    border: '1px solid rgba(100, 100, 100, 0.25)',
    borderRadius: 3,
    backgroundColor: 'rgba(100, 100, 100, 0.1',
    padding: '0 2px 1px 20px',
  }}
  ><code style={{ textAlign: 'left' }}>{children}</code>
  </pre>
)

function StepSelectFiles() {
  const state = useContext(WorkflowStateContext)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const { handleChange } = useField()
  const {
    files,
    error,
  } = state
  return (
    <Grid container alignItems="center" direction="column" spacing={4}>
      <Grid item xs={6}>
        <Typography paragraph style={{ textAlign: 'center' }}>Select a directory to upload.</Typography>
        <Box my={2}>
          <Alert color="info">
            <Typography variant="body1">Please ensure the directory contains a <InlineCode>circuits_exported.csv</InlineCode> file that includes a column called <InlineCode>Combined_Circuit</InlineCode> that contains the circuit ID/Name.</Typography>
            <Typography variant="body1">Selected circuits will be passed as an argument to the selected script at runtime (separate processes will be spawned for each circuit). In order for your script to utilize circuit scoping, it will need to read the circuits from the arguments. For example:</Typography>
            <Codeblock>{`args <- commandArgs(trailingOnly=TRUE)
circuits_list= c(args[1])`}
            </Codeblock>
            <Typography variant="body2">Note: any files in a <InlineCode>results</InlineCode> directory in your upload will be omitted.</Typography>
          </Alert>
        </Box>
        <InputFiles name="inputFiles" value={files} onChange={handleChange} directory />
        <Collapse in={Boolean(error)}>
          <Box mt={2}>
            <Alert color="error">{error?.message}</Alert>
          </Box>
        </Collapse>
        <Box my={5}>
          <Typography variant="h5">Resource (RAM &amp; Disk) Scaling</Typography>
          <Typography variant="body1" paragraph>
            For circuits that need more resources, include a column named <InlineCode>resource_scaling_factor</InlineCode> in your <InlineCode>circuits_exported.csv</InlineCode> file. In this column, each row (circuit) may specify a float (<InlineCode>0.0</InlineCode>-<InlineCode>1.0</InlineCode>) to indicate the quantity of resources it might need. If not specified (or a value of <InlineCode>0</InlineCode> is), the circuit will be executed with the default resources (4GB RAM, 1GB disk). If a value of <InlineCode>1.0</InlineCode> is specified, the maximum available resources (512GB RAM, 64GB disk) will be used. Any value between 0 and 1 will be interpolated.
          </Typography>
          <Typography variant="h5">Script Results</Typography>
          <Typography variant="body1" paragraph>
            Files that your scripts output to the <InlineCode>/results</InlineCode> directory will be packaged into two archives that you can download from the job dashboard after they complete.
          </Typography>
          <Collapse in={showDetails}>
            <ol>
              <li>
                <Typography variant="h6">Full results</Typography>
                <Typography variant="body1" paragraph>
                  Intended to contain most of the results files, this archive will contain <InlineCode>/results</InlineCode> files that match any of the following patterns:
                </Typography>
                <ul>
                  <li><InlineCode>*.txt</InlineCode></li>
                  <li><InlineCode>*.html</InlineCode></li>
                  <li><InlineCode>*_zz.rds</InlineCode></li>
                </ul>
              </li>
              <li>
                <Typography variant="h6">Partial results</Typography>
                <Typography variant="body1" paragraph>
                  Intended to be a faster-to-download, lightweight version, this archive will contain only <InlineCode>/results</InlineCode> files that match any of the following patterns:
                </Typography>
                <ul>
                  <li><InlineCode>*.txt</InlineCode></li>
                  <li><InlineCode>*_zz.rds</InlineCode></li>
                </ul>
              </li>
            </ol>
            <Typography variant="h5">Customizing the Results Patterns</Typography>
            <Typography variant="body1" paragraph>
              If you would like to modify which files are included in the archives, you may include a file named <InlineCode>workload.yaml</InlineCode> in your upload.
            </Typography>
            <Typography variant="body1" paragraph>
              To include all results files in the full archive, and only the <InlineCode>zz.rds</InlineCode> files in the partial archive:
            </Typography>
            <Codeblock>{`full-results-patterns:
  - "*"
partial-results-patterns:
  - "*_zz.rds"`}
            </Codeblock>

            <Typography variant="body1" paragraph>
              The default <InlineCode>workload.yaml</InlineCode>:
            </Typography>
            <Codeblock>{`full-results-patterns:
  - "*.txt"
  - "*.htm"
  - "*.html"
  - "*_zz.rds"
partial-results-patterns:
  - "*.txt"
  - "*_zz.rds"`}
            </Codeblock>
          </Collapse>
          <Button
            onClick={() => setShowDetails(!showDetails)}
            startIcon={showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            Show {showDetails ? 'less' : 'more'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default StepSelectFiles
