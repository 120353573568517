/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'

import { getMetalLossProjectionGraphs2 } from '../lib/getResultCases.js'

const MetalLossProjections = ({ results, nextTaDate, turnaroundFrequency, problemType }) => {
  let keys
  const items = []
  const endIndexObject = {}
  let graphData
  let graphKeys
  let firstKey
  if(typeof results.metalLossProjections !== 'undefined') {
    let newNextTaDate
    if(typeof nextTaDate === 'undefined') {
      newNextTaDate = '01/01/2024'
    } else {
      newNextTaDate = nextTaDate
    }
    let newTurnaroundFrequency
    if(typeof turnaroundFrequency === 'undefined') {
      newTurnaroundFrequency = 4
    } else {
      newTurnaroundFrequency = turnaroundFrequency
    }
    const inputDates = [results.analysisDate[0], newNextTaDate, newTurnaroundFrequency]
    const jsonObject = JSON.parse(results.metalLossProjections[0])
    keys = Object.keys(jsonObject)
    const lengthKeys = keys.length
    let gridSize = 1
    if(lengthKeys > 6) {
      gridSize = 9 // rows = 3, columns = 3
    } else if(lengthKeys > 4) {
      gridSize = 6 // rows = 2, columns = 3
    } else if(lengthKeys > 3) {
      gridSize = 4 // rows = 2, columns = 2
    } else if(lengthKeys > 2) {
      gridSize = 3 // rows = 1, columns = 3
    } else if(lengthKeys > 1) {
      gridSize = 2 // rows = 1, columns = 2
    }
    const numPlots = Math.ceil(lengthKeys / gridSize)
    for(let i = 0; i < numPlots; i += 1) {
      if(i < numPlots - 1) {
        const startIndex = i * gridSize
        const endIndex = (i + 1) * gridSize - 1
        const startCml = jsonObject[startIndex].cmlId
        const endCml = jsonObject[endIndex].cmlId
        const selectLabel = `CML ${startCml}[${startIndex}]-${endCml}[${endIndex}]`
        items.push(<E2GSelectFieldOption label={selectLabel} value={startIndex} key={startIndex} />)
        endIndexObject[startIndex] = endIndex
      } else {
        const lengthNewKeys = lengthKeys - gridSize * (i + 1)
        let newGridSize = 1
        if(lengthNewKeys > 6) {
          newGridSize = 9 // rows = 3, columns = 3
        } else if(lengthNewKeys > 4) {
          newGridSize = 6 // rows = 2, columns = 3
        } else if(lengthNewKeys > 3) {
          newGridSize = 4 // rows = 2, columns = 2
        } else if(lengthNewKeys > 2) {
          newGridSize = 3 // rows = 1, columns = 3
        } else if(lengthNewKeys > 1) {
          newGridSize = 2 // rows = 1, columns = 2
        }
        const numPlotsNew = Math.ceil(lengthNewKeys / newGridSize)
        if(numPlotsNew > 0) {
          for(let j = 0; j < numPlotsNew; j += 1) {
            if(j < numPlots - 1) {
              const startIndex = i * gridSize + j * newGridSize
              const endIndex = i * gridSize + (j + 1) * newGridSize - 1
              const startCml = jsonObject[startIndex].cmlId
              const endCml = jsonObject[endIndex].cmlId
              const selectLabel = `CML ${startCml}[${startIndex}]-${endCml}[${endIndex}]`
              items.push(<E2GSelectFieldOption label={selectLabel} value={startIndex} key={startIndex} />)
              endIndexObject[startIndex] = endIndex
            } else {
              const startIndex = i * gridSize + j * newGridSize
              const endIndex = lengthKeys - 1
              const startCml = jsonObject[startIndex].cmlId
              const endCml = jsonObject[endIndex].cmlId
              const selectLabel = `CML ${startCml}[${startIndex}]-${endCml}[${endIndex}]`
              items.push(<E2GSelectFieldOption label={selectLabel} value={startIndex} key={startIndex} />)
              endIndexObject[startIndex] = endIndex
            }
          }
        } else {
          const startIndex = i * gridSize
          const endIndex = lengthKeys - 1
          const startCml = jsonObject[startIndex].cmlId
          const endCml = jsonObject[endIndex].cmlId
          const selectLabel = `CML ${startCml}[${startIndex}]-${endCml}[${endIndex}]`
          items.push(<E2GSelectFieldOption label={selectLabel} value={startIndex} key={startIndex} />)
          endIndexObject[startIndex] = endIndex
        }
      }
    }
    const endIndexIn = endIndexObject[results.metalLossKeys ? results.metalLossKeys[0] : keys[0]]
    graphData = getMetalLossProjectionGraphs2(results.metalLossProjections, results.metalLossKeys ? results.metalLossKeys[0] : keys[0], endIndexIn, inputDates, problemType)
    graphKeys = Object.keys(graphData)
    firstKey = graphKeys[0]
  }
  return (
    <div className="cf w-100">
      {results.metalLossProjections
        ? (
          <div className="fl w-100">
            <E2GSelectFieldIndexer name="metalLossKeys" value={results.metalLossKeys ? results.metalLossKeys[0] : keys[0]} label="Select CML(s) To Plot">
              {items}
            </E2GSelectFieldIndexer>
            <div>
              <DataGraph plotClass="data-graph-85" data={graphData[firstKey].traces} layout={graphData[firstKey].layout} />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

MetalLossProjections.propTypes = {
  results: PropTypes.object,
  nextTaDate: PropTypes.string,
  problemType: PropTypes.string,
  turnaroundFrequency: PropTypes.number,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  nextTaDate: state.workflow.fields.nextTaDate,
  problemType: state.workflow.fields.problemType,
  turnaroundFrequency: state.workflow.fields.turnaroundFrequency,
})

export default connect(mapStateToProps, null)(MetalLossProjections)
