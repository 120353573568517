import CladNoWeld from './CladNoWeld.png'
import CladResidualNo from './CladResidualNo.png'
import CladResidualNoPWHT from './CladResidualNoPWHT.png'
import CladResidualYes from './CladResidualYes.png'
import CladResidualYesPWHT from './CladResidualYesPWHT.png'
import CladWeld from './CladWeld.png'
import CYC from './CYC.png'
import CYL from './CYL.png'
import FlawClad from './FlawClad.png'
import FlawUnclad from './FlawUnclad.png'
import SP from './SP.png'
import UncladNoWeld from './UncladNoWeld.png'
import UncladWeld from './UncladWeld.png'

export {
  CladNoWeld,
  CladResidualNo,
  CladResidualNoPWHT,
  CladResidualYes,
  CladResidualYesPWHT,
  CladWeld,
  CYC,
  CYL,
  FlawClad,
  FlawUnclad,
  SP,
  UncladNoWeld,
  UncladWeld
}
