import type { TRPCParam } from 'calculators'

export const params: TRPCParam[] = [
  {
    valueType: 0,
    keyword: 'imageName',
    fieldName: 'imageName',
    default: 'r-workload',
    label: 'Image Name',
  },
  {
    valueType: 0,
    fieldName: 'machineClass',
    keyword: 'machineClass',
    default: 'o3',
    label: 'Preselected Machine Class',
    choices: {
      o1: 'n2d-standard-16 (vCPUs: 16, RAM: 64GB)',
      o2: 'n2d-standard-8 (vCPUs: 8, RAM: 32GB)',
      o3: 'n2d-standard-4 (vCPUs: 4, RAM: 16GB)',
    },
  },
  {
    valueType: 0,
    fieldName: 'commands',
    keyword: 'commands',
    default: undefined,
    label: 'Commands',
  },
  {
    valueType: 0,
    fieldName: 'commandResourceScales',
    keyword: 'commandResourceScales',
    default: undefined,
    label: 'Command Resource Scales',
  },
  {
    valueType: 0,
    fieldName: 'workDir',
    keyword: 'workDir',
    default: undefined,
    label: 'Working Directory',
  },
]

export default params
