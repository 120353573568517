import React, { ChangeEvent, useContext, useMemo } from 'react'
import { Alert, Autocomplete, Box, Collapse, TextField, Typography } from '@mui/material'

import { WorkflowDispatchContext, WorkflowStateContext } from '../WorkflowProvider'

type TOption = {
  name: string
  workDir: string
}
function StepSelectScript() {
  const state = useContext(WorkflowStateContext)
  const dispatch = useContext(WorkflowDispatchContext)
  const filesArray = useMemo(() => (state.files ? Array.from(state.files).filter(f => !f.webkitRelativePath.includes('/results/')) : []), [state.files])
  const options: TOption[] = filesArray.map(f => {
    const workDir = f.webkitRelativePath.slice(0, f.webkitRelativePath.indexOf('/') + 1)
    const name = f.webkitRelativePath.replace(workDir, '')
    return {
      name,
      workDir,
    }
  }).sort((a, b) => a.name.localeCompare(b.name))

  const value = options.find(o => o.name === state.selectedScript) || null

  const handleChange = (_e: ChangeEvent<{}>, selected: TOption | null) => {
    dispatch({
      type: 'setSelectedScript',
      payload: selected,
    })
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography paragraph style={{ textAlign: 'center' }}>Select which file to execute.</Typography>

      <Autocomplete
        value={value}
        onChange={handleChange}
        id="input-select-script"
        options={options}
        style={{ width: 300 }}
        getOptionLabel={(o) => o.name}
        isOptionEqualToValue={(o, val) => o.name === val.name}
        renderInput={(params) => <TextField {...params} label="Select a script" variant="outlined" />}
      />
      <Collapse in={Boolean(state.error)}>
        <Box py={2}>
          <Alert severity="error">{state.error?.message}</Alert>
        </Box>
      </Collapse>
    </Box>
  )
}

export default StepSelectScript
