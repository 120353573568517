/* eslint-disable camelcase */
import { useContext } from 'react'
import { AxiosError } from 'axios'

import {
  CalculatorRequest,
  Data,
  Metadata,
  Parameter,
  Parameter_ValueType,
} from '@e2grnd/service-interface/dist/cjs/calculator/v3/calculator_pb'
import { useAuthContext } from '@e2grnd/ui-core'
import { getAxiosInstance } from '@e2grnd/ui-core/dist/core/endpoint'

import { WorkflowDispatchContext, WorkflowStateContext } from '../WorkflowProvider'

function useRequest() {
  const dispatch = useContext(WorkflowDispatchContext)
  const state = useContext(WorkflowStateContext)
  const { userId } = useAuthContext()

  const handleSubmit = async () => {
    dispatch({
      type: 'jobSubmitted',
    })
    try {
      const crq = new CalculatorRequest()
      crq.metadata = new Metadata()
      crq.metadata.calculatorName = 'workload.degradationAnalysis'
      crq.metadata.userId = userId || ''
      crq.metadata.jobName = state.jobName || ''
      crq.metadata.customerName = state.customerId || ''
      crq.metadata.params = []
      const vp = new Parameter()
      vp.name = 'version'
      vp.valueType = 0
      vp.valuesString = [process.env.REACT_APP_VERSION as string]
      crq.metadata.params.push(vp)
      crq.data = new Data()
      crq.data.params = []
      const data = new FormData()
      const {
        fields,
        params,
      } = state
      if(!(state.files?.length)) {
        throw new Error('No files to upload')
      }
      Array.from(state.files)
        .filter(f => !f.webkitRelativePath.includes('/results/'))
        .forEach(f => {
          data.append(f.name, f)
        })
      Object.keys(fields).forEach(k => {
        const pc = params.find(p => p.fieldName === k)
        if(pc) {
          const p = new Parameter({
            name: pc.keyword,
            valueType: Parameter_ValueType.STRING,
          })
          if(Array.isArray(fields[k])) {
            p.valuesString = fields[k]
          } else {
            p.valuesString = [fields[k]]
          }
          (crq.data as Data).params.push(p)
        }
      })
      const b = new Blob([crq.toBinary()])
      data.append('request', b, 'workload.crq')
      const axios = await getAxiosInstance()
      const resp = await axios.post('/api/job/create', data)
      if(!resp) {
        throw new Error('Invalid response received from server')
      }
      dispatch({
        type: 'jobCreated',
        payload: {
          jobId: resp.data.metadata.jobId,
        },
      })
    } catch(err) {
      console.error((err as AxiosError).response?.data)
      dispatch({ type: 'error', payload: err as Error, preventSubmission: true })
    }
  }
  return ({
    handleSubmit,
  })
}

export default useRequest
