import averagePitDensity from './averagePitDensity.png'
import depthStates from './depthStates.png'
import pitProp2 from './pitProp2.png'
import pitPropagation from './pitPropagation.png'

export {
  averagePitDensity,
  depthStates,
  pitProp2,
  pitPropagation
}
