import CCJA from './CCJA.png'
import CCJC from './CCJC.png'
import CCJCCCJA from './CCJCCCJA.png'
import CLJA from './CLJA.png'
import CLJA_GLOBAL from './CLJA_GLOBAL.png'
import CLJC from './CLJC.png'
import CLJCCLJA from './CLJCCLJA.png'
import CLJCCLJA_GLOBAL from './CLJCCLJA_GLOBAL.png'
import CO from './CO.png'
import CORA from './CORA.png'
import CORG from './CORG.png'
import COU from './COU.png'
import COUL from './COUL.png'
import CY from './CY.png'
import CYD from './CYD.png'
import CYDID from './CYDID.png'
import CYU from './CYU.png'
import CYUID from './CYUID.png'
import CYUL from './CYUL.png'
import fabTolerancesB31 from './fabTolerancesB31.png'
import fabTolerancesS8 from './fabTolerancesS8.png'
import PE from './PE.png'
import PED from './PED.png'
import PEID from './PEID.png'
import SCJA from './SCJA.png'
import SCJCSCJA from './SCJCSCJA.png'
import siteFactorsTable from './siteFactorsTable.png'
import SP from './SP.png'
import SPU from './SPU.png'

export {
  CCJA,
  CCJC,
  CCJCCCJA,
  CLJA,
  CLJA_GLOBAL,
  CLJC,
  CLJCCLJA,
  CLJCCLJA_GLOBAL,
  CO,
  CORA,
  CORG,
  COU,
  COUL,
  CY,
  CYD,
  CYDID,
  CYU,
  CYUID,
  CYUL,
  fabTolerancesB31,
  fabTolerancesS8,
  PE,
  PED,
  PEID,
  SCJA,
  SCJCSCJA,
  siteFactorsTable,
  SP,
  SPU
}
