// THIS FILE IS GENERATED BY: plugins/calculator-meta/generate-page-template.ts
import React from 'react'
import NoSsr from '@mui/material/NoSsr'

import CorrSolutionsProvider from '../providers/CorrSolutionsProvider'

export default function CorrSolutionsPage() {
  return (
    <NoSsr>
      <CorrSolutionsProvider />
    </NoSsr>
  )
}
