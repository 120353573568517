/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'

import { getRunningMeanPerChainGraph } from '../lib/getResultCases.js'

const RunningMeanPerChain = ({ results }) => {
  let keys
  const values = []
  const items = []
  let graphData
  let graphKeys
  let firstKey
  if(typeof results.runningMeanOutData !== 'undefined') {
    const jsonObject = JSON.parse(results.runningMeanOutData[0])
    keys = Object.keys(jsonObject)
    let i = 0
    keys.forEach((key) => {
      jsonObject[key].forEach((val, index2) => {
        if(jsonObject[key].length > 1) {
          items.push(<E2GSelectFieldOption label={`${key}[${index2}]`} value={`${key}[${index2}]`} key={i} />)
          values.push(`${key}[${index2}]`)
        } else {
          items.push(<E2GSelectFieldOption label={`${key}`} value={`${key}`} key={i} />)
          values.push(`${key}`)
        }
        i += 1
      })
    })
    graphData = getRunningMeanPerChainGraph(results.runningMeanOutData, results.runningMeanKeys ? results.runningMeanKeys[0] : values[0])
    graphKeys = Object.keys(graphData)
    firstKey = graphKeys[0]
  }
  return (
    <div className="cf w-100">
      {results.runningMeanOutData
        ? (
          <div className="fl w-100">
            <E2GSelectFieldIndexer name="runningMeanKeys" value={results.runningMeanKeys ? results.runningMeanKeys[0] : values[0]} label="Select Parameter to Plot">
              {items}
            </E2GSelectFieldIndexer>
            <div>
              <DataGraph plotClass="data-graph-85" data={graphData[firstKey].traces} layout={graphData[firstKey].layout} />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

RunningMeanPerChain.propTypes = {
  results: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
})

export default connect(mapStateToProps, null)(RunningMeanPerChain)
