/* eslint quote-props: 0 */
export function getPipeNPS() {
  // const nps = ['NPS 1/8', 'NPS 1/4', 'NPS 3/8', 'NPS 1/2', 'NPS 3/4', 'NPS 1', 'NPS 1-1/4', 'NPS 1-1/2', 'NPS 2', 'NPS 2-1/2', 'NPS 3',
  //   'NPS 3-1/2', 'NPS 4', 'NPS 5', 'NPS 6', 'NPS 8', 'NPS 10', 'NPS 12', 'NPS 14', 'NPS 16', 'NPS 18', 'NPS 20', 'NPS 22', 'NPS 24',
  //   'NPS 26', 'NPS 28', 'NPS 30', 'NPS 32', 'NPS 34', 'NPS 36', 'NPS 38', 'NPS 40', 'NPS 42', 'NPS 44', 'NPS 46', 'NPS 48'];
  const nps = [0.125, 0.25, 0.375, 0.5, 0.75, 1, 1.25, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 24, 26, 28, 30, 32, 34, 36, 42, 48]
  return nps
}

export function getPipeNPS2() {
  // const nps = ['NPS 1/8', 'NPS 1/4', 'NPS 3/8', 'NPS 1/2', 'NPS 3/4', 'NPS 1', 'NPS 1-1/4', 'NPS 1-1/2', 'NPS 2', 'NPS 2-1/2', 'NPS 3',
  //   'NPS 3-1/2', 'NPS 4', 'NPS 5', 'NPS 6', 'NPS 8', 'NPS 10', 'NPS 12', 'NPS 14', 'NPS 16', 'NPS 18', 'NPS 20', 'NPS 22', 'NPS 24',
  //   'NPS 26', 'NPS 28', 'NPS 30', 'NPS 32', 'NPS 34', 'NPS 36', 'NPS 38', 'NPS 40', 'NPS 42', 'NPS 44', 'NPS 46', 'NPS 48'];
  const nps = ['0.125', '0.25', '0.375', '0.5', '0.75', '1', '1.25', '1.5', '2', '2.5', '3', '3.5', '4', '5', '6', '8', '10', '12', '14', '16', '18', '20', '24', '26', '28', '30', '32', '34', '36', '42', '48']
  return nps
}

export function getPipeDiameter() {
  const nps = ['NPS 1/8',
    'NPS 1/4',
    'NPS 3/8',
    'NPS 1/2',
    'NPS 3/4',
    'NPS 1',
    'NPS 1-1/4',
    'NPS 1-1/2',
    'NPS 2',
    'NPS 2-1/2',
    'NPS 3',
    'NPS 3-1/2',
    'NPS 4',
    'NPS 5',
    'NPS 6',
    'NPS 8',
    'NPS 10',
    'NPS 12',
    'NPS 14',
    'NPS 16',
    'NPS 18',
    'NPS 20',
    'NPS 22',
    'NPS 24',
    'NPS 26',
    'NPS 28',
    'NPS 30',
    'NPS 32',
    'NPS 34',
    'NPS 36',
    'NPS 38',
    'NPS 40',
    'NPS 42',
    'NPS 44',
    'NPS 46',
    'NPS 48']
  return nps
}

export function getComponents() {
  const components = ['CAP', 'CPLG', 'ELL', 'HVYWALL', 'NIP', 'PIPE', 'RED', 'TEE', 'TNIP', 'TPIPE']
  return components
}

export function getNPSPipeThickness(nps, sch) {
  const npsDictionary = {
    'NPS 1/8': { 'STD': 0.068, 'XS': 0.095, 'S10': 0.049, 'S30': 0.057, 'S40': 0.068, 'S80': 0.095, 'S10S': 0.049, 'S40S': 0.068, 'S80S': 0.095 },
    'NPS 1/4': { 'STD': 0.088, 'XS': 0.119, 'S10': 0.065, 'S30': 0.073, 'S40': 0.088, 'S80': 0.119, 'S10S': 0.065, 'S40S': 0.088, 'S80S': 0.119 },
    'NPS 3/8': { 'STD': 0.091, 'XS': 0.126, 'S10': 0.065, 'S30': 0.073, 'S40': 0.091, 'S80': 0.126, 'S10S': 0.065, 'S40S': 0.091, 'S80S': 0.126 },
    'NPS 1/2': { 'STD': 0.109, 'XS': 0.147, 'XXS': 0.294, 'S5': 0.065, 'S10': 0.083, 'S30': 0.095, 'S40': 0.109, 'S80': 0.147, 'S160': 0.188, 'S5S': 0.065, 'S10S': 0.083, 'S40S': 0.109, 'S80S': 0.147 },
    'NPS 3/4': { 'STD': 0.113, 'XS': 0.154, 'XXS': 0.308, 'S5': 0.065, 'S10': 0.083, 'S30': 0.095, 'S40': 0.113, 'S80': 0.154, 'S160': 0.219, 'S5S': 0.065, 'S10S': 0.083, 'S40S': 0.113, 'S80S': 0.154 },
    'NPS 1': { 'STD': 0.133, 'XS': 0.179, 'XXS': 0.358, 'S5': 0.065, 'S10': 0.109, 'S30': 0.114, 'S40': 0.133, 'S80': 0.179, 'S160': 0.250, 'S5S': 0.065, 'S10S': 0.109, 'S40S': 0.133, 'S80S': 0.179 },
    'NPS 1-1/4': { 'STD': 0.140, 'XS': 0.191, 'XXS': 0.382, 'S5': 0.065, 'S10': 0.109, 'S30': 0.117, 'S40': 0.140, 'S80': 0.191, 'S160': 0.250, 'S5S': 0.065, 'S10S': 0.109, 'S40S': 0.140, 'S80S': 0.191 },
    'NPS 1-1/2': { 'STD': 0.145, 'XS': 0.200, 'XXS': 0.400, 'S5': 0.065, 'S10': 0.109, 'S30': 0.125, 'S40': 0.145, 'S80': 0.200, 'S160': 0.281, 'S5S': 0.065, 'S10S': 0.109, 'S40S': 0.145, 'S80S': 0.200 },
    'NPS 2': { 'STD': 0.154, 'XS': 0.218, 'XXS': 0.436, 'S5': 0.065, 'S10': 0.109, 'S30': 0.125, 'S40': 0.154, 'S80': 0.218, 'S160': 0.344, 'S5S': 0.065, 'S10S': 0.109, 'S40S': 0.154, 'S80S': 0.218 },
    'NPS 2-1/2': { 'STD': 0.203, 'XS': 0.276, 'XXS': 0.552, 'S5': 0.083, 'S10': 0.120, 'S30': 0.188, 'S40': 0.203, 'S80': 0.276, 'S160': 0.375, 'S5S': 0.083, 'S10S': 0.120, 'S40S': 0.203, 'S80S': 0.276 },
    'NPS 3': { 'STD': 0.216, 'XS': 0.300, 'XXS': 0.600, 'S5': 0.083, 'S10': 0.120, 'S30': 0.188, 'S40': 0.216, 'S80': 0.300, 'S160': 0.438, 'S5S': 0.083, 'S10S': 0.120, 'S40S': 0.216, 'S80S': 0.300 },
    'NPS 3-1/2': { 'STD': 0.226, 'XS': 0.318, 'S5': 0.083, 'S10': 0.120, 'S30': 0.188, 'S40': 0.226, 'S80': 0.318, 'S5S': 0.083, 'S10S': 0.120, 'S40S': 0.226, 'S80S': 0.318 },
    'NPS 4': { 'STD': 0.237, 'XS': 0.337, 'XXS': 0.674, 'S5': 0.083, 'S10': 0.120, 'S30': 0.188, 'S40': 0.237, 'S80': 0.337, 'S120': 0.438, 'S160': 0.531, 'S5S': 0.083, 'S10S': 0.120, 'S40S': 0.237, 'S80S': 0.337 },
    'NPS 5': { 'STD': 0.258, 'XS': 0.375, 'XXS': 0.750, 'S5': 0.109, 'S10': 0.134, 'S40': 0.258, 'S80': 0.375, 'S120': 0.500, 'S160': 0.625, 'S5S': 0.109, 'S10S': 0.134, 'S40S': 0.258, 'S80S': 0.375 },
    'NPS 6': { 'STD': 0.280, 'XS': 0.432, 'XXS': 0.864, 'S5': 0.109, 'S10': 0.134, 'S40': 0.280, 'S80': 0.432, 'S120': 0.562, 'S160': 0.719, 'S5S': 0.109, 'S10S': 0.134, 'S40S': 0.280, 'S80S': 0.432 },
    'NPS 8': { 'STD': 0.322, 'XS': 0.500, 'XXS': 0.875, 'S5': 0.109, 'S10': 0.148, 'S20': 0.250, 'S30': 0.277, 'S40': 0.322, 'S60': 0.406, 'S80': 0.500, 'S100': 0.594, 'S120': 0.719, 'S140': 0.812, 'S160': 0.906, 'S5S': 0.109, 'S10S': 0.148, 'S40S': 0.322, 'S80S': 0.500 },
    'NPS 10': { 'STD': 0.365, 'XS': 0.500, 'XXS': 1.000, 'S5': 0.135, 'S10': 0.165, 'S20': 0.250, 'S30': 0.307, 'S40': 0.365, 'S60': 0.500, 'S80': 0.594, 'S100': 0.719, 'S120': 0.844, 'S140': 1.000, 'S160': 1.125, 'S5S': 0.134, 'S10S': 0.165, 'S40S': 0.365, 'S80S': 0.500 },
    'NPS 12': { 'STD': 0.375, 'XS': 0.500, 'XXS': 1.000, 'S5': 0.156, 'S10': 0.180, 'S20': 0.250, 'S30': 0.330, 'S40': 0.406, 'S60': 0.562, 'S80': 0.688, 'S100': 0.844, 'S120': 1.00, 'S140': 1.125, 'S160': 1.312, 'S5S': 0.156, 'S10S': 0.180, 'S40S': 0.375, 'S80S': 0.500 },
    'NPS 14': { 'STD': 0.375, 'XS': 0.500, 'S5': 0.156, 'S10': 0.250, 'S20': 0.312, 'S30': 0.375, 'S40': 0.438, 'S60': 0.594, 'S80': 0.750, 'S100': 0.938, 'S120': 1.094, 'S140': 1.250, 'S160': 1.406, 'S5S': 0.156, 'S10S': 0.188, 'S40S': 0.375, 'S80S': 0.500 },
    'NPS 16': { 'STD': 0.375, 'XS': 0.500, 'S5': 0.165, 'S10': 0.250, 'S20': 0.312, 'S30': 0.375, 'S40': 0.500, 'S60': 0.656, 'S80': 0.844, 'S100': 1.031, 'S120': 1.219, 'S140': 1.438, 'S160': 1.594, 'S5S': 0.165, 'S10S': 0.188, 'S40S': 0.375, 'S80S': 0.500 },
    'NPS 18': { 'STD': 0.375, 'XS': 0.500, 'S5': 0.165, 'S10': 0.250, 'S20': 0.312, 'S30': 0.438, 'S40': 0.562, 'S60': 0.750, 'S80': 0.938, 'S100': 1.156, 'S120': 1.375, 'S140': 1.562, 'S160': 1.781, 'S5S': 0.165, 'S10S': 0.188, 'S40S': 0.375, 'S80S': 0.500 },
    'NPS 20': { 'STD': 0.375, 'XS': 0.500, 'S5': 0.188, 'S10': 0.250, 'S20': 0.375, 'S30': 0.500, 'S40': 0.594, 'S60': 0.812, 'S80': 1.031, 'S100': 1.281, 'S120': 1.500, 'S140': 1.750, 'S160': 1.969, 'S5S': 0.188, 'S10S': 0.218, 'S40S': 0.375, 'S80S': 0.500 },
    'NPS 22': { 'STD': 0.375, 'XS': 0.500, 'S5': 0.188, 'S10': 0.250, 'S20': 0.375, 'S30': 0.500, 'S60': 0.875, 'S80': 1.125, 'S100': 1.375, 'S120': 1.625, 'S140': 1.875, 'S160': 2.125, 'S5S': 0.188, 'S10S': 0.218 },
    'NPS 24': { 'STD': 0.375, 'XS': 0.500, 'S5': 0.218, 'S10': 0.250, 'S20': 0.375, 'S30': 0.562, 'S40': 0.688, 'S60': 0.969, 'S80': 1.219, 'S100': 1.531, 'S120': 1.812, 'S140': 2.062, 'S160': 2.344, 'S5S': 0.218, 'S10S': 0.250, 'S40S': 0.375, 'S80S': 0.500 },
    'NPS 26': { 'STD': 0.375, 'XS': 0.500, 'S10': 0.312, 'S20': 0.500 },
    'NPS 28': { 'STD': 0.375, 'XS': 0.500, 'S10': 0.312, 'S20': 0.500, 'S30': 0.625 },
    'NPS 30': { 'STD': 0.375, 'XS': 0.500, 'S5': 0.250, 'S10': 0.312, 'S20': 0.500, 'S30': 0.625, 'S5S': 0.250, 'S10S': 0.312 },
    'NPS 32': { 'STD': 0.375, 'XS': 0.500, 'S10': 0.312, 'S20': 0.500, 'S30': 0.625, 'S40': 0.688 },
    'NPS 34': { 'STD': 0.375, 'XS': 0.500, 'S10': 0.312, 'S20': 0.500, 'S30': 0.625, 'S40': 0.688 },
    'NPS 36': { 'STD': 0.375, 'XS': 0.500, 'S10': 0.312, 'S20': 0.500, 'S30': 0.625, 'S40': 0.750 },
    'NPS 38': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 40': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 42': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 44': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 46': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 48': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 52': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 56': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 60': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 64': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 68': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 72': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 76': { 'STD': 0.375, 'XS': 0.500 },
    'NPS 80': { 'STD': 0.375, 'XS': 0.500 },
  }
  return npsDictionary[nps][sch]
}

export function getMeMultiplier(component) {
  const lookup = {
    CAP: 1.2,
    CPLG: 1.15,
    ELL: 1.2,
    HVYWALL: 1.2,
    NIP: 1.15,
    PIPE: 1.0,
    RED: 1.75,
    TEE: 1.65,
    TNIP: 1.15,
    TPIPE: 1.0,
  }
  const measurementErrorMultiplier = lookup[component]
  return measurementErrorMultiplier
}

export function getSigmaMultiplier(component) {
  const lookup = {
    CAP: 1.24,
    CPLG: 1.1,
    ELL: 1.24,
    HVYWALL: 1.25,
    NIP: 1.0,
    PIPE: 1.0,
    RED: 1.58,
    TEE: 1.54,
    TNIP: 1.0,
    TPIPE: 1.0,
  }
  const sigmaMultiplier = lookup[component]
  return sigmaMultiplier
}

export function getNumberOfReadings(nps, inspectionType) {
  let numberOfReadings = 1
  if(inspectionType === 'RT') {
    const npsDictionary = {
      'NPS 1/8': 4,
      'NPS 1/4': 4,
      'NPS 3/8': 4,
      'NPS 1/2': 4,
      'NPS 3/4': 8,
      'NPS 1': 8,
      'NPS 1-1/4': 8,
      'NPS 1-1/2': 8,
      'NPS 2': 20,
      'NPS 2-1/2': 20,
      'NPS 3': 20,
      'NPS 3-1/2': 20,
      'NPS 4': 20,
      'NPS 5': 20,
      'NPS 6': 20,
      'NPS 8': 20,
      'NPS 10': 20,
      'NPS 12': 20,
      'NPS 14': 20,
      'NPS 16': 20,
      'NPS 18': 20,
      'NPS 20': 20,
      'NPS 22': 20,
      'NPS 24': 20,
      'NPS 26': 20,
      'NPS 28': 20,
      'NPS 30': 20,
      'NPS 32': 20,
      'NPS 34': 20,
      'NPS 36': 20,
      'NPS 38': 20,
      'NPS 40': 20,
      'NPS 42': 20,
      'NPS 44': 20,
      'NPS 46': 20,
      'NPS 48': 20,
      'NPS 52': 20,
      'NPS 56': 20,
      'NPS 60': 20,
      'NPS 64': 20,
      'NPS 68': 20,
      'NPS 72': 20,
      'NPS 76': 20,
      'NPS 80': 20,
    }
    numberOfReadings = npsDictionary[nps]
  } else if(inspectionType === 'UT') {
    const npsDictionary = {
      'NPS 1/8': 2,
      'NPS 1/4': 2,
      'NPS 3/8': 2,
      'NPS 1/2': 2,
      'NPS 3/4': 2,
      'NPS 1': 2,
      'NPS 1-1/4': 2,
      'NPS 1-1/2': 2,
      'NPS 2': 4,
      'NPS 2-1/2': 4,
      'NPS 3': 4,
      'NPS 3-1/2': 4,
      'NPS 4': 4,
      'NPS 5': 4,
      'NPS 6': 4,
      'NPS 8': 4,
      'NPS 10': 4,
      'NPS 12': 4,
      'NPS 14': 4,
      'NPS 16': 4,
      'NPS 18': 4,
      'NPS 20': 4,
      'NPS 22': 4,
      'NPS 24': 4,
      'NPS 26': 4,
      'NPS 28': 4,
      'NPS 30': 4,
      'NPS 32': 4,
      'NPS 34': 4,
      'NPS 36': 4,
      'NPS 38': 4,
      'NPS 40': 4,
      'NPS 42': 4,
      'NPS 44': 4,
      'NPS 46': 4,
      'NPS 48': 4,
      'NPS 52': 4,
      'NPS 56': 4,
      'NPS 60': 4,
      'NPS 64': 4,
      'NPS 68': 4,
      'NPS 72': 4,
      'NPS 76': 4,
      'NPS 80': 4,
    }
    numberOfReadings = npsDictionary[nps]
  }
  return numberOfReadings
}
