import React, { useContext, useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Alert, Box, Button, Collapse, LinearProgress, TextField, Typography } from '@mui/material'
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback'

import ParamsList from '@e2grnd/ui-core/dist/components/ParamsList'
import { Context as LayoutContext } from '@e2grnd/ui-core/dist/contexts/LayoutStateProvider'

import { WorkflowDispatchContext, WorkflowStateContext } from '../WorkflowProvider'

import { EstimatedCost } from './EstimatedCost'

const MAX_JOB_NAME_LENGTH = 254

export default function StepSubmit() {
  const {
    submitting,
    error,
    fields,
    params,
    jobId,
    selectedScript,
    selectedCircuits,
  } = useContext(WorkflowStateContext)
  const dispatch = useContext(WorkflowDispatchContext)
  const { dispatch: layoutDispatch } = useContext(LayoutContext)
  const [jobName, setJobName] = useState<string>(() => {
    const scriptBasename = selectedScript && selectedScript.split('/').pop()
    return [scriptBasename, ...(selectedCircuits || [])]
      .filter(i => !!i)
      .join(' ')
      .slice(0, MAX_JOB_NAME_LENGTH)
      .trim()
  })
  const dispatchSetJobName = useDebouncedCallback(
    (val: string) => dispatch({
      type: 'setJobName',
      payload: val.slice(0, MAX_JOB_NAME_LENGTH).trim(),
    }),
    [],
    200,
    { leading: false, trailing: true }
  )

  useEffect(() => {
    dispatchSetJobName(jobName)
  }, [dispatchSetJobName, jobName])

  const handleJobNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJobName(e.target.value)
  }

  const displayedParams = ['imageName', 'commands']
  const filteredFields = Object.entries(fields).reduce((acc, [k, v]) => {
    if(displayedParams.includes(k)) {
      acc[k] = v
    }
    return acc
  }, {} as Partial<typeof fields>)

  function openJobDrawer() {
    layoutDispatch({ type: 'setJobDrawerOpen', payload: { jobDrawerOpen: true } })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box mb={2} display="flex" flexDirection="column" alignItems="center">
        <Button color="primary" variant="outlined" onClick={() => openJobDrawer()}>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">View Jobs <OpenInNewIcon fontSize="inherit" style={{ paddingLeft: '0.333em' }} /></Box>
        </Button>
      </Box>
      <ParamsList
        fields={filteredFields}
        params={params}
        additionalRows={[
          {
            label: 'Estimated Cost',
            value: <EstimatedCost />,
          },
        ]}
        style={submitting ? { opacity: 0.33 } : {}}
      />
      <Collapse in={Boolean(submitting)}>
        <Box my={2} display="flex" flexDirection="column" alignItems="center">
          <Typography gutterBottom>Uploading...</Typography>
          <Box minWidth={250}>
            <LinearProgress variant="indeterminate" />
          </Box>
        </Box>
      </Collapse>
      <Collapse in={!(jobId || submitting)}>
        <Box my={2}>
          <TextField
            id="input-job-name"
            label="Job Name"
            helperText="Give this job a name"
            variant="outlined"
            value={jobName}
            onChange={handleJobNameChange}
            style={{ minWidth: 300 }}
            inputProps={{ maxLength: MAX_JOB_NAME_LENGTH }}
          />
        </Box>
      </Collapse>
      <Collapse in={Boolean(error)}>
        <Box my={2} maxWidth={500}>
          <Alert severity="error">{error?.message}</Alert>
        </Box>
      </Collapse>
      <Collapse in={Boolean(jobId)}>
        <Box my={2} display="flex" flexDirection="column" alignItems="center">
          <CheckCircleIcon />
          <Typography paragraph>Your job has been submitted.</Typography>
        </Box>
      </Collapse>

    </Box>
  )
}
