import CY from './CY.png'
import EXTR from './EXTR.png'
import ExtrudedDims from './ExtrudedDims.png'
import INTEGRAL from './INTEGRAL.png'
import IntegralBranch from './IntegralBranch.png'
import IntegralBranchWelds from './IntegralBranchWelds.png'
import PipeBranchDims from './PipeBranchDims.png'
import PipeDims from './PipeDims.png'
import PipeDimsU from './PipeDimsU.png'
import REPAD from './REPAD.png'
import RepadBranch from './RepadBranch.png'
import RepadDims from './RepadDims.png'
import siteFactorsTable from './siteFactorsTable.png'

export {
  CY,
  EXTR,
  ExtrudedDims,
  INTEGRAL,
  IntegralBranch,
  IntegralBranchWelds,
  PipeBranchDims,
  PipeDims,
  PipeDimsU,
  REPAD,
  RepadBranch,
  RepadDims,
  siteFactorsTable
}
