/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'

import { getResidual } from '../lib/getResultCases.js'

const Trace = ({ results, problemType }) => {
  let graphData
  if(typeof results.residualMedians !== 'undefined') {
    const medians = results.residualMedians
    const lowerErrors = results.residualLowerBounds
    const upperErrors = results.residualUpperBounds
    graphData = getResidual(medians, lowerErrors, upperErrors, problemType)
  }
  return (
    <div className="cf w-100">
      {results.residualMedians
        ? (
          <div className="fl w-100">
            <DataGraph plotClass="data-graph-85" data={graphData.traces} layout={graphData.layout} />
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

Trace.propTypes = {
  results: PropTypes.object,
  problemType: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  results: state.workflow.response ? state.workflow.response.results : undefined,
})

export default connect(mapStateToProps, null)(Trace)
