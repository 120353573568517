import fixedContents from './fixedContents.png'
import fixedDesignHeights from './fixedDesignHeights.png'
import fixedInServiceHeights from './fixedInServiceHeights.png'
import floatContents from './floatContents.png'
import floatDesignHeights from './floatDesignHeights.png'
import floatInServiceHeights from './floatInServiceHeights.png'

export {
  fixedContents,
  fixedDesignHeights,
  fixedInServiceHeights,
  floatContents,
  floatDesignHeights,
  floatInServiceHeights
}
