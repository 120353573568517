import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import OpenStepper from '@e2grnd/ui-core/dist/containers/OpenStepper'
import RadioField, { RadioFieldOption } from '@e2grnd/ui-core/dist/containers/RadioField'

export const problemTypeLup = {
  CS_OLD: 'Corrsolutions Full Monty',
  CS: 'Corrsolutions Generalized',
  SIMP: 'Simple Jags Model',
  R: 'R Code Implementation - Latest',
}

const Problems = ({ problemType }) => (
  <div>
    <RadioField name="problemType" value={problemType} reInitialize label="Problem Type" >
      <RadioFieldOption value="CS_OLD" label="Corrsolutions DA Full-Monty Model" />
      <RadioFieldOption value="CS" label="Corrsolutions DA Model Generalized" />
      <RadioFieldOption value="SIMP" label="Simple Hiearchical Bayes Jags Model" />
      <RadioFieldOption value="R" label="Latest Corrsolutions DA R Code Model" />
    </RadioField>
    <OpenStepper label="Next" name="problemType" value={problemType} />
  </div>
)

Problems.propTypes = {
  problemType: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
})

export default connect(mapStateToProps, null)(Problems)
