import firstOrder from './firstOrder.png'
import lman from './lman.png'
import lman1 from './lman1.png'
import secondOrder from './secondOrder.png'

export {
  firstOrder,
  lman,
  lman1,
  secondOrder
}
