import jet from './jet.png'
import jetSmall from './jetSmall.png'
import rainbow from './rainbow.png'
import rainbowSmall from './rainbowSmall.png'

export {
  jet,
  jetSmall,
  rainbow,
  rainbowSmall
}
