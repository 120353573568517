import React from 'react'

import WorkloadModule from './WorkloadModule'

export { params } from './params'
export { steps } from './steps'
export { default as WorkloadModule } from './WorkloadModule'

export default {
  freeformPages: [
    {
      name: 'Prop1',
      title: 'Page 1',
      component: <WorkloadModule />,
    },
  ],
  params: [],
}
