import CladNoCrack from './CladNoCrack.png'
import criticalConcentration from './criticalConcentration.png'
import CY from './CY.png'
import midwallReduction from './midwallReduction.png'
import operatingHistory from './operatingHistory.png'
import operatingHistoryH2S from './operatingHistoryH2S.png'
import SP from './SP.png'
import UncladNoCrack from './UncladNoCrack.png'

export {
  CladNoCrack,
  criticalConcentration,
  CY,
  midwallReduction,
  operatingHistory,
  operatingHistoryH2S,
  SP,
  UncladNoCrack
}
