/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

const CorrosionRates = ({ circuit, circuitCorrosionRates, cmlCorrosionRates, acfCorrosionRates }) => {
  return (
    <div className="cf w-100">
      <div>
        <b><i>{circuit}</i> Corrosion Rates</b>
        <DataTableDisplay id="circuitCorrosionRates" table={circuitCorrosionRates} containerHeight="75px" />
        <br /><br />
      </div>

      <div>
        <b><i>{circuit}</i> ACF Group Corrosion Rates</b>
        <DataTableDisplay id="acfCorrosionRatesTable" table={acfCorrosionRates} containerHeight="150px" />
      </div>

      <div>
        <b><i>{circuit}</i> CML Corrosion Rates</b>
        <DataTableDisplay id="cmlCorrosionRatesTable" table={cmlCorrosionRates} containerHeight="250px" />
        <br /><br />
      </div>

      <div>
        <br/><br/><br/>
        <b>B-Values represent the credible interval levels for the corrosion rates. </b>
        <i>B50 = Median;  B75 = 3rd Quartile; B90, B95, B99 are the 90th, 95th, and 99th Precentiles Respectively</i>
      </div>
    </div>
  )
}

CorrosionRates.propTypes = {
  circuit: PropTypes.string,
  circuitCorrosionRates: PropTypes.object,
  cmlCorrosionRates: PropTypes.object,
  acfCorrosionRates: PropTypes.object,
}

const mapStateToProps = state => ({
  circuit: state.workflow.fields.circuit,
  circuitCorrosionRates: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.circuitCorrosionRates : undefined,
  cmlCorrosionRates: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.cmlCorrosionRates : undefined,
  acfCorrosionRates: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.acfCorrosionRates : undefined,
})

export default connect(mapStateToProps, null)(CorrosionRates)
