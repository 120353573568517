import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Typography } from '@mui/material'

import RequestTrialForm from '@e2grnd/ui-core/dist/components/support/RequestTrialForm'

export default function FeedbackPage() {
  return (
    <Box p={4}>
      <Helmet title="Request a Trial" />
      <Typography variant="h1" gutterBottom>Request a Trial</Typography>
      <RequestTrialForm />
    </Box>
  )
}
