/* eslint semi: ["error", "never"] */

import { AxiosError } from 'axios'

import { TRPCParam } from 'calculators'

import defaultParams from '../params'

type TWorkloadImageName = 'py-workload' | 'r-workload'
type TPythonExtensions = 'py' | 'py3' | 'pyc'
type TRscriptExtensions = 'r' | 'rscript' | 'rs'
type TSupportedExtensions = TPythonExtensions | TRscriptExtensions
export type TMachineClass = null | 'o1'| 'o2'| 'o3'| 'o4'| 'o5'| 'o6'| 'o7'| 'o8'| 'o9'

export type TCircuitResourceScales = Record<string, number>

const extensionImageMap: {
  [ext in TSupportedExtensions]: TWorkloadImageName
} = {
  r: 'r-workload',
  rs: 'r-workload',
  rscript: 'r-workload',
  py: 'py-workload',
  py3: 'py-workload',
  pyc: 'py-workload',
}

const extensionExecutableMap: { [k in TSupportedExtensions]: string } = {
  py: 'python',
  py3: 'python',
  pyc: 'python',
  r: 'xvfb-run Rscript',
  rs: 'xvfb-run Rscript',
  rscript: 'xvfb-run Rscript',
}

export type TWorkflowState = {
  fields: {
    [k: string]: any
    imageName?: TWorkloadImageName
    machineClass?: TMachineClass
    commands?: string[]
    workDir?: string
    commandResourceScales?: string // json stringified representation of circuitResourceScales from top-level state
    someBit?: number // just to add a boolean bit type for unused `toggleField` action
  }
  jobName?: string
  files?: FileListWithWebkitDrafts
  selectedScript: string | null
  availableCircuits?: string[]
  selectedCircuits?: string[]
  circuitResourceScales?: TCircuitResourceScales
  submitting?: boolean
  params: TRPCParam[]
  jobId?: string
  error?: Error
  preventSubmission?: boolean
  customerId?: string
}

export const reducerInitializer = (params: TRPCParam[] = defaultParams): TWorkflowState => ({
  fields: {
    imageName: (params.find(item => item.fieldName === 'imageName')?.default) as TWorkloadImageName,
    machineClass: (params.find(item => item.fieldName === 'machineClass')?.default) as TMachineClass,
    commands: [],
  },
  submitting: false,
  params,
  selectedScript: null,
  availableCircuits: [],
  selectedCircuits: [],
})

export type TWorkflowActionAsync = {
  type: 'changedFiles'
  payload: FileListWithWebkitDrafts
}

export type TWorkflowAction = {
  type: 'initialize'
}
| {
  type: 'noop' // Used when initializing from fixed state. Only triggers derived state calculations.
}
| {
  type: 'setFiles'
  payload: FileListWithWebkitDrafts
}
| {
  type: 'setJobName'
  payload: string
}
| {
  type: 'setSelectedScript'
  payload?: {
    name?: string | null
    workDir?: string
  } | null
}
| {
  type: 'setAvailableCircuits'
  payload: string[]
}
| {
  type: 'setCircuitResourceScales'
  payload: TCircuitResourceScales
}
| {
  type: 'setSelectedCircuits'
  payload: string[]
}
| {
  type: 'jobSubmitted'
}
| {
  type: 'jobCreated'
  payload: { jobId: string }
}
| {
  type: 'error'
  payload?: Error
  preventSubmission?: boolean
}
| {
  type: 'clearPreventSubmission'
}
| {
  type: 'setCustomerId'
  payload: string
}

/**
 * This is a non-standard spec and therefor not in the DOM types
 * See https://developer.mozilla.org/en-US/docs/Web/API/File/webkitRelativePath
 */
export interface FileListWithWebkitDrafts extends FileList {
  item(index: number): TFileWithWebkitDrafts | null
  [index: number]: TFileWithWebkitDrafts
}
interface TFileWithWebkitDrafts extends File {
  webkitRelativePath: string
}

export function isFileListWebkitDraft(fileList: FileList | FileListWithWebkitDrafts): fileList is FileListWithWebkitDrafts {
  const i0 = fileList.item(0)
  return i0 !== null && 'webkitRelativePath' in i0
}

function isAxiosError(err: Error | AxiosError): err is AxiosError {
  return (err as AxiosError).isAxiosError
}

export default function reducer(state: TWorkflowState, action: TWorkflowAction): TWorkflowState {
  const ret: TWorkflowState = { ...state }
  switch(action.type) {
    /**
     * Pure actions that do not affect derrived reducer props. (may return
     * state directly from switch case)
     */
    case 'initialize': return reducerInitializer()
    case 'error': {
      const err = action.payload
      if(err && isAxiosError(err)) {
        if(err.response?.status === 400) {
          err.message = err.response.statusText
          if(err.response.data) {
            if(err.response.data.indexOf('workload pre-job-create failed:') > -1) {
              err.message = `Something is wrong with your request: ${err.response.data.split('workload pre-job-create failed:')[1]}`
            } else {
              err.message += `: "${err.response.data}"`
            }
          }
        }
      }
      return {
        ...state,
        submitting: false,
        error: action.payload,
        preventSubmission: action.preventSubmission,
      }
    }
    case 'clearPreventSubmission':
      return {
        ...state,
        preventSubmission: false,
      }
    case 'jobCreated':
      return ({
        ...state,
        submitting: false,
        jobId: action.payload.jobId,
      })
    case 'jobSubmitted':
      return ({
        ...state,
        submitting: true,
      })
    case 'setJobName':
      return ({
        ...state,
        jobName: action.payload,
      })
    /**
     * Actions that affect derived props (using mutable return)
     */
    case 'setSelectedScript': {
      ret.selectedScript = action.payload?.name || null
      ret.fields.workDir = action.payload?.workDir
      ret.error = undefined
      ret.jobId = undefined
      break
    }
    case 'setAvailableCircuits': {
      ret.availableCircuits = action.payload
      ret.selectedCircuits = []
      ret.error = undefined
      ret.jobId = undefined
      break
    }
    case 'setSelectedCircuits': {
      ret.selectedCircuits = action.payload
      ret.error = undefined
      ret.jobId = undefined
      break
    }
    case 'setCircuitResourceScales': {
      ret.circuitResourceScales = action.payload
      break
    }
    case 'setFiles': {
      const files = action.payload
      if(!isFileListWebkitDraft(files)) {
        throw new Error('Browser does not support files with relative path')
      }
      ret.files = files
      ret.selectedScript = null
      ret.jobId = undefined
      ret.error = undefined
      break
    }
    case 'setCustomerId': {
      ret.customerId = action.payload
      break
    }
    case 'noop': {
      break
    }
    default:
      console.warn('Unknown action type')
      throw new Error()
  }

  /**
   * Derived state
   */
  if(ret.selectedScript) {
    try {
      const ext = getExtension(ret.selectedScript.toLowerCase())
      if(!isExensionSupported(ext)) {
        throw new Error(`No runtime images configured for use with script extension "${ext}"`)
      }
      ret.fields.imageName = extensionImageMap[ext]
      const executable = extensionExecutableMap[ext]
      const cmdBase = [executable, `"${ret.selectedScript}"`]
      if(ret.selectedCircuits && ret.selectedCircuits.length > 0) {
        const commands: typeof ret.fields.commands = []
        const commandResourceScales: Record<string, number> = {}
        ret.selectedCircuits.forEach(circuit => {
          const command = [...cmdBase, circuit].join(' ')
          commands?.push(command)
          if(ret.circuitResourceScales) {
            commandResourceScales[command] = ret.circuitResourceScales[circuit]
          }
        })
        ret.fields.commands = commands
        ret.fields.commandResourceScales = JSON.stringify(commandResourceScales)
      } else {
        ret.fields.commands = [cmdBase.join(' ')]
        ret.fields.commandResourceScales = '{}'
      }
    } catch(err) {
      console.error(err)
      return {
        ...state,
        error: err,
      }
    }
  } else {
    ret.fields.commands = []
  }
  return ret
}

function getExtension(s: string): string {
  const matchGroups = s.toLowerCase().match(/.+\.([a-z0-9]*)?$/)
  if(!matchGroups || matchGroups.length !== 2) {
    throw new Error(`Could not determine runtime image to use for script with name "${s}"`)
  }
  return matchGroups[1]
}
function isExensionSupported(s: string | TSupportedExtensions | undefined | null): s is TSupportedExtensions {
  if(!s) return false
  return Object.keys(extensionImageMap).includes(s)
}

