/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

const Step1 = ({
  problemType, evtFlag, priorCrMax, priorConfidence, priorDataError, priorDataErrorSd, circuit, priorCrMean, priorCrSd, priorMeasErrorMean, priorCrMultiplier, priorMeasErrorMax, priorDataErrorMin, style, censoredMaxOffset, minCmlLossOffset, corrosionRateForm, vtInterval, visualInitial, sigma1SdInitial, sigma1MuInitial, rate2MuInitial, rate2SdInitial, rate1SdInitial, rate0SdInitial, priorMeasErrorSD, consequence_financial, consequence_area, consequence_class, thinning_dm, consequence_basis,
}) => (
  <div className="Step1">
    <div className="cf w-100">
      <p><strong>The following circuit parameters are used for model definition, Bayesian prior probabilities, and JAGS initial conditions. Additional user definable analysis properties can be found in the advanced options tab.</strong></p>
      {problemType === 'CS_OLD'
        ? (
          <div>
            <div className="fl w-100 w-50-ns pa3">
              <E2GTextField style={style} name="circuit" value={circuit} label="Circuit Name" type="text" required />
              <E2GSelectField style={style} name="corrosionRateForm" value={corrosionRateForm} label="Corrosion Rate Form">
                <E2GSelectFieldOption value="Constant" label="Constant" />
                {/* <E2GSelectFieldOption value="Linear" label="Linear" />
              <E2GSelectFieldOption value="Quadratic" label="Quadratic" /> */}
              </E2GSelectField>
              <E2GTextField style={style} name="priorCrMean" value={priorCrMean} label="Prior Corrosion Rate Mean" type="number" required />
              <E2GTextField style={style} name="priorCrSd" value={priorCrSd} label="Prior Corrosion Rate SD" type="number" required />
              <E2GTextField style={style} name="priorCrMultiplier" value={priorCrMultiplier} label="Prior Corrosion Rate Multiplier" type="number" required />
              <E2GTextField style={style} name="rate0SdInitial" value={rate0SdInitial} label="Constant Corrosion Rate SD Initial Value" type="number" required />
              <E2GTextField style={style} name="censoredMaxOffset" value={censoredMaxOffset} label="Censored Thickness Max Offset" type="number" required />
              <E2GTextField style={style} name="minCmlLossOffset" value={minCmlLossOffset} label="Min CML Loss Offset" type="number" required />
              {corrosionRateForm !== 'Constant'
                ? (
                  <div>
                    <E2GTextField style={style} name="rate1SdInitial" value={rate1SdInitial} label="Linear Corrosion Rate SD Initial Value" type="number" required />
                    <E2GTextField style={style} name="rate2MuInitial" value={rate2MuInitial} label="Quadratic Corrosion Rate Mean Initial Value" type="number" required />
                    <E2GTextField style={style} name="rate2SdInitial" value={rate2SdInitial} label="Quadratic Corrosion Rate SD Initial Value" type="number" required />
                  </div>
                )
                : ''}
              <E2GSelectField style={style} name="evtFlag" value={evtFlag} label="Multiple Readings Method">
                <E2GSelectFieldOption value={'censoring'} label="Censoring" />
                <E2GSelectFieldOption value={'evt'} label="EVT" />
              </E2GSelectField>
            </div>
            <div className="fl w-100 w-50-ns pa3">
              <E2GTextField style={style} name="vtInterval" value={vtInterval} label="Visual Inspection Frequency" type="number" required />
              <E2GTextField style={style} name="visualInitial" value={visualInitial} label="Visual Inspection Initial Value" type="number" required />
              <E2GTextField style={style} name="priorMeasErrorMean" value={priorMeasErrorMean} label="Prior Measurement Error Mean" type="number" required />
              <E2GTextField style={style} name="priorMeasErrorMax" value={priorMeasErrorMax} label="Prior Measurement Error Max" type="number" required />
              <E2GTextField style={style} name="priorDataErrorMin" value={priorDataErrorMin} label="Prior Data Error Min (Sigma)" type="number" required />
              <E2GTextField style={style} name="sigma1MuInitial" value={sigma1MuInitial} label="Sigma 1 Mu Initial Value" type="number" required />
              <E2GTextField style={style} name="sigma1SdInitial" value={sigma1SdInitial} label="Sigma 1 SD Initial Value" type="number" required />
            </div>
          </div>
        )
        : <div>
          <div className="fl w-100 w-50-ns pa3">
            <E2GTextField style={style} name="circuit" value={circuit} label="Circuit Name" type="text" required />
            <E2GTextField style={style} name="priorCrMean" value={priorCrMean} label="Prior Corrosion Rate Mean" type="number" required />
            <E2GTextField style={style} name="priorCrSd" value={priorCrSd} label="Prior Corrosion Rate SD" type="number" required />
            {problemType === 'R'
              ? (
                <div>
                  <E2GSelectField style={style} name="thinning_dm" value={thinning_dm} label="Thinning Damage Mechanism">
                    <E2GSelectFieldOption value="5" label="Amine Corrosion" />
                    <E2GSelectFieldOption value="13" label="Ammonium Bisulfide Corrosion" />
                    <E2GSelectFieldOption value="14" label="Ammonium Chloride Corrosion" />
                    <E2GSelectFieldOption value="11" label="Boiler Water/Condensate Corrosion" />
                    <E2GSelectFieldOption value="25" label="Caustic Corrosion" />
                    <E2GSelectFieldOption value="10" label="CO2 Corrosion" />
                    <E2GSelectFieldOption value="1" label="Cooling Water Corrosion - Treated" />
                    <E2GSelectFieldOption value="23" label="Cooling Water Corrosion - Untreated" />
                    <E2GSelectFieldOption value="12" label="Dissolved O2 in Water Corrosion" />
                    <E2GSelectFieldOption value="26" label="Flue Gas Dew Point Corrosion" />
                    <E2GSelectFieldOption value="31" label="HCl Corrosion" />
                    <E2GSelectFieldOption value="8" label="High Temp H2/H2S Corrosion" />
                    <E2GSelectFieldOption value="3" label="High Temp Sulfidation" />
                    <E2GSelectFieldOption value="7" label="Hydrofluoric Acid Corrosion" />
                    <E2GSelectFieldOption value="9" label="Localized Corrosion - Other" />
                    <E2GSelectFieldOption value="4" label="Microbiological Induced Corrosion" />
                    <E2GSelectFieldOption value="15" label="Napthenic Acid Corrosion" />
                    <E2GSelectFieldOption value="27" label="Organic Acid Corrosion" />
                    <E2GSelectFieldOption value="24" label="Sour Water Corrosion (Acidic)" />
                    <E2GSelectFieldOption value="6" label="Sulfuric Acid Corrosion" />
                    <E2GSelectFieldOption value="2" label="Uniform Corrosion - Other" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {/* <E2GSelectField style={style} name="priorConfidence" value={priorConfidence} label="Prior Corrosion Rate Confidence">
              <E2GSelectFieldOption value={'low'} label="Low" />
              <E2GSelectFieldOption value={'moderate'} label="Moderate" />
              <E2GSelectFieldOption value={'high'} label="HIgh" />
            </E2GSelectField> */}
            {problemType === 'CS'
              ? (
                <div>
                  <E2GTextField style={style} name="vtInterval" value={vtInterval} label="Visual Inspection Frequency" type="number" required />
                </div>
              )
              : ''}
          </div>
          <div className="fl w-100 w-50-ns pa3">
            <E2GTextField style={style} name="priorMeasErrorMean" value={priorMeasErrorMean} label="Prior Measurement Error Mean" type="number" required />
            {problemType !== 'R'
              ? (
                <div>
                  <E2GTextField style={style} name="priorMeasErrorSD" value={priorMeasErrorSD} label="Prior Measurement Error SD" type="number" required />
                </div>
              )
              : ''}
            {problemType === 'R'
              ? (
                <div>
                  <E2GTextField style={style} name="priorDataErrorMin" value={priorDataErrorMin} label="Prior Measurement Error Min" type="number" required />
                  <E2GTextField style={style} name="priorMeasErrorMax" value={priorMeasErrorMax} label="Prior Measurement Error Max" type="number" required />
                  <E2GTextField style={style} name="priorCrMax" value={priorCrMax} label="Prior CR Max" type="number" required />
                  {consequence_basis === 'financial'
                    ? (
                      <E2GTextField style={style} name="consequence_financial" value={consequence_financial} label="Financial Consequence" type="number" required />
                    )
                    : <div>
                      <input type="hidden" name="consequence_area" value={0} />
                      <input type="hidden" name="consequence_class" value={'C'} />
                    </div>}
                  {consequence_basis === 'area'
                    ? (
                      <E2GTextField style={style} name="consequence_area" value={consequence_area} label="Area Consequence" type="number" required />
                    )
                    : <div>
                      <input type="hidden" name="consequence_financial" value={0} />
                      <input type="hidden" name="consequence_class" value={'C'} />
                    </div>}
                  {consequence_basis === 'category'
                    ? (
                      <E2GSelectField style={style} name="consequence_class" value={consequence_class} label="Consequence Class">
                        <E2GSelectFieldOption value="A" label="A" />
                        <E2GSelectFieldOption value="B" label="B" />
                        <E2GSelectFieldOption value="C" label="C" />
                        <E2GSelectFieldOption value="D" label="D" />
                        <E2GSelectFieldOption value="E" label="E" />
                      </E2GSelectField>
                    )
                    : <div>
                      <input type="hidden" name="consequence_area" value={0} />
                      <input type="hidden" name="consequence_financial" value={0} />
                    </div>}
                </div>
              )
              : ''}
            {problemType === 'CS'
              ? (
                <div>
                  <E2GTextField style={style} name="priorDataError" value={priorDataError} label="Prior Data Error Mean" type="number" required />
                  <E2GTextField style={style} name="priorDataErrorSd" value={priorDataErrorSd} label="Prior Data Error SD" type="number" required />
                </div>
              )
              : ''}
            {problemType !== 'R'
              ? (
                <E2GSelectField style={style} name="evtFlag" value={evtFlag} label="Multiple Readings Method">
                  <E2GSelectFieldOption value={'censoring'} label="Censoring" />
                  <E2GSelectFieldOption value={'evt'} label="EVT" />
                </E2GSelectField>
              )
              : ''}
            {evtFlag === 'censoring'
              ? (
                <E2GTextField style={style} name="censoredMaxOffset" value={censoredMaxOffset} label="Censored Thickness Max Offset" type="number" required />
              )
              : ''}
          </div>
        </div>}
    </div>
    <Submit label="Next" />
  </div>
)
Step1.propTypes = {
  consequence_basis: PropTypes.string,
  thinning_dm: PropTypes.number,
  consequence_financial: PropTypes.number,
  consequence_area: PropTypes.number,
  consequence_class: PropTypes.string,
  priorMeasErrorSD: PropTypes.number,
  problemType: PropTypes.string,
  priorDataError: PropTypes.number,
  priorDataErrorSd: PropTypes.number,
  priorConfidence: PropTypes.string,
  evtFlag: PropTypes.string,
  circuit: PropTypes.string,
  censoredMaxOffset: PropTypes.number,
  minCmlLossOffset: PropTypes.number,
  vtInterval: PropTypes.number,
  visualInitial: PropTypes.number,
  sigma1SdInitial: PropTypes.number,
  sigma1MuInitial: PropTypes.number,
  rate2MuInitial: PropTypes.number,
  rate2SdInitial: PropTypes.number,
  rate1SdInitial: PropTypes.number,
  rate0SdInitial: PropTypes.number,
  corrosionRateForm: PropTypes.string,
  priorCrMean: PropTypes.number,
  priorCrSd: PropTypes.number,
  priorMeasErrorMean: PropTypes.number,
  // priorMeasErrorMin: PropTypes.number,
  priorMeasErrorMax: PropTypes.number,
  priorDataErrorMin: PropTypes.number,
  priorCrMultiplier: PropTypes.number,
  // groupNipples: PropTypes.number,
  style: PropTypes.object,
  priorCrMax: PropTypes.number,
}
const mapStateToProps = state => ({
  consequence_basis: state.workflow.fields.consequence_basis,
  thinning_dm: state.workflow.fields.thinning_dm,
  consequence_financial: state.workflow.fields.consequence_financial,
  consequence_area: state.workflow.fields.consequence_area,
  consequence_class: state.workflow.fields.consequence_class,
  priorMeasErrorSD: state.workflow.fields.priorMeasErrorSD,
  problemType: state.workflow.fields.problemType,
  priorDataError: state.workflow.fields.priorDataError,
  priorDataErrorSd: state.workflow.fields.priorDataErrorSd,
  priorConfidence: state.workflow.fields.priorConfidence,
  evtFlag: state.workflow.fields.evtFlag,
  circuit: state.workflow.fields.circuit,
  censoredMaxOffset: state.workflow.fields.censoredMaxOffset,
  minCmlLossOffset: state.workflow.fields.minCmlLossOffset,
  vtInterval: state.workflow.fields.vtInterval,
  visualInitial: state.workflow.fields.visualInitial,
  sigma1SdInitial: state.workflow.fields.sigma1SdInitial,
  sigma1MuInitial: state.workflow.fields.sigma1MuInitial,
  rate2MuInitial: state.workflow.fields.rate2MuInitial,
  rate2SdInitial: state.workflow.fields.rate2SdInitial,
  rate1SdInitial: state.workflow.fields.rate1SdInitial,
  rate0SdInitial: state.workflow.fields.rate0SdInitial,
  priorCrMultiplier: state.workflow.fields.priorCrMultiplier,
  corrosionRateForm: state.workflow.fields.corrosionRateForm,
  priorCrMean: state.workflow.fields.priorCrMean,
  priorCrSd: state.workflow.fields.priorCrSd,
  priorMeasErrorMean: state.workflow.fields.priorMeasErrorMean,
  // priorMeasErrorMin: state.workflow.fields.priorMeasErrorMin,
  priorMeasErrorMax: state.workflow.fields.priorMeasErrorMax,
  priorDataErrorMin: state.workflow.fields.priorDataErrorMin,
  // groupNipples: state.workflow.fields.groupNipples,
  style: state.ui.style,
  priorCrMax: state.workflow.fields.priorCrMax,
})
export default connect(mapStateToProps, null)(Step1)
