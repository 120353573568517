import CYLINDER from './CYLINDER.png'
import CYU from './CYU.png'
import CYUID from './CYUID.png'
import RectangularAttachment from './RectangularAttachment.png'
import RectangularAttachment2 from './RectangularAttachment2.png'
import RoundAttachment from './RoundAttachment.png'
import RoundAttachment2 from './RoundAttachment2.png'
import shell from './shell.png'
import SPD from './SPD.png'
import SPHERE from './SPHERE.png'
import SquareAttachment from './SquareAttachment.png'
import SquareAttachment2 from './SquareAttachment2.png'

export {
  CYLINDER,
  CYU,
  CYUID,
  RectangularAttachment,
  RectangularAttachment2,
  RoundAttachment,
  RoundAttachment2,
  shell,
  SPD,
  SPHERE,
  SquareAttachment,
  SquareAttachment2
}
