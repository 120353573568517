import { Reducer } from 'react'
import { AsyncActionHandlers } from 'use-reducer-async'

import { circuitSort } from '../lib/circuitSort'

import { getCircuitsFromFiles } from './getCircuitsFromFiles'
import { isFileListWebkitDraft, TWorkflowAction, TWorkflowActionAsync, TWorkflowState } from './reducer'

export const asyncActionHandlers: AsyncActionHandlers<Reducer<TWorkflowState, TWorkflowAction>, TWorkflowActionAsync> = {
  changedFiles: ({ dispatch }) => async (action) => {
    const fileList = action.payload
    if(!isFileListWebkitDraft(fileList)) {
      throw new Error('Browser does not support files with relative path')
    }
    dispatch({ type: 'initialize' })
    dispatch({ type: 'setFiles', payload: fileList })
    try {
      const { circuits, resourceScales, customerId } = await getCircuitsFromFiles(fileList)
      dispatch({ type: 'setAvailableCircuits', payload: circuits.sort(circuitSort) })
      dispatch({ type: 'setCircuitResourceScales', payload: resourceScales })
      dispatch({ type: 'setCustomerId', payload: customerId })
    } catch(err) {
      dispatch({ type: 'error', payload: err })
    }
  },
}
