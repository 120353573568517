/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

import { getAcfCrGraph } from '../lib/getResultCases.js'

const RAcfCorrosionRates = ({ results, circuit, acfCorrosionRates }) => {
  const graphAcfData = getAcfCrGraph(results.acfCrPages, results.acfCrLabels, results.acfCrColors)

  const jsonObject = JSON.parse(results.acfCrPages[0])
  const keys = Object.keys(jsonObject)

  const labels = JSON.parse(results.acfCrLabels)
  const colors = JSON.parse(results.acfCrColors)
  const pageCount = jsonObject.pages.length

  return (
    <div className="cf w-100">
      <div>
        <b><i>{circuit}</i> ACF Group Corrosion Rates</b>
        <DataTableDisplay id="acfCorrosionRatesTable" table={acfCorrosionRates} containerHeight="100px" containerWidth="100%" />
      </div>

      <br /><br />
      <div className="cf w-100">
        <DataGraphDisplay graphs={graphAcfData} />
      </div>

      <div>
        <br/>
        <b>B-Values represent the credible interval levels for the corrosion rates. </b>
        <i>B50 = Median;  B75 = 3rd Quartile; B90, B95, B99 are the 90th, 95th, and 99th Precentiles Respectively</i>
      </div>

    </div>
  )
}

RAcfCorrosionRates.propTypes = {
  results: PropTypes.object,
  circuit: PropTypes.string,
  acfCorrosionRates: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  circuit: state.workflow.fields.circuit,
  acfCorrosionRates: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.acfCorrosionRates : undefined,
})

export default connect(mapStateToProps, null)(RAcfCorrosionRates)
