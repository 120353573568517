import React, { createContext } from 'react'
import { useReducerAsync } from 'use-reducer-async'

import params from './params'
import reducer, { asyncActionHandlers, reducerInitializer, TWorkflowAction, TWorkflowState } from './reducer'

export const WorkflowStateContext = createContext<TWorkflowState>(reducerInitializer([]))

export const WorkflowDispatchContext = createContext<React.Dispatch<TWorkflowAction>>(() => {})

function WorkflowProvider(props: { children: React.ReactNode, initialState: TWorkflowState}) {
  const {
    children,
    initialState,
  } = props
  const defaults = reducerInitializer(params)
  const init = Object.assign({}, defaults, initialState)
  const [state, dispatch] = useReducerAsync(reducer, init, asyncActionHandlers)
  return (
    <WorkflowStateContext.Provider value={state}>
      <WorkflowDispatchContext.Provider value={dispatch}>
        {children}
      </WorkflowDispatchContext.Provider>
    </WorkflowStateContext.Provider>
  )
}

export default WorkflowProvider
