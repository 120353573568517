import React, { useCallback, useContext } from 'react'
import { Alert, Box, Collapse, LinearProgress, Typography } from '@mui/material'

import TransferList from '@e2grnd/ui-core/dist/components/TransferList'

import { scaleLinear } from '../lib/scaleLinear'
import { WorkflowDispatchContext, WorkflowStateContext } from '../WorkflowProvider'

export type TProps = {
  maxSelectedCircuits?: number
}
const defaultMaxSelectedCircuits = 500
const defaultMachineType = 'o3'

// const formatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0, useGrouping: true });

export const StepSelectCircuits = (props: TProps) => {
  const {
    maxSelectedCircuits: max = defaultMaxSelectedCircuits,
  } = props
  const {
    availableCircuits = [],
    selectedCircuits,
    circuitResourceScales,
    error,
  } = useContext(WorkflowStateContext)
  const dispatch = useContext(WorkflowDispatchContext)

  const setSelectedCircuits = (v: string[]) => {
    dispatch({ type: 'setSelectedCircuits', payload: v.slice(0, max) })
    if(v.length > max) {
      dispatch({
        type: 'error',
        payload: new Error(`Max number of circuits exceeded. Selected circuits have been truncated to ${max}.`),
      })
    }
  }

  const getResourceScaleByCircuit = useCallback((circuitName: string): React.ReactNode => {
    if(!circuitResourceScales) return undefined
    const num = circuitResourceScales[circuitName]
    if(num === 0) return undefined
    const estRam = scaleLinear([0, 1], [4, 512], num)
    const title = `This circuit will request ~${estRam.toFixed(0)}GB RAM (${(num * 100).toFixed(0)}% of the maximum available resources)`
    return <LinearProgress variant="determinate" value={num * 100} title={title} />
    // return `+${formatter.format(num * 100)}%`;
  }, [circuitResourceScales])

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography paragraph style={{ textAlign: 'center' }}>Select which circuits to scope the analysis to.</Typography>
      {/* temporarily removed because cost estimate was not accurate. see RD-1083 */}
      {/* <Typography style={{ textAlign: 'center' }}>Estimated Cost:</Typography>
      <EstimatedCost style={{ textAlign: 'center' }} /> */}
      <TransferList
        value={selectedCircuits || []}
        options={availableCircuits}
        onChange={setSelectedCircuits}
        leftProps={{
          title: 'Available Circuits',
          disabled: selectedCircuits && selectedCircuits.length >= max,
          listItemTextProps: (circuitName: string) => ({
            secondary: getResourceScaleByCircuit(circuitName),
          }),
        }}
        rightProps={{
          title: `Selected Circuits (max ${max})`,
          listItemTextProps: (circuitName: string) => ({
            secondary: getResourceScaleByCircuit(circuitName),
          }),
        }}
      />
      <Collapse in={Boolean(error)}>
        <Box pb={2}>
          <Alert severity="error">{error?.message}</Alert>
        </Box>
        <Box pb={2}>
          <Alert severity="info">Try splitting up large spreadsheet into multiple smaller spreadsheets.</Alert>
        </Box>
      </Collapse>
    </Box>
  )
}

export default StepSelectCircuits

StepSelectCircuits.defaultProps = {
  maxSelectedCircuits: defaultMaxSelectedCircuits,
  machineType: defaultMachineType,
}
