import React, { MouseEvent } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material'
import { navigate } from 'gatsby'

import Logo from '@e2grnd/ui-core/dist/components/Logo/Logo'
import { useAuthContext } from '@e2grnd/ui-core/dist/contexts/AuthProvider'

import { PageFrontmatterOptions } from 'types/page-frontmatter'

export default function Home() {
  const { isSignedIn, signinRedirect } = useAuthContext({ noThrow: true })
  const handleSignInClick = (e: MouseEvent) => {
    e.preventDefault()
    if(isSignedIn) {
      navigate('/app')
    } else {
      signinRedirect()
    }
  }
  return (
    <Box sx={{ background: 'linear-gradient(180deg, #f9f9fa 80%, #ffffff 100%)' }}>
      <Helmet title="Equity Engineering Cloud" />
      <Paper
        variant="cobalt"
        sx={{
          borderRadius: 0,
          width: '100vw',
          p: 6,
        }}
      >
        <Container maxWidth="md">
          <Stack spacing={4} alignItems="center">
            <Logo alt="The Equity Engineer Cloud" style={{ width: 'auto', height: 60 }} />
            <Typography variant="body1"
              component="p"
              fontWeight="bold"
              fontSize="22px"
              textAlign="center"
            >
              Distributed Computing Tools
            </Typography>
            <Stack direction="row" spacing={4}>
              <Button variant="petra" size="large" sx={{ width: '12rem' }} onClick={handleSignInClick} component="a" href="/app">Log In</Button>
            </Stack>
          </Stack>
        </Container>
      </Paper>
    </Box>
  )
}

export const frontmatter: PageFrontmatterOptions = {
  layout: {
    noDrawer: true,
    noTopNav: true,
  },
}
