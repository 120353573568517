import React from 'react'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Link } from '@reach/router'

import Logo from '@e2grnd/ui-core/dist/components/Logo'

const Root = styled('div')({
  height: '100vh',
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
  marginRight: 'auto',
  flexDirection: 'column',
  justifyContent: 'center',
})

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(8),
  maxWidth: '34em',
  alignSelf: 'center',
  lineHeight: 1.5,
}))

const StyledLogo = styled('div')({
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export default function PageNotFound() {
  return (
    <Root>
      <StyledPaper>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <Typography variant="h6" color="textPrimary">
          The page you are looking for can not be found. <br />Please visit the <Link to={process.env.REACT_APP_HOME}>EEC Home Page</Link> and try again.
        </Typography>
      </StyledPaper>
    </Root>
  )
}
