import Cylinder from './Cylinder.png'
import CylinderProfile from './CylinderProfile.png'
import RegionalLaborProductivityFactor from './RegionalLaborProductivityFactorFr.png'
import Sphere from './Sphere.png'
import SphereProfile from './SphereProfile.png'

export {
  Cylinder,
  CylinderProfile,
  RegionalLaborProductivityFactor,
  Sphere,
  SphereProfile
}
