import React from 'react'

import AcfError from './AcfError'
import AnalysisSummary from './AnalysisSummary'
import AutoCorrelationPerChain from './AutoCorrelationPerChain'
import AvgDensity from './AvgDensity'
import BoxWhisker from './BoxWhisker'
import CmlError from './CmlError'
import CorrosionRates from './CorrosionRates'
import Density from './Density'
import GelmanRubin from './GelmanRubin'
import MetalLossProjections from './MetalLossProjections'
import POF from './POF'
import RAcfCorrosionRates from './RAcfCorrosionRates'
import RCircuitCorrosionRates from './RCircuitCorrosionRates'
import RCmlCorrosionRates from './RCmlCorrosionRates'
import Residual from './Residual'
// import RunningMean from './RunningMean';
import RunningMeanPerChain from './RunningMeanPerChain'
import ThicknessProjections from './ThicknessProjections'
// import Trace from './Trace';
import TracePerChain from './TracePerChain'

export default [
  {
    name: 'Prop0',
    title: 'Corrosion Rate Plots',
    component: <BoxWhisker />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop1',
    title: 'Thickness Projections',
    component: <ThicknessProjections />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop2',
    title: 'Metal Loss Projections',
    component: <MetalLossProjections />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop9',
    title: 'POF Projections',
    component: <POF />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop3',
    title: 'Density Plots',
    component: <AvgDensity />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop4',
    title: 'Density Plots per Chain',
    component: <Density />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop5',
    title: 'Trace Plots Per Chain',
    component: <TracePerChain />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop6',
    title: 'Running Mean Per Chain',
    component: <RunningMeanPerChain />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop7',
    title: 'Gelman Rubin',
    component: <GelmanRubin />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop8',
    title: 'Auto Correlation Per Chain',
    component: <AutoCorrelationPerChain />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop9',
    title: 'Residual',
    component: <Residual />,
    predicate: fields => fields.problemType !== 'R',
  },
  {
    name: 'Prop10',
    title: 'CML Error',
    component: <CmlError />,
    predicate: fields => fields.problemType === 'R',
  },
  {
    name: 'Prop11',
    title: 'ACF Error',
    component: <AcfError />,
    predicate: fields => fields.problemType === 'R',
  },
  {
    name: 'Prop12',
    title: 'Corrosion Rate Data',
    component: <CorrosionRates />,
    predicate: fields => fields.problemType === 'R',
  },
  {
    name: 'Prop13',
    title: 'Circuit Corrosion Rate Plot/Data',
    component: <RCircuitCorrosionRates />,
    predicate: fields => fields.problemType === 'R',
  },
  {
    name: 'Prop14',
    title: 'ACF Corrosion Rate Plots/Data',
    component: <RAcfCorrosionRates />,
    predicate: fields => fields.problemType === 'R',
  },
  {
    name: 'Prop15',
    title: 'CML Circuit Corrosion Rate Plot/Data',
    component: <RCmlCorrosionRates />,
    predicate: fields => fields.problemType === 'R',
  },
  {
    name: 'Prop16',
    title: 'Analysis Parameter Summary',
    component: <AnalysisSummary />,
    predicate: fields => fields.problemType === 'R',
  },
]
