import React, { ReactNode, useContext, useMemo } from 'react'
import { Typography, TypographyProps } from '@mui/material'

import { scaleLinear } from '../lib/scaleLinear'
import { WorkflowStateContext } from '../WorkflowProvider'

type TProps = {
} & TypographyProps

const currencyFormatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' })

const measurementCostScale = (resourceScale: number): number => {
  const domain: [number, number] = [0, 1]
  const hourlyCostRange: [number, number] = [0.020446, 0.654272]
  return scaleLinear(domain, hourlyCostRange, resourceScale)
}

export const EstimatedCost = (props: TProps) => {
  const {
    circuitResourceScales,
    selectedCircuits,
  } = useContext(WorkflowStateContext)

  const $cost: ReactNode = useMemo(() => {
    if(!circuitResourceScales || !selectedCircuits) {
      return '?'
    }
    const estimatedCost = selectedCircuits.length === 0
      ? measurementCostScale(0)
      : selectedCircuits.reduce((acc, circ) => {
        let ret = acc
        ret += measurementCostScale(circuitResourceScales[circ] || 0)
        return ret
      }, 0)
    // Assume linear correlation between # measurements and machine cost
    return estimatedCost ? <strong>{`${currencyFormatter.format(estimatedCost)}/hr`}</strong> : '-'
  }, [circuitResourceScales, selectedCircuits])

  return (
    <Typography {...props}>{$cost}</Typography>
  )
}
