import { useContext } from 'react'

import { WorkflowDispatchContext } from '../WorkflowProvider'

function useField() {
  const dispatch = useContext(WorkflowDispatchContext)

  const handleChange = (e) => {
    const field = e.target
    switch(field.type) {
      case 'file':
        dispatch({
          type: 'changedFiles',
          payload: field.files,
        })
        break
      case 'checkbox':
        dispatch({
          type: 'toggledField',
          payload: {
            name: field.name,
          },
        })
        break
      default:
        dispatch({
          type: 'changedField',
          payload: {
            [field.name]: field.value,
          },
        })
    }
  }
  const handleSelect = (e) => {
    dispatch({
      type: 'changedArrayField',
      payload: {
        name: e.target.name,
        value: e.target.value,
      },
    })
  }
  return {
    handleChange,
    handleSelect,
  }
}

export default useField
