import ASMECS from './ASMECS.png'
import ASMESS from './ASMESS.png'
import RambergOsgood from './RambergOsgood.png'
import SSMAlTi from './SSMAlTi.png'
import SSMLife from './SSMLife.png'
import SSMSteel from './SSMSteel.png'

export {
  ASMECS,
  ASMESS,
  RambergOsgood,
  SSMAlTi,
  SSMLife,
  SSMSteel
}
