import CO from './CO.png'
import COU from './COU.png'
import CY from './CY.png'
import CYUID from './CYUID.png'
import EL from './EL.png'
import PE from './PE.png'
import SP from './SP.png'
import TO from './TO.png'

export {
  CO,
  COU,
  CY,
  CYUID,
  EL,
  PE,
  SP,
  TO
}
