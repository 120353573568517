import CCEMBEDDED from './CCEMBEDDED.png'
import CCNWEMBEDDED from './CCNWEMBEDDED.png'
import CCNWSURFACE from './CCNWSURFACE.png'
import CCNWTHROUGH from './CCNWTHROUGH.png'
import CCPWEMBEDDED from './CCPWEMBEDDED.png'
import CCPWSURFACE from './CCPWSURFACE.png'
import CCPWTHROUGH from './CCPWTHROUGH.png'
import CCSURFACE from './CCSURFACE.png'
import CCTHROUGH from './CCTHROUGH.png'
import CY from './CY.png'
import CYU from './CYU.png'
import CYUID from './CYUID.png'
import CYUL from './CYUL.png'
import embeddedDegrees from './embeddedDegrees.png'
import LCEMBEDDED from './LCEMBEDDED.png'
import LCNWEMBEDDED from './LCNWEMBEDDED.png'
import LCNWSURFACE from './LCNWSURFACE.png'
import LCNWTHROUGH from './LCNWTHROUGH.png'
import LCPWEMBEDDED from './LCPWEMBEDDED.png'
import LCPWSURFACE from './LCPWSURFACE.png'
import LCPWTHROUGH from './LCPWTHROUGH.png'
import LCSURFACE from './LCSURFACE.png'
import LCTHROUGH from './LCTHROUGH.png'
import sccCrackSeverityTable from './sccCrackSeverityTable.png'
import siteFactorsTable from './siteFactorsTable.png'
import SP from './SP.png'
import SPU from './SPU.png'
import surfaceDegrees from './surfaceDegrees.png'
import weldingProcessSI from './weldingProcessSI.png'
import weldingProcessUS from './weldingProcessUS.png'

export {
  CCEMBEDDED,
  CCNWEMBEDDED,
  CCNWSURFACE,
  CCNWTHROUGH,
  CCPWEMBEDDED,
  CCPWSURFACE,
  CCPWTHROUGH,
  CCSURFACE,
  CCTHROUGH,
  CY,
  CYU,
  CYUID,
  CYUL,
  embeddedDegrees,
  LCEMBEDDED,
  LCNWEMBEDDED,
  LCNWSURFACE,
  LCNWTHROUGH,
  LCPWEMBEDDED,
  LCPWSURFACE,
  LCPWTHROUGH,
  LCSURFACE,
  LCTHROUGH,
  sccCrackSeverityTable,
  siteFactorsTable,
  SP,
  SPU,
  surfaceDegrees,
  weldingProcessSI,
  weldingProcessUS
}
