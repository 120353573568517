import React from 'react'

import SilentOidcCallback from '@e2grnd/ui-core/dist/contexts/AuthProvider/SilentOidcCallback'

import { PageFrontmatterOptions } from 'types/page-frontmatter'

export default function LocalOidcCallback() {
  return (
    <SilentOidcCallback />
  )
};
export const frontmatter: PageFrontmatterOptions = {
  bare: true,
}
