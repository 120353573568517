// THIS FILE IS GENERATED BY: plugins/calculator-meta/generate-page-template.ts
import React from 'react'
import NoSsr from '@mui/material/NoSsr'

import DegradationAnalysisProvider from '../providers/DegradationAnalysisProvider'

export default function DegradationAnalysisPage() {
  return (
    <NoSsr>
      <DegradationAnalysisProvider />
    </NoSsr>
  )
}
