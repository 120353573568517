export default [{
  expression: (problemType, accessControl, credentials, activeCalculator) => {
    return problemType !== 'SIMP' ? [{
      name: "weightBaseValue",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "weightMaxValue",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "weightMidValue",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "weightSteepnessValue",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "nextTaDate",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "turnaroundFrequency",
      required: true,
      type: "CUSTOM_FIELD"
    }] : [{
      name: "nextTaDate",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "turnaroundFrequency",
      required: true,
      type: "CUSTOM_FIELD"
    }];
  },
  testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
}, {
  expression: (problemType, consequence_basis, accessControl, credentials, activeCalculator) => {
    return problemType === 'R' ? [{
      expression: (consequence_basis, accessControl, credentials, activeCalculator) => {
        return consequence_basis === 'financial' ? [{
          name: "financial_risk_action_level",
          required: true,
          type: "CUSTOM_FIELD"
        }] : [{
          name: "consequence_area_risk_action_level",
          required: false,
          type: "HIDDEN_FIELD"
        }, {
          name: "pof_risk_action_target",
          required: false,
          type: "HIDDEN_FIELD"
        }];
      },
      testIdentifiers: ["consequence_basis", "accessControl", "credentials", "activeCalculator"]
    }, {
      expression: (consequence_basis, accessControl, credentials, activeCalculator) => {
        return consequence_basis === 'area' ? [{
          name: "consequence_area_risk_action_level",
          required: true,
          type: "CUSTOM_FIELD"
        }] : [{
          name: "financial_risk_action_level",
          required: false,
          type: "HIDDEN_FIELD"
        }, {
          name: "pof_risk_action_target",
          required: false,
          type: "HIDDEN_FIELD"
        }];
      },
      testIdentifiers: ["consequence_basis", "accessControl", "credentials", "activeCalculator"]
    }, {
      expression: (consequence_basis, accessControl, credentials, activeCalculator) => {
        return consequence_basis === 'category' ? [{
          name: "pof_risk_action_target",
          required: true,
          type: "CUSTOM_FIELD"
        }] : [{
          name: "consequence_area_risk_action_level",
          required: false,
          type: "HIDDEN_FIELD"
        }, {
          name: "financial_risk_action_level",
          required: false,
          type: "HIDDEN_FIELD"
        }];
      },
      testIdentifiers: ["consequence_basis", "accessControl", "credentials", "activeCalculator"]
    }, {
      name: "use_unmonitored_comps",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "use_piecewise_rates_flag",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "use_linear_rates_flag",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "use_weighted_readings",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "model_run_type",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "use_convergence",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "accepted_measurement_threshold",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "measurement_error_noisy_threshold",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "measurement_error_noise_quantile",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "project_with_meas_err",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "consequence_basis",
      required: false,
      type: "CUSTOM_FIELD"
    }] : [];
  },
  testIdentifiers: ["problemType", "consequence_basis", "accessControl", "credentials", "activeCalculator"]
}, {
  expression: (problemType, accessControl, credentials, activeCalculator) => {
    return problemType !== 'SIMP' ? [{
      name: "censoredReadingsTable",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "errorMultipliersTable",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "pipeScheduleTable",
      required: false,
      type: "CUSTOM_FIELD"
    }] : "";
  },
  testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
}];