/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import find from 'lodash/find'
import PropTypes from 'prop-types'

import Dialog from '@e2grnd/ui-core/dist/components/ImageDialog'
import Close from '@e2grnd/ui-core/dist/components/ImageDialogClose'
import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'
import withConfig from '@e2grnd/ui-core/dist/contexts/withConfig'
import { getColumnFrom2DArray } from '@e2grnd/ui-core/dist/core/lib'

import { corrSolutions } from '../../../images'
import { checkTableEmpty } from '../lib/checkTables.js'

class Step3 extends Component {
  static propTypes = {
    problemType: PropTypes.string,
    evtFlag: PropTypes.string,
    cmlDataAndLabelsTable: PropTypes.array,
    cmlDataAndLabelsTable2: PropTypes.array,
    cmlDataAndLabelsTable3: PropTypes.array,
    cmlMeasurementThicknessTable: PropTypes.array,
    cmlMeasurementThicknessTable2: PropTypes.array,
    config: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const {
      problemType,
      evtFlag,
      cmlDataAndLabelsTable,
      cmlDataAndLabelsTable2,
      cmlDataAndLabelsTable3,
      cmlMeasurementThicknessTable,
      cmlMeasurementThicknessTable2,
      config,
    } = this.props
    let tableData
    if((problemType !== 'SIMP' && evtFlag === 'evt') || problemType === 'R') {
      tableData = find(config.dataTables, ['name', 'cmlMeasurementThicknessTable2'])
    } else {
      tableData = find(config.dataTables, ['name', 'cmlMeasurementThicknessTable'])
    }
    let col0 = []
    if(problemType !== 'SIMP' && problemType !== 'R') {
      col0 = getColumnFrom2DArray(cmlDataAndLabelsTable, 0)
    } else if(problemType === 'SIMP') {
      col0 = getColumnFrom2DArray(cmlDataAndLabelsTable2, 0)
    } else {
      col0 = getColumnFrom2DArray(cmlDataAndLabelsTable3, 0)
    }
    const cmlIds = []
    col0.forEach((cmlId) => {
      if(!cmlIds.includes(cmlId)) {
        if(cmlId !== null && cmlId !== '' && typeof cmlId !== 'undefined') {
          cmlIds.push(cmlId)
        }
      }
    })
    // eslint-disable-next-line no-param-reassign
    tableData.options.columns[0].source = cmlIds
    // eslint-disable-next-line no-param-reassign
    if(problemType !== 'SIMP') {
      tableData.options.columns[3].source = ['UT', 'RT', 'NT']
    } else {
      tableData.options.columns[3].source = ['UT', 'RT', 'VT']
    }
    let table2StatusEmpty
    if((problemType !== 'SIMP' && evtFlag === 'evt') || problemType === 'R') {
      table2StatusEmpty = checkTableEmpty(cmlMeasurementThicknessTable2, 1)
    } else {
      table2StatusEmpty = checkTableEmpty(cmlMeasurementThicknessTable, 0)
    }
    return (
      <div className="Step3">
        <p><strong>The CML-ID column is prepopulated with the unique CML-IDs from the Step 2 CML table. The inspection types column is prepopulated with available inspection methods. If the EVT method was selected in the circuit settings, use the table and graph in the modal dialog via the button below to determine the effective number of readings.</strong></p>
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.toggleOpen()}
        >
          EVT Effective<br/>Number of Readings
        </Button>
        <Dialog
          title={<div><span className="fr"><Close className="dim" onClick={() => this.toggleOpen()} /></span></div>}
          modal={false}
          open={this.state.open}
          fullWidth
        >
          <p><strong>Effective Number of Readings for an expected minimum thickness area ratio (rows) for a given level of confidence or inspection effectiveness (columns). For example, if the corrosion was perfectly uniform (i.e., coverage are fraction = 99%), RT with 50% confidence would be treated as 1 effective reading.</strong></p>
          <img src={corrSolutions.evtTableImage} alt="" />
          <p><strong>Effective Number of Readings corresponding plot for Number of Readings vs Area Ratio for each confidence level.</strong></p>
          <img src={corrSolutions.evtGraphImage} alt="" />
        </Dialog>
        {problemType !== 'SIMP' && problemType !== 'R'
          ? (
            <div>
              <p>The TML History Number is required to handle replacements. Number 0 is the latest component, number 1 is the last replacement, number 2 is the second to last replacement, and so on. Baseline inspections should be entered in this table specifically for each CML and replacement if the user wants them incorporated into the analysis with the inspection method set to NT.</p>
              {evtFlag === 'evt'
                ? (
                  <DataTableField id="cmlMeasurementThicknessTable2" name="cmlMeasurementThicknessTable2" value={cmlMeasurementThicknessTable2} startRows={4} colHeaders={{ USCustomary: ['CML ID<br />(Select)*', 'Thickness Reading (in)*', 'Measurement Date<br />(MM-DD-YYYY)*', 'Inspection Type<br />(Select)*', 'TML History Index*', 'Number of<br />Readings*'], Metric: ['CML ID<br />(Select)*', 'Thickness Reading (mm)*', 'Measurement Date<br />(MM-DD-YYYY)*', 'Inspection Type<br />(Select)*', 'TML History Index*', 'Number of<br />Readings*'] }} rowHeaders={false} />
                )
                : <DataTableField id="cmlMeasurementThicknessTable" name="cmlMeasurementThicknessTable" value={cmlMeasurementThicknessTable} startRows={4} colHeaders={{ USCustomary: ['CML ID<br />(Select)*', 'Thickness Reading (in)*', 'Measurement Date<br />(MM-DD-YYYY)*', 'Inspection Type<br />(Select)*', 'TML History Index*'], Metric: ['CML ID<br />(Select)*', 'Thickness Reading (mm)*', 'Measurement Date<br />(MM-DD-YYYY)*', 'Inspection Type<br />(Select)*', 'TML History Index*'] }} rowHeaders={false} />}
            </div>
          )
          : ''}
        {problemType === 'SIMP'
          ? (
            <div>
              <p>Note, the number of readings per inspection is a required column. NT is not available. Instead UT/RT at the time of install can be included if it was performed. Also, VT is added explicitly as an inspection technique.</p>
              <DataTableField id="cmlMeasurementThicknessTable" name="cmlMeasurementThicknessTable" value={cmlMeasurementThicknessTable} startRows={4} colHeaders={{ USCustomary: ['CML ID<br />(Select)*', 'Thickness Reading (in)*', 'Measurement Date<br />(MM-DD-YYYY)*', 'Inspection Type<br />(Select)*', 'Number of<br />Readings*'], Metric: ['CML ID<br />(Select)*', 'Thickness Reading (mm)*', 'Measurement Date<br />(MM-DD-YYYY)*', 'Inspection Type<br />(Select)*', 'Number of<br />Readings*'] }} rowHeaders={false} />
            </div>
          )
          : ''}
        {problemType === 'R'
          ? (
            <div>
              <p>The TML History Number is required to handle replacements. Number 0 is the latest component, number 1 is the last replacement, number 2 is the second to last replacement, and so on. Baseline inspections should be entered in this table specifically for each CML and replacement if the user wants them incorporated into the analysis with the inspection method set to NT.</p>
              <DataTableField id="cmlMeasurementThicknessTable2" name="cmlMeasurementThicknessTable2" value={cmlMeasurementThicknessTable2} startRows={4} colHeaders={{ USCustomary: ['CML ID<br />(Select)*', 'Thickness Reading (in)*', 'Measurement Date<br />(MM-DD-YYYY)*', 'Inspection Type<br />(Select)*', 'TML History Index*', 'Number of<br />Readings*'], Metric: ['CML ID<br />(Select)*', 'Thickness Reading (mm)*', 'Measurement Date<br />(MM-DD-YYYY)*', 'Inspection Type<br />(Select)*', 'TML History Index*', 'Number of<br />Readings*'] }} rowHeaders={false} />
            </div>
          )
          : ''}
        { table2StatusEmpty === false
          ? (
            <Submit label="Calculate" />
          )
          : <div className="pa3">
            <font color="red">Table is invalid. Please ensure the CML-ID, measurement date, and thickness reading are provided for each added row.</font>
            <Submit label="Calculate" disabled />
          </div>}
        <div className="f6 mt4">* indicates required columns</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  evtFlag: state.workflow.fields.evtFlag,
  cmlDataAndLabelsTable: state.workflow.fields.cmlDataAndLabelsTable,
  cmlDataAndLabelsTable2: state.workflow.fields.cmlDataAndLabelsTable2,
  cmlDataAndLabelsTable3: state.workflow.fields.cmlDataAndLabelsTable3,
  cmlMeasurementThicknessTable: state.workflow.fields.cmlMeasurementThicknessTable,
  cmlMeasurementThicknessTable2: state.workflow.fields.cmlMeasurementThicknessTable2,
})

export default connect(mapStateToProps, null)(
  withConfig(Step3)
)
