import ApiG1 from './ApiG1.png'
import ApiG2 from './ApiG2.png'
import ApiG3 from './ApiG3.png'
import ApiG4 from './ApiG4.png'
import ApiG5 from './ApiG5.png'
import ApiG6 from './ApiG6.png'
import ApiG7 from './ApiG7.png'
import ApiG8 from './ApiG8.png'
import CO from './CO.png'
import COD from './COD.png'
import COU from './COU.png'
import CY from './CY.png'
import CYD from './CYD.png'
import CYDID from './CYDID.png'
import CYU from './CYU.png'
import CYUID from './CYUID.png'
import EL from './EL.png'
import ELD from './ELD.png'
import level2Geometry from './level2Geometry.png'
import PE from './PE.png'
import PED from './PED.png'
import PEID from './PEID.png'
import siteFactorsTable from './siteFactorsTable.png'
import SP from './SP.png'
import SPD from './SPD.png'
import TO from './TO.png'
import TOD from './TOD.png'

export {
  ApiG1,
  ApiG2,
  ApiG3,
  ApiG4,
  ApiG5,
  ApiG6,
  ApiG7,
  ApiG8,
  CO,
  COD,
  COU,
  CY,
  CYD,
  CYDID,
  CYU,
  CYUID,
  EL,
  ELD,
  level2Geometry,
  PE,
  PED,
  PEID,
  siteFactorsTable,
  SP,
  SPD,
  TO,
  TOD
}
