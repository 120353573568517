import React, { FormEvent, useCallback, useContext } from 'react'
import useConditionalTimeout from 'beautiful-react-hooks/useConditionalTimeout'

import E2GStepper, { TProps as TStepperProps } from '@e2grnd/ui-core/dist/components/E2GStepper'

import useRequest from './steps/useRequest'
import { TWorkflowState } from './reducer'
import steps from './steps'
import { WorkflowDispatchContext, WorkflowStateContext } from './WorkflowProvider'

function Workload() {
  const { handleSubmit } = useRequest()
  const context = useContext(WorkflowStateContext)
  const dispatch = useContext(WorkflowDispatchContext)

  useConditionalTimeout(() => {
    dispatch({ type: 'clearPreventSubmission' })
  }, 5000, Boolean(context.preventSubmission))

  const {
    files,
    selectedScript,
    submitting,
    jobId,
    selectedCircuits,
  } = context
  const getStepNavProps: TStepperProps['getStepNavProps'] = useCallback((step, incomingProps) => {
    const stepNavProps = {
      marginBottom: '6rem',
    }
    if(typeof step.stepNav === 'function') {
      return { ...stepNavProps, ...step.stepNav(context, incomingProps) }
    }
    return { ...stepNavProps, ...(incomingProps || {}) }
  }, [context])
  const getStepButtonProps: TStepperProps<TWorkflowState>['getStepButtonProps'] = useCallback((step: typeof steps[number]) => {
    const btnProps = {
      disabled: false,
    }
    // Disable all stepper nav buttons if uploading
    if(submitting) {
      return {
        disabled: true,
      }
    }
    switch(step.name) {
      case 'select-executable': {
        btnProps.disabled = !(files?.length)
        break
      }
      case 'select-circuits': {
        btnProps.disabled = !(files?.length && selectedScript) || selectedCircuits?.length === 0
        break
      }
      case 'review': {
        btnProps.disabled = !(files?.length && selectedScript)
        break
      }
      case 'status': {
        btnProps.disabled = !jobId
        break
      }
      default:
        // noop
    }
    return btnProps
  }, [files?.length, jobId, selectedCircuits?.length, selectedScript, submitting])
  const preventDefaultSubmit = (e: FormEvent) => {
    e.preventDefault()
  }
  return (
    <form id="workflow" onSubmit={preventDefaultSubmit}>
      <E2GStepper
        steps={steps}
        orientation="horizontal"
        onSubmit={handleSubmit}
        getStepNavProps={getStepNavProps}
        getStepButtonProps={getStepButtonProps}
      />
    </form>
  )
}

export default Workload
