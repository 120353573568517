/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'

import { getBoxWhiskerGraphs } from '../lib/getResultCases.js'

const BoxWhisker = ({ results, problemType }) => {
  let graphData
  let orderedCmls
  let orderedAcfs
  if(typeof results.finalOutData !== 'undefined') {
    if(typeof results.orderedCmls !== 'undefined') {
      orderedCmls = results.orderedCmls
    }
    if(typeof results.orderedAcfs !== 'undefined') {
      orderedAcfs = results.orderedAcfs
    }
    graphData = getBoxWhiskerGraphs(results.finalOutData, problemType, orderedCmls, orderedAcfs)
  }
  return (
    <div className="cf w-100">
      {results.finalOutData
        ? (
          <div className="fl w-100">
            <div>
              <DataGraph plotClass="data-graph-85" data={graphData.traces} layout={graphData.layout} />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

BoxWhisker.propTypes = {
  results: PropTypes.object,
  problemType: PropTypes.string,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  problemType: state.workflow.fields.problemType,
})

export default connect(mapStateToProps, null)(BoxWhisker)
