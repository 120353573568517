export default [{
  expression: (problemType, accessControl, credentials, activeCalculator) => {
    return problemType !== 'SIMP' && problemType !== 'R' ? [{
      name: "cmlDataAndLabelsTable",
      required: false,
      type: "CUSTOM_FIELD"
    }] : "";
  },
  testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
}, {
  expression: (problemType, accessControl, credentials, activeCalculator) => {
    return problemType === 'SIMP' ? [{
      name: "cmlDataAndLabelsTable2",
      required: false,
      type: "CUSTOM_FIELD"
    }] : "";
  },
  testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
}, {
  expression: (problemType, accessControl, credentials, activeCalculator) => {
    return problemType === 'R' ? [{
      name: "acfInputTable",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "cmlDataAndLabelsTable3",
      required: false,
      type: "CUSTOM_FIELD"
    }] : "";
  },
  testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
}, {
  expression: (step1StatusEmpty, step1StatusDuplicate, accessControl, credentials, activeCalculator) => {
    return step1StatusEmpty === false ? [{
      expression: (step1StatusDuplicate, accessControl, credentials, activeCalculator) => {
        return step1StatusDuplicate === true ? "" : "";
      },
      testIdentifiers: ["step1StatusDuplicate", "accessControl", "credentials", "activeCalculator"]
    }] : [];
  },
  testIdentifiers: ["step1StatusEmpty", "step1StatusDuplicate", "accessControl", "credentials", "activeCalculator"]
}];