import React from 'react'

import { TWorkflowStep } from '@e2grnd/ui-core/dist/types'

import { TWorkflowState } from '../reducer'

import StepSelectCircuits from './StepSelectCircuits'
import StepSelectFiles from './StepSelectFiles'
import StepSelectScript from './StepSelectScript'
import StepSubmit from './StepSubmit'

export const steps: TWorkflowStep<TWorkflowState>[] = [
  {
    name: 'upload',
    title: 'Upload Directory',
    content: <StepSelectFiles />,
    stepNav: (context: { files: any }, incomingProps = {}) => ({
      ...incomingProps,
      nextButtonProps: {
        disabled: !context.files,
      },
    }),
  },
  {
    name: 'select-executable',
    title: 'Select Executable',
    content: <StepSelectScript />,
    stepNav: (context: { selectedScript: any }, incomingProps = {}) => ({
      ...incomingProps,
      nextButtonProps: {
        disabled: !context.selectedScript,
      },
    }),
  },
  {
    name: 'select-circuits',
    title: 'Select Circuits',
    content: <StepSelectCircuits />,
    stepNav: (context: { selectedCircuits: string | any[] }, incomingProps = {}) => ({
      ...incomingProps,
      nextButtonProps: {
        disabled: context.selectedCircuits?.length === 0,
      },
    }),
  },
  {
    name: 'review',
    title: 'Submit Workload',
    content: <StepSubmit />,
    stepNav: (context: { submitting: any; preventSubmission: any; jobId: any }, incomingProps = {}) => ({
      ...incomingProps,
      labelNext: 'Submit',
      useSubmit: true,
      prevButtonProps: {
        disabled: context.submitting,
      },
      nextButtonProps: {
        disabled: context.preventSubmission || context.submitting || !!context.jobId,
      },
    }),
  },
]

export default steps
