import React from 'react'

import AdvancedOptions from './AdvancedOptions'
import ReportConfig from './ReportConfig'

export default [
  {
    name: 'Prop1',
    title: 'Advanced Options',
    component: <AdvancedOptions />,
  },
  {
    name: 'Prop2',
    title: 'Report Config',
    component: <ReportConfig />,
  },
]
