export default [{
  name: "thicknessPlotsReportFlag",
  required: false,
  type: "CUSTOM_FIELD"
}, {
  name: "metalLossPlotsReportFlag",
  required: false,
  type: "CUSTOM_FIELD"
}, {
  name: "pofPlotsReportFlag",
  required: false,
  type: "CUSTOM_FIELD"
}, {
  name: "residualPlotsReportFlag",
  required: false,
  type: "CUSTOM_FIELD"
}];