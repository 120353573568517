import { parse as parseCSV, ParseResult } from 'papaparse'

import { FileListWithWebkitDrafts, TCircuitResourceScales } from './reducer'

type TOptions = {
  circuitDefinitionFilename?: string
  columnName?: string
  resourceScaleColumnName?: string
  initializationFilename?: string
  clientsDefinitionFilename?: string
}
const defaultOptions: Required<TOptions> = {
  circuitDefinitionFilename: 'circuits_export.csv',
  columnName: 'Combined_Circuit',
  resourceScaleColumnName: 'resource_scaling_factor',
  initializationFilename: 'initialization_parameters.csv',
  clientsDefinitionFilename: 'clients_export.csv',
}

export async function getCircuitsFromFiles(fileList: FileListWithWebkitDrafts, options: TOptions = defaultOptions): Promise<{ circuits: Array<string>, resourceScales: TCircuitResourceScales, customerId: string}> {
  const opts = Object.assign(defaultOptions, options)
  const circuitDefinitionFile = Array.from(fileList).find(f => f.name.toLowerCase() === opts.circuitDefinitionFilename.toLowerCase())
  const initializationFile = Array.from(fileList).find(f => f.name.toLowerCase() === opts.initializationFilename.toLowerCase())
  const clientsDefinitionFile = Array.from(fileList).find(f => f.name.toLowerCase() === opts.clientsDefinitionFilename.toLowerCase())
  if(!circuitDefinitionFile) {
    throw new Error(`Could not find the circuit definition file named "${opts.circuitDefinitionFilename}" in the upload.`)
  }
  if(!initializationFile) {
    throw new Error(`Could not find the initialization parameter definition file named "${opts.initializationFilename}" in the upload.`)
  }
  if(!clientsDefinitionFile) {
    throw new Error(`Could not find the clients definition file named "${opts.clientsDefinitionFilename}" in the upload.`)
  }
  const circuitDefinitionContents = await getFileContents<{ [k: string]: any }>(circuitDefinitionFile)
    .then(d => d.data)
  const initializationContents = await getFileContents<{ [k: string]: any }>(initializationFile)
    .then(d => d.data)
  const initClientRow = initializationContents.find((r) => r.name && r.name === 'client')
  if(!initClientRow) {
    throw new Error(`Client row not found in ${opts.initializationFilename}`)
  }
  let clientValue = initClientRow.value as string
  if(!clientValue) {
    throw new Error(`Client value not found in ${opts.initializationFilename}`)
  }
  // remove quotations that are around the client name for some reason
  clientValue = clientValue.replace(/"/g, '')
  const clientsDefinitionContents = await getFileContents<{ [k: string]: any }>(clientsDefinitionFile)
    .then(d => d.data)
  const clientRow = clientsDefinitionContents.find((r) => r.client === clientValue)
  if(!clientRow) {
    throw new Error(`Row not found with client ${clientValue} in ${opts.clientsDefinitionFilename}`)
  }
  const companyName = clientRow.auth_company_name
  if(!companyName) {
    throw new Error(`auth_company_name not found in ${opts.clientsDefinitionFilename}`)
  }
  const circuits = new Set<string>()
  const resourceScales = circuitDefinitionContents.reduce((acc, row) => {
    let circuitName = row[opts.columnName]
    if(!circuitName) return acc
    circuitName = String(circuitName).trim()
    if(circuitName === '') return acc
    circuits.add(circuitName)
    try {
      const resourceScale = parseFloat(row[opts.resourceScaleColumnName])
      acc[circuitName] = Math.max(0, Math.min(1, resourceScale || 0))
    } catch(err) {
      console.warn(err)
    }
    return acc
  }, {} as Record<string, number>)
  return {
    circuits: Array.from(circuits),
    customerId: companyName,
    resourceScales,
  }
}

async function getFileContents<T = unknown>(file: File): Promise<ParseResult<T>> {
  return new Promise((resolve, reject) => {
    parseCSV<T>(file, {
      complete: (results) => resolve(results),
      error: reject,
      header: true,
    })
  })
}

