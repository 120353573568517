/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'

import { getMultiplierTable, getNpsTable, getNumReadingsTable } from '../lib/checkTables.js'

const AdvancedOptions = ({
  problemType, nextTaDate, turnaroundFrequency, projectionsCredibleInterval, weightBaseValue, weightMaxValue, weightMidValue, weightSteepnessValue, nChains, numberOfBurnInSteps, numberOfAdaptiveSteps, numberOfSamplesToThin, numberOfTotalSamples, style, censoredReadingsTable, pipeScheduleTable, errorMultipliersTable, use_unmonitored_comps, use_piecewise_rates_flag, use_linear_rates_flag, model_run_type, use_weighted_readings, use_convergence, accepted_measurement_threshold, measurement_error_noisy_threshold, measurement_error_noise_quantile, project_with_meas_err, consequence_basis, financial_risk_action_level, consequence_area_risk_action_level, pof_risk_action_target,
}) => (
  <div>
    <div className="fl w-100">
      <div className="fl w-100 w-50-ns pl3">
        <E2GSelectField style={style} name="nChains" value={nChains} label="Number of Chains">
          <E2GSelectFieldOption value={1} label="1" />
          <E2GSelectFieldOption value={2} label="2" />
          <E2GSelectFieldOption value={3} label="3" />
          <E2GSelectFieldOption value={4} label="4" />
        </E2GSelectField>
        <E2GTextField style={style} name="numberOfBurnInSteps" value={numberOfBurnInSteps} label="Number of Burn in Steps" type="number" required />
        <E2GTextField style={style} name="numberOfAdaptiveSteps" value={numberOfAdaptiveSteps} label="Number of Adaptive Steps" type="number" required />
        <E2GTextField style={style} name="numberOfSamplesToThin" value={numberOfSamplesToThin} label="Number of Samples to Thin" type="number" required />
        <E2GTextField style={style} name="numberOfTotalSamples" value={numberOfTotalSamples} label="Number of Total Samples" type="number" required />
        <E2GTextField style={style} name="projectionsCredibleInterval" value={projectionsCredibleInterval} label="Projections Credible Interval" type="number" required />
      </div>
      {problemType !== 'SIMP'
        ? (
          <div className="fl w-100 w-50-ns pl3">
            <E2GTextField style={style} name="weightBaseValue" value={weightBaseValue} label="Weight Base Value" type="number" required />
            <E2GTextField style={style} name="weightMaxValue" value={weightMaxValue} label="Weight Max Value" type="number" required />
            <E2GTextField style={style} name="weightMidValue" value={weightMidValue} label="Weight Mid Value" type="number" required />
            <E2GTextField style={style} name="weightSteepnessValue" value={weightSteepnessValue} label="Weight Steepness Value" type="number" required />
            <E2GTextField style={style} name="nextTaDate" value={nextTaDate} label="Next Turnaround Date" type="date" required />
            <E2GTextField style={style} name="turnaroundFrequency" value={turnaroundFrequency} label="Turnaround Frequency" type="number" required />
          </div>
        )
        : <div className="fl w-100 w-50-ns pl3">
          <E2GTextField style={style} name="nextTaDate" value={nextTaDate} label="Next Turnaround Date" type="date" required />
          <E2GTextField style={style} name="turnaroundFrequency" value={turnaroundFrequency} label="Turnaround Frequency" type="number" required />
        </div>}
    </div>
    {problemType === 'R'
      ? (
        <div>
          <div className="fl w-100 w-50-ns pl3">
            <E2GSelectField style={style} name="use_unmonitored_comps" value={use_unmonitored_comps} label="Include Unmonitored Components?">
              <E2GSelectFieldOption value="TRUE" label="Yes" />
              <E2GSelectFieldOption value="FALSE" label="No" />
            </E2GSelectField>
            <E2GSelectField style={style} name="use_piecewise_rates_flag" value={use_piecewise_rates_flag} label="Use Piecewise Corrosion Rates?" type="number" >
              <E2GSelectFieldOption value="1" label="Yes" />
              <E2GSelectFieldOption value="0" label="No" />
            </E2GSelectField>
            <E2GSelectField style={style} name="use_linear_rates_flag" value={use_linear_rates_flag} label="Use Linear Corrosion Rates?" type="number" >
              <E2GSelectFieldOption value="1" label="Yes" />
              <E2GSelectFieldOption value="0" label="No" />
            </E2GSelectField>
            <E2GSelectField style={style} name="use_weighted_readings" value={use_weighted_readings} label="Use Weighted Readings?">
              <E2GSelectFieldOption value="TRUE" label="Yes" />
              <E2GSelectFieldOption value="FALSE" label="No" />
            </E2GSelectField>
            <E2GSelectField style={style} name="model_run_type" value={model_run_type} label="Model Run Type">
              <E2GSelectFieldOption value="standard" label="Standard (w/ACF Groups)" />
              <E2GSelectFieldOption value="initial" label="Initial (w/out ACF Groups)" />
            </E2GSelectField>
            <E2GSelectField style={style} name="use_convergence" value={use_convergence} label="Use Dynamic Convergence?">
              <E2GSelectFieldOption value="TRUE" label="Yes" />
              <E2GSelectFieldOption value="FALSE" label="No" />
            </E2GSelectField>
          </div>
          <div className="fl w-100 w-50-ns pl3">
            <E2GTextField style={style} name="accepted_measurement_threshold" value={accepted_measurement_threshold} label="Acceptable Measurement Error Threshold" type="number" required />
            <E2GTextField style={style} name="measurement_error_noisy_threshold" value={measurement_error_noisy_threshold} label="Measurement Error Noisy Threshold" type="number" required />
            <E2GTextField style={style} name="measurement_error_noise_quantile" value={measurement_error_noise_quantile} label="Measurement Error Noise Quantile" type="number" required />
            <E2GSelectField style={style} name="project_with_meas_err" value={project_with_meas_err} label="Project Thicknesses with Measurement Error?">
              <E2GSelectFieldOption value="TRUE" label="Yes" />
              <E2GSelectFieldOption value="FALSE" label="No" />
            </E2GSelectField>
            <E2GSelectField style={style} name="consequence_basis" value={consequence_basis} label="Consequence Basis?">
              <E2GSelectFieldOption value="financial" label="Financial" />
              <E2GSelectFieldOption value="area" label="Area" />
              <E2GSelectFieldOption value="category" label="Category" />
            </E2GSelectField>
            {consequence_basis === 'financial'
              ? (
                <E2GTextField style={style} name="financial_risk_action_level" value={financial_risk_action_level} label="Financial Risk Action Level" type="number" required />
              )
              : <div>
                <input type="hidden" name="consequence_area_risk_action_level" value={0} />
                <input type="hidden" name="pof_risk_action_target" value={0} />
              </div>}
            {consequence_basis === 'area'
              ? (
                <E2GTextField style={style} name="consequence_area_risk_action_level" value={consequence_area_risk_action_level} label="Area Risk Action Level" type="number" required />
              )
              : <div>
                <input type="hidden" name="financial_risk_action_level" value={0} />
                <input type="hidden" name="pof_risk_action_target" value={0} />
              </div>}
            {consequence_basis === 'category'
              ? (
                <E2GTextField style={style} name="pof_risk_action_target" value={pof_risk_action_target} label="Category (POF) Risk Action Level" type="number" required />
              )
              : <div>
                <input type="hidden" name="consequence_area_risk_action_level" value={0} />
                <input type="hidden" name="financial_risk_action_level" value={0} />
              </div>}
          </div>
        </div>
      )
      : ''}
    {problemType !== 'SIMP'
      ? (
        <div>
          <div className="fl w-100">
            <div className="fl w-100">
              <DataTableField id="censoredReadingsTable" name="censoredReadingsTable" value={getNumReadingsTable(censoredReadingsTable)} colHeaders={{ USCustomary: ['NPS*', 'UT Number of Readings*', 'RT Number of Readings*'], Metric: ['NPS*', 'UT Number of Readings*', 'RT Number of Readings*'] }} rowHeaders={false} />
            </div>
          </div>
          <div className="fl w-100">
            <div className="fl w-100">
              <DataTableField id="errorMultipliersTable" name="errorMultipliersTable" value={getMultiplierTable(errorMultipliersTable)} colHeaders={{ USCustomary: ['Component Type*', 'Data Error Multiplier*', 'Measurement Error Multiplier*'], Metric: ['Component Type*', 'Data Error Multiplier*', 'Measurement Error Multiplier*'] }} rowHeaders={false} />
            </div>
          </div>
          <div className="fl w-100">
            <div className="fl w-100">
              <DataTableField id="pipeScheduleTable" name="pipeScheduleTable" value={getNpsTable(pipeScheduleTable)} colHeaders={{ USCustomary: ['NPS*', 'SCH*', 'Nominal (in)*', 'Max Nominal (in)*', 'Nominal SD (in)*', 'Preferred Sch'], Metric: ['NPS*', 'SCH*', 'Nominal (mm)*', 'Max Nominal (mm)*', 'Nominal SD (mm)*', 'Preferred Sch'] }} rowHeaders={false} />
            </div>
          </div>
        </div>
      )
      : ''}
  </div>
)
AdvancedOptions.propTypes = {
  consequence_basis: PropTypes.string,
  financial_risk_action_level: PropTypes.number,
  consequence_area_risk_action_level: PropTypes.number,
  pof_risk_action_target: PropTypes.number,
  accepted_measurement_threshold: PropTypes.number,
  measurement_error_noisy_threshold: PropTypes.number,
  measurement_error_noise_quantile: PropTypes.number,
  project_with_meas_err: PropTypes.string,
  use_convergence: PropTypes.string,
  use_weighted_readings: PropTypes.string,
  use_unmonitored_comps: PropTypes.string,
  use_piecewise_rates_flag: PropTypes.number,
  use_linear_rates_flag: PropTypes.number,
  model_run_type: PropTypes.string,
  problemType: PropTypes.string,
  nextTaDate: PropTypes.string,
  turnaroundFrequency: PropTypes.number,
  nChains: PropTypes.number,
  numberOfSamplesToThin: PropTypes.number,
  numberOfBurnInSteps: PropTypes.number,
  numberOfAdaptiveSteps: PropTypes.number,
  numberOfTotalSamples: PropTypes.number,
  weightBaseValue: PropTypes.number,
  weightMaxValue: PropTypes.number,
  weightMidValue: PropTypes.number,
  weightSteepnessValue: PropTypes.number,
  projectionsCredibleInterval: PropTypes.number,
  censoredReadingsTable: PropTypes.array,
  errorMultipliersTable: PropTypes.array,
  pipeScheduleTable: PropTypes.array,
  style: PropTypes.object,
}
const mapStateToProps = state => ({
  consequence_basis: state.workflow.fields.consequence_basis,
  financial_risk_action_level: state.workflow.fields.financial_risk_action_level,
  consequence_area_risk_action_level: state.workflow.fields.consequence_area_risk_action_level,
  pof_risk_action_target: state.workflow.fields.pof_risk_action_target,
  accepted_measurement_threshold: state.workflow.fields.accepted_measurement_threshold,
  measurement_error_noisy_threshold: state.workflow.fields.measurement_error_noisy_threshold,
  measurement_error_noise_quantile: state.workflow.fields.measurement_error_noise_quantile,
  project_with_meas_err: state.workflow.fields.project_with_meas_err,
  use_convergence: state.workflow.fields.use_convergence,
  use_weighted_readings: state.workflow.fields.use_weighted_readings,
  use_unmonitored_comps: state.workflow.fields.use_unmonitored_comps,
  use_piecewise_rates_flag: state.workflow.fields.use_piecewise_rates_flag,
  use_linear_rates_flag: state.workflow.fields.use_linear_rates_flag,
  model_run_type: state.workflow.fields.model_run_type,
  problemType: state.workflow.fields.problemType,
  nextTaDate: state.workflow.fields.nextTaDate,
  nChains: state.workflow.fields.nChains,
  turnaroundFrequency: state.workflow.fields.turnaroundFrequency,
  projectionsCredibleInterval: state.workflow.fields.projectionsCredibleInterval,
  numberOfSamplesToThin: state.workflow.fields.numberOfSamplesToThin,
  weightBaseValue: state.workflow.fields.weightBaseValue,
  numberOfBurnInSteps: state.workflow.fields.numberOfBurnInSteps,
  numberOfAdaptiveSteps: state.workflow.fields.numberOfAdaptiveSteps,
  numberOfTotalSamples: state.workflow.fields.numberOfTotalSamples,
  weightMaxValue: state.workflow.fields.weightMaxValue,
  weightMidValue: state.workflow.fields.weightMidValue,
  weightSteepnessValue: state.workflow.fields.weightSteepnessValue,
  censoredReadingsTable: state.workflow.fields.censoredReadingsTable,
  errorMultipliersTable: state.workflow.fields.errorMultipliersTable,
  pipeScheduleTable: state.workflow.fields.pipeScheduleTable,
  style: state.ui.style,
})
export default connect(mapStateToProps, null)(AdvancedOptions)
