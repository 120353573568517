export default [{
  expression: (problemType, corrosionRateForm, consequence_basis, evtFlag, accessControl, credentials, activeCalculator) => {
    return problemType === 'CS_OLD' ? [{
      expression: (corrosionRateForm, accessControl, credentials, activeCalculator) => {
        return corrosionRateForm !== 'Constant' ? [{
          name: "rate1SdInitial",
          required: true,
          type: "CUSTOM_FIELD"
        }, {
          name: "rate2MuInitial",
          required: true,
          type: "CUSTOM_FIELD"
        }, {
          name: "rate2SdInitial",
          required: true,
          type: "CUSTOM_FIELD"
        }] : "";
      },
      testIdentifiers: ["corrosionRateForm", "accessControl", "credentials", "activeCalculator"]
    }, {
      name: "circuit",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "corrosionRateForm",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorCrMean",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorCrSd",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorCrMultiplier",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "rate0SdInitial",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "censoredMaxOffset",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "minCmlLossOffset",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "evtFlag",
      required: false,
      type: "CUSTOM_FIELD"
    }, {
      name: "vtInterval",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "visualInitial",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorMeasErrorMean",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorMeasErrorMax",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorDataErrorMin",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "sigma1MuInitial",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "sigma1SdInitial",
      required: true,
      type: "CUSTOM_FIELD"
    }] : [{
      expression: (problemType, accessControl, credentials, activeCalculator) => {
        return problemType === 'R' ? [{
          name: "thinning_dm",
          required: false,
          type: "CUSTOM_FIELD"
        }] : "";
      },
      testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
    }, {
      expression: (problemType, accessControl, credentials, activeCalculator) => {
        return problemType === 'CS' ? [{
          name: "vtInterval",
          required: true,
          type: "CUSTOM_FIELD"
        }] : "";
      },
      testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
    }, {
      expression: (problemType, accessControl, credentials, activeCalculator) => {
        return problemType !== 'R' ? [{
          name: "priorMeasErrorSD",
          required: true,
          type: "CUSTOM_FIELD"
        }] : "";
      },
      testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
    }, {
      expression: (problemType, consequence_basis, accessControl, credentials, activeCalculator) => {
        return problemType === 'R' ? [{
          expression: (consequence_basis, accessControl, credentials, activeCalculator) => {
            return consequence_basis === 'financial' ? [{
              name: "consequence_financial",
              required: true,
              type: "CUSTOM_FIELD"
            }] : [{
              name: "consequence_area",
              required: false,
              type: "HIDDEN_FIELD"
            }, {
              name: "consequence_class",
              required: false,
              type: "HIDDEN_FIELD"
            }];
          },
          testIdentifiers: ["consequence_basis", "accessControl", "credentials", "activeCalculator"]
        }, {
          expression: (consequence_basis, accessControl, credentials, activeCalculator) => {
            return consequence_basis === 'area' ? [{
              name: "consequence_area",
              required: true,
              type: "CUSTOM_FIELD"
            }] : [{
              name: "consequence_financial",
              required: false,
              type: "HIDDEN_FIELD"
            }, {
              name: "consequence_class",
              required: false,
              type: "HIDDEN_FIELD"
            }];
          },
          testIdentifiers: ["consequence_basis", "accessControl", "credentials", "activeCalculator"]
        }, {
          expression: (consequence_basis, accessControl, credentials, activeCalculator) => {
            return consequence_basis === 'category' ? [{
              name: "consequence_class",
              required: false,
              type: "CUSTOM_FIELD"
            }] : [{
              name: "consequence_area",
              required: false,
              type: "HIDDEN_FIELD"
            }, {
              name: "consequence_financial",
              required: false,
              type: "HIDDEN_FIELD"
            }];
          },
          testIdentifiers: ["consequence_basis", "accessControl", "credentials", "activeCalculator"]
        }, {
          name: "priorDataErrorMin",
          required: true,
          type: "CUSTOM_FIELD"
        }, {
          name: "priorMeasErrorMax",
          required: true,
          type: "CUSTOM_FIELD"
        }, {
          name: "priorCrMax",
          required: true,
          type: "CUSTOM_FIELD"
        }] : [];
      },
      testIdentifiers: ["problemType", "consequence_basis", "accessControl", "credentials", "activeCalculator"]
    }, {
      expression: (problemType, accessControl, credentials, activeCalculator) => {
        return problemType === 'CS' ? [{
          name: "priorDataError",
          required: true,
          type: "CUSTOM_FIELD"
        }, {
          name: "priorDataErrorSd",
          required: true,
          type: "CUSTOM_FIELD"
        }] : "";
      },
      testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
    }, {
      expression: (problemType, accessControl, credentials, activeCalculator) => {
        return problemType !== 'R' ? [{
          name: "evtFlag",
          required: false,
          type: "CUSTOM_FIELD"
        }] : "";
      },
      testIdentifiers: ["problemType", "accessControl", "credentials", "activeCalculator"]
    }, {
      expression: (evtFlag, accessControl, credentials, activeCalculator) => {
        return evtFlag === 'censoring' ? [{
          name: "censoredMaxOffset",
          required: true,
          type: "CUSTOM_FIELD"
        }] : "";
      },
      testIdentifiers: ["evtFlag", "accessControl", "credentials", "activeCalculator"]
    }, {
      name: "circuit",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorCrMean",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorCrSd",
      required: true,
      type: "CUSTOM_FIELD"
    }, {
      name: "priorMeasErrorMean",
      required: true,
      type: "CUSTOM_FIELD"
    }];
  },
  testIdentifiers: ["problemType", "corrosionRateForm", "consequence_basis", "evtFlag", "accessControl", "credentials", "activeCalculator"]
}];