export default {
  USCustomary: [
    {
      group: 'Contrived Examples - Simple Model',
      members: [
        {
          title: 'Case 1 - Base Case',
          fileName: 'simpleExample.json',
        },
        {
          title: 'Case 2 - Symmetric Error',
          fileName: 'simpleError.json',
        },
        {
          title: 'Case 3 - Diff Exact Rates',
          fileName: 'simple_case3.json',
        },
        {
          title: 'Case 4 - Diff Rates Error',
          fileName: 'simple_case4.json',
        },
        // {
        //   title: 'Case 5 - Multiple Censored',
        //   fileName: 'simple_case5.json',
        // },
        {
          title: 'Case 5 - Multiple EVT',
          fileName: 'simple_case5_evt.json',
        },
        // {
        //   title: 'Case 5b - Multiple Censored',
        //   fileName: 'simple_case5b.json',
        // },
        {
          title: 'Case 5b - Multiple EVT',
          fileName: 'simple_case5b_evt.json',
        },
        // {
        //   title: 'Case 6 - RT',
        //   fileName: 'simple_case6.json',
        // },
        {
          title: 'Case 6 - RT EVT',
          fileName: 'simple_case6_evt.json',
        },
        {
          title: 'Case 8 - Replacements',
          fileName: 'simple_case8.json',
        },
        {
          title: 'Case 9 - Unmonitored Exact',
          fileName: 'simple_case9.json',
        },
        {
          title: 'Case 9b - Unmonitored Error',
          fileName: 'simple_case9b.json',
        },
        {
          title: 'Case 9c - Unmonitored Diff Rates',
          fileName: 'simple_case9c.json',
        },
        {
          title: 'Case 9d - Unmonitored Diff ACF',
          fileName: 'simple_case9d.json',
        },
        {
          title: 'Case 10 - BP Atlantis Circuit',
          fileName: 'simple_case10.json',
        },
      ],
    },
    {
      group: 'Contrived Examples - Reduced CS Model',
      members: [
        {
          title: 'Case 1 - Base Case',
          fileName: 'CS_reduced_case1.json',
        },
        {
          title: 'Case 2 - Symmetric Error',
          fileName: 'CS_reduced_case2.json',
        },
        {
          title: 'Case 3 - Diff Exact Rates',
          fileName: 'CS_reduced_case3.json',
        },
        {
          title: 'Case 4 - Diff Rates Error',
          fileName: 'CS_reduced_case4.json',
        },
        {
          title: 'Case 5 - Multiple Censored',
          fileName: 'CS_reduced_case5.json',
        },
        {
          title: 'Case 5 - Multiple EVT',
          fileName: 'CS_reduced_case5_evt.json',
        },
        {
          title: 'Case 5b - Multiple Censored',
          fileName: 'CS_reduced_case5b.json',
        },
        {
          title: 'Case 5b - Multiple EVT',
          fileName: 'CS_reduced_case5b_evt.json',
        },
        {
          title: 'Case 6 - RT',
          fileName: 'CS_reduced_case6.json',
        },
        {
          title: 'Case 6 - RT EVT',
          fileName: 'CS_reduced_case6_evt.json',
        },
        {
          title: 'Case 8 - Replacements',
          fileName: 'CS_reduced_case8.json',
        },
        {
          title: 'Case 9 - Unmonitored Exact',
          fileName: 'CS_reduced_case9.json',
        },
        {
          title: 'Case 9 - Unmonitored Error',
          fileName: 'CS_reduced_case9b.json',
        },
        {
          title: 'Case 9 - Unmonitored Diff Rates',
          fileName: 'CS_reduced_case9c.json',
        },
        {
          title: 'Case 9 - Unmonitored Diff ACF',
          fileName: 'CS_reduced_case9d.json',
        },
        {
          title: 'Case 10 - BP Atlantis Circuit',
          fileName: 'CS_reduced_case10.json',
        },
      ],
    },
    {
      group: 'Contrived Examples - Full CS Model',
      members: [
        // {
        //   title: 'Case 1 - Base Case',
        //   fileName: 'CS_full_case1.json',
        // },
        {
          title: 'Case 2 - Symmetric Error',
          fileName: 'CS_full_case2.json',
        },
      ],
    },
    {
      group: 'Contrived Examples - R Model',
      members: [
        {
          title: 'Case 1 - Base Case',
          fileName: 'exampleR01.json',
        },
        {
          title: 'Case 2 - Modified Base Case - 2 ACFs',
          fileName: 'exampleR02.json',
        },
      ],
    },
    {
      group: 'P66 Examples',
      members: [
        {
          title: 'Circuit 1460-1900',
          fileName: 'newExample.json',
        },
        // {
        //   title: 'Circuit 1460-1900 EVT',
        //   fileName: 'csEvt.json',
        // },
      ],
    },
  ],
  Metric: [
  ],
}
