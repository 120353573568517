import { logo } from '../../../images/encodings'

export default {
  title: 'Corrsolutions DA Report',
  logo: {
    uri: logo,
    width: 100,
    height: 32,
  },
  columnWidths: [200, 10, 280, 60],
  sections: [
    {
      type: 'heading',
      text: 'Inputs',
    },
    {
      type: 'keywords',
      heading: 'Problem Definition',
      params: [
        {
          keyword: 'problemType',
          transform: (value) => {
            if(value === 'CS') {
              return 'Corrsolutions Reduced Model'
            } else if(value === 'CS_OLD') {
              return 'Corrsolutions Full Monty'
            } else if(value === 'SIMP') {
              return 'Simple Model'
            }
            return value
          },
        },
        {
          keyword: 'circuit',
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Circuit Data',
      params: [
        {
          keyword: 'corrosionRateForm',
          predicate: fields => (fields.problemType === 'CS'),
        },
        {
          keyword: 'priorCrMean',
        },
        {
          keyword: 'priorCrSd',
        },
        {
          keyword: 'priorCrMultiplier',
          predicate: fields => (fields.problemType === 'CS_OLD'),
        },
        {
          keyword: 'priorMeasErrorMean',
        },
        {
          keyword: 'priorMeasErrorSd',
          predicate: fields => (fields.problemType === 'SIMP' || fields.problemType === 'CS'),
        },
        {
          keyword: 'priorMeasErrorMax',
          predicate: fields => (fields.problemType === 'CS_OLD'),
        },
        {
          keyword: 'priorDataErrorMin',
          predicate: fields => (fields.problemType === 'CS_OLD'),
        },
        {
          keyword: 'vtInterval',
          predicate: fields => (fields.problemType === 'CS_OLD' || fields.problemType === 'CS'),
        },
        {
          keyword: 'visualInitial',
          predicate: fields => (fields.problemType === 'CS_OLD' || fields.problemType === 'CS'),
        },
        {
          keyword: 'censoredMaxOffset',
          predicate: fields => (fields.evtFlag === 'censoring'),
        },
        {
          keyword: 'minCmlLossOffset',
          predicate: fields => (fields.problemType === 'CS_OLD'),
        }
      ],
    },
    {
      type: 'table',
      heading: 'CML Data',
      predicate: fields => (fields.problemType === 'CS_OLD' || fields.problemType === 'CS'),
      dataTables: [
        {
          name: 'cmlDataAndLabelsTable',
        },
      ],
    },
    {
      type: 'table',
      heading: 'CML Data',
      predicate: fields => (fields.problemType === 'SIMP'),
      dataTables: [
        {
          name: 'cmlDataAndLabelsTable2',
        },
      ],
    },
    {
      type: 'table',
      heading: 'Thickness Data',
      predicate: fields => (fields.problemType === 'SIMP'),
      dataTables: [
        {
          name: 'cmlMeasurementThicknessTable',
        },
      ],
    },
    {
      type: 'table',
      heading: 'Thickness Data',
      predicate: fields => ((fields.problemType === 'CS' || fields.problemType === 'CS_OLD') && fields.evtFlag === 'censoring'),
      dataTables: [
        {
          name: 'cmlMeasurementThicknessTable',
        },
      ],
    },
    {
      type: 'table',
      heading: 'Thickness Data',
      predicate: fields => ((fields.problemType === 'CS' || fields.problemType === 'CS_OLD') && fields.evtFlag === 'evt'),
      dataTables: [
        {
          name: 'cmlMeasurementThicknessTable2',
        },
      ],
    },
    { type: 'pagebreak' },
    {
      type: 'heading',
      text: 'Results',
    },
    {
      type: 'table',
      heading: 'Summary Jags Data',
      dataTables: [
        {
          name: 'summaryData',
        },
      ],
    },
    {
      type: 'image',
      heading: 'Thickness Projections',
      predicate: fields => (fields.thicknessPlotsReportFlag === true || fields.thicknessPlotsReportFlag === 1),
      graphs: [
        {
          name: 'thicknessPlotsGraphObject',
        },
      ],
    },
    {
      type: 'image',
      heading: 'Metal Loss Projections',
      predicate: fields => (fields.metalLossPlotsReportFlag === true || fields.metalLossPlotsReportFlag === 1),
      graphs: [
        {
          name: 'metalLossPlotsGraphObject',
        },
      ],
    },
    {
      type: 'image',
      heading: 'POF Projections',
      predicate: fields => (fields.pofPlotsReportFlag === true || fields.pofPlotsReportFlag === 1),
      graphs: [
        {
          name: 'pofPlotsGraphObject',
        },
      ],
    },
    {
      type: 'image',
      heading: 'Residuals Plot',
      predicate: fields => (fields.residualPlotsReportFlag === true || fields.residualPlotsReportFlag === 1),
      graphs: [
        {
          name: 'residualPlotsGraphObject',
        },
      ],
    },
  ],
}
